import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { NutritionComponentId } from '../domain/nutritions/nutrition-component';
import { NutritionGoal } from '../domain/nutritions/nutrition-goal';
import { NutritionUnit } from '../domain/nutritions/nutrition-quantity-unit';

export interface NutritionGoalSchema {
  componentId: string;
  value: number | null;
  unit: NutritionUnit | null;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
  sorter: number;
}

@Injectable()
export class NutritionGoalRepository {
  constructor() {
    // NaDa
  }

  toSchema(
    nutritionGoal: NutritionGoal,
    sorter?: number | undefined,
  ): NutritionGoalSchema {
    if (sorter) {
      return <NutritionGoalSchema>{
        componentId: nutritionGoal.componentId.id.toString(),
        value: nutritionGoal.value ?? null,
        unit: nutritionGoal.unit ?? null,
        createdAt:
          nutritionGoal.createdAt !== undefined
            ? Timestamp.fromDate(nutritionGoal.createdAt)
            : Timestamp.now(),
        updatedAt: Timestamp.now(),
        sorter,
      };
    } else {
      return <NutritionGoalSchema>{
        componentId: nutritionGoal.componentId.id.toString(),
        value: nutritionGoal.value ?? null,
        createdAt:
          nutritionGoal.createdAt !== undefined
            ? Timestamp.fromDate(nutritionGoal.createdAt)
            : Timestamp.now(),
        updatedAt: Timestamp.now(),
      };
    }
  }

  fromSchema(
    schema: NutritionGoalSchema,
    id?: string | undefined,
  ): NutritionGoal {
    return NutritionGoal.create(
      {
        componentId: NutritionComponentId.create(
          new UniqueEntityID(schema.componentId),
        ),
        value: schema.value ?? undefined,
        unit: schema.unit ?? undefined,
        createdAt: schema.createdAt?.toDate(),
        updatedAt: schema.updatedAt?.toDate(),
      },
      new UniqueEntityID(id),
    );
  }
}
