import Entity from '../../../core/domain/entity';
import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { DietitianProps } from '../../dietitian/domain/dietitian';

export interface NewsProps {
  subtitle: string;
  content: string;
  date: Date;
}

export class NewsId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): NewsId {
    return new NewsId(id);
  }
}

export class News extends Entity<NewsProps> {
  get newsId(): NewsId {
    return NewsId.create(this.id);
  }

  get subtitle(): string {
    return this.props.subtitle;
  }

  get content(): string {
    return this.props.content;
  }

  get date(): Date {
    return this.props.date;
  }

  private constructor(props: NewsProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: DietitianProps): News {
    return News.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: NewsProps, id?: UniqueEntityID): News {
    return new News(props, id);
  }
}
