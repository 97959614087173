import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { UserId } from '../../auth/domain/user';
import { PatientId } from '../../patient/domain/patient';
import { Encouragement } from '../domain/encouragement/encouragement';
import { EncouragementNotFoundException } from '../domain/encouragement/encouragement_exceptions';

interface EncouragementSchema {
  patientUserId: string | null;
  message: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  reaction: string | null;
}

@Injectable()
export class EncouragementRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collection(patientId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('patients')
      .doc(patientId)
      .collection<EncouragementSchema>('encouragements', queryFn);
  }

  private toSchema(encouragement: Encouragement): EncouragementSchema {
    return <EncouragementSchema>{
      patientUserId: encouragement.patientUserId?.id.toString() ?? null,
      message: encouragement.message,
      createdAt:
        encouragement.createdAt !== undefined
          ? Timestamp.fromDate(encouragement.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      reaction: encouragement.reaction ?? null,
    };
  }

  private fromSchema(
    schema: EncouragementSchema,
    patientId: string,
    id: string,
  ): Encouragement {
    return Encouragement.create(
      {
        patientId: PatientId.create(new UniqueEntityID(patientId)),
        patientUserId: schema.patientUserId
          ? UserId.create(new UniqueEntityID(schema.patientUserId))
          : undefined,
        message: schema.message,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        reaction: schema.reaction ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async load(
    patientId: string,
    encouragementId: string,
  ): Promise<Encouragement> {
    const snap = await firstValueFrom(
      this.collection(patientId).doc(encouragementId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new EncouragementNotFoundException();
    }

    return this.fromSchema(
      snap.data() as EncouragementSchema,
      patientId,
      snap.id,
    );
  }

  async findEncouragements(
    patientId: string,
    start: Date,
    end: Date,
  ): Promise<Encouragement[]> {
    const snap = await firstValueFrom(
      this.collection(patientId, (ref) =>
        ref
          .where('createdAt', '>=', start)
          .where('createdAt', '<=', end)
          .orderBy('createdAt'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), patientId, doc.id),
    );
  }
}
