import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { Dietitian } from '../dietitian';

export class DietitianEvent extends DomainEvent<Dietitian> {}

export class DietitianCreated extends DietitianEvent {}

export class DietitianUpdated extends DietitianEvent {}

export class DietitianSignedIn extends DietitianEvent {}

export class DietitianSignedOut extends DietitianEvent {}
