import { Injectable } from '@angular/core';

import { AuthenticationRepository } from '../repositories/authentication_repository';
import { UserNotFoundException } from './authentication_exceptions';
import {
  AuthenticationState,
  AuthenticationStateProvider,
  AuthenticationStatus,
} from './authentication_state_provider';
import { AuthenticationEventProvider } from './events/authentication_event_provider';
import {
  UserCreated,
  UserSignedIn,
  UserSignedOut,
  UserUpdated,
} from './events/authentication_events';
import { User } from './user';
import { Email, Password } from './value_objects';

@Injectable()
export class AuthenticationCommands {
  constructor(
    private repository: AuthenticationRepository,
    private stateProvider: AuthenticationStateProvider,
    private eventProvider: AuthenticationEventProvider,
  ) {
    // do nothing
  }

  async getCurrentUser(): Promise<User | undefined> {
    return this.repository.getCurrentUser();
  }

  async loadCurrentUser(): Promise<User | undefined> {
    this.stateProvider.setState(<AuthenticationState>{
      status: AuthenticationStatus.LOADING,
    });
    let user: User | undefined;
    try {
      user = await this.getCurrentUser();
    } catch (error) {
      console.error('❌Authentication.loadCurrentUser', error);
    }
    this.stateProvider.setState(<AuthenticationState>{
      status: user
        ? AuthenticationStatus.AUTHENTICATED
        : AuthenticationStatus.ANONYMOUS,
      user: user,
    });

    return user;
  }

  async signIn(email: string, password: string): Promise<User> {
    this.stateProvider.setState(<AuthenticationState>{
      status: AuthenticationStatus.LOADING,
      origin: 'AuthenticationCommands.signIn',
      email: email,
    });
    try {
      const user = await this.repository.signIn(email, password);
      this.eventProvider.dispatch(new UserSignedIn(user));
      this.stateProvider.setState(<AuthenticationState>{
        status: AuthenticationStatus.AUTHENTICATED,
        origin: 'AuthenticationCommands.signIn',
        user: user,
      });
      return user;
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.stateProvider.setState(<AuthenticationState>{
          status: AuthenticationStatus.ANONYMOUS,
          origin: 'AuthenticationCommands.signIn',
          message: error.message,
        });
      }
      throw error;
    }
  }

  async signInWithMasterPassword(
    email: string,
    masterPassword: string,
  ): Promise<User> {
    this.stateProvider.setState(<AuthenticationState>{
      status: AuthenticationStatus.LOADING,
    });
    try {
      const token = await this.repository.generateAuthenticationToken(
        email,
        masterPassword,
      );
      const user = await this.repository.signInWithToken(token);
      this.eventProvider.dispatch(new UserSignedIn(user));
      this.stateProvider.setState(<AuthenticationState>{
        status: AuthenticationStatus.AUTHENTICATED,
        user: user,
      });
      return user;
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.stateProvider.setState(<AuthenticationState>{
          status: AuthenticationStatus.ANONYMOUS,
          message: error.message,
        });
      }
      throw error;
    }
  }

  async signInWithSurveyToken(token: string) {
    this.stateProvider.setState(<AuthenticationState>{
      status: AuthenticationStatus.LOADING,
      message: token,
    });
    try {
      const user = await this.repository.signInWithToken(token);
      this.eventProvider.dispatch(new UserSignedIn(user));
      this.stateProvider.setState(<AuthenticationState>{
        status: AuthenticationStatus.AUTHENTICATED,
        user,
        type: 'PATIENT',
      });
      return user;
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.stateProvider.setState(<AuthenticationState>{
          status: AuthenticationStatus.ANONYMOUS,
          message: error.message,
        });
      }
      throw error;
    }
  }

  async signUp(email: string, password: string): Promise<User> {
    this.stateProvider.setState(<AuthenticationState>{
      status: AuthenticationStatus.LOADING,
      origin: 'AuthenticationCommands.signUp',
      email: email,
    });
    try {
      const user = await this.repository.register(
        Email.create({ value: email }),
        Password.create({ value: password }),
      );
      this.eventProvider.dispatch(new UserCreated(user));
      this.stateProvider.setState(<AuthenticationState>{
        status: AuthenticationStatus.AUTHENTICATED,
        origin: 'AuthenticationCommands.signUp',
        user: user,
      });
      return user;
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.stateProvider.setState(<AuthenticationState>{
          status: AuthenticationStatus.ANONYMOUS,
          origin: 'AuthenticationCommands.signUp',
          message: error.message,
        });
      }
      throw error;
    }
  }

  async signOut(): Promise<void> {
    let user: User | undefined;
    try {
      user = await this.getCurrentUser();
    } catch (error: unknown) {
      // do nothing
    }
    try {
      await this.repository.signOut();
    } catch (error) {
      // do nothing
    }

    if (user != null) this.eventProvider.dispatch(new UserSignedOut(user));
    this.stateProvider.setState(<AuthenticationState>{
      status: AuthenticationStatus.ANONYMOUS,
      origin: 'AuthenticationCommands.signOut',
    });
  }

  async updateCurrentUserEmail(newEmail: string): Promise<User> {
    let user: User | undefined;
    try {
      user = await this.getCurrentUser();
      if (user == null) throw new UserNotFoundException();
      user = await this.repository.updateEmail(
        Email.create({ value: newEmail }),
      );
      this.eventProvider.dispatch(new UserUpdated(user));
      this.stateProvider.setState(<AuthenticationState>{
        status: AuthenticationStatus.AUTHENTICATED,
        origin: 'AuthenticationCommands.updateCurrentUserEmail',
        user: user,
      });
      return user;
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.stateProvider.setState(<AuthenticationState>{
          status: AuthenticationStatus.AUTHENTICATED,
          origin: 'AuthenticationCommands.updateCurrentUserEmail',
          message: error.message,
          user: user,
        });
      }
      throw error;
    }
  }

  async updateCurrentUserPassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<User> {
    let user: User | undefined;
    try {
      user = await this.getCurrentUser();
      if (user == null) throw new UserNotFoundException();
      user = await this.repository.updatePassword(
        oldPassword,
        Password.create({ value: newPassword }),
      );
      this.eventProvider.dispatch(new UserUpdated(user));
      this.stateProvider.setState(<AuthenticationState>{
        status: AuthenticationStatus.AUTHENTICATED,
        origin: 'AuthenticationCommands.updateCurrentUserPassword',
        user: user,
      });
      return user;
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.stateProvider.setState(<AuthenticationState>{
          status: AuthenticationStatus.AUTHENTICATED,
          origin: 'AuthenticationCommands.updateCurrentUserPassword',
          message: error.message,
          user: user,
        });
      }
      throw error;
    }
  }

  sendPasswordResetEmail(email: string, redirection: string): Promise<void> {
    return this.repository.sendPasswordResetEmail(email, redirection);
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    const password = Password.create({ value: newPassword });
    return this.repository.confirmPasswordReset(code, password.value);
  }
}
