import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { PatientId } from '../../../patient/domain/patient';

export interface MemoProps {
  patientId: PatientId;
  value: string;
  updatedAt?: Date;
}

export class MemoId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): MemoId {
    return new MemoId(id);
  }
}

export class Memo extends Entity<MemoProps> {
  get memoId(): MemoId {
    return MemoId.create(this.id);
  }

  get patientId(): PatientId {
    return this.props.patientId;
  }

  get value(): string {
    return this.props.value;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  private constructor(props: MemoProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: MemoProps): Memo {
    return Memo.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: MemoProps, id?: UniqueEntityID): Memo {
    return new Memo(props, id);
  }
}
