import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { BillLineDescription } from '../bill/bill-line-description';

export interface EstimateLineProps {
  description: BillLineDescription | undefined;
  comment?: string | undefined;
  date?: string | undefined;
  amount: number | undefined;
  createdAt?: Date | undefined;
  updatedAt?: Date | undefined;
}

export class EstimateLine extends Entity<EstimateLineProps> {
  get description(): BillLineDescription | undefined {
    return this.props.description;
  }

  set description(value: BillLineDescription | undefined) {
    this.props.description = value;
  }

  get comment(): string | undefined {
    return this.props.comment;
  }

  set comment(value: string | undefined) {
    this.props.comment = value;
  }

  get amount(): number | undefined {
    return this.props.amount;
  }

  set amount(value: number | undefined) {
    this.props.amount = value;
  }

  get date(): string | undefined {
    return this.props.date;
  }

  set date(value: string | undefined) {
    this.props.date = value;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  private constructor(props: EstimateLineProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: EstimateLineProps): EstimateLine {
    return EstimateLine.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: EstimateLineProps,
    id?: UniqueEntityID,
  ): EstimateLine {
    return new EstimateLine(props, id);
  }
}
