export enum DiaryType {
  Collation = 'collation',
  Feeling = 'feeling',
  Hydration = 'hydration',
  Meal = 'meal',
  PhysicalActivity = 'physical_activity',
  ChallengeOfTheDay = 'challenge_of_the_day',
  Sleep = 'sleep',
  ExcludingMeals = 'excluding_meals',
  Symptoms = 'symptoms',
  Positive = 'positive',
  Discomfort = 'discomfort',
}

export class DiaryUtils {
  private type: DiaryType;

  constructor(type: DiaryType) {
    this.type = type;
  }

  get typeName(): string {
    switch (this.type) {
      case DiaryType.Collation:
        return 'Collation';
      case DiaryType.Feeling:
        return 'Ressentis';
      case DiaryType.Hydration:
        return 'Hydratation';
      case DiaryType.Meal:
        return 'Repas';
      case DiaryType.PhysicalActivity:
        return 'Activité physique';
      case DiaryType.ChallengeOfTheDay:
        return 'Défi du jour';
      case DiaryType.Sleep:
        return 'Sommeil';
      case DiaryType.ExcludingMeals:
        return 'Hors repas';
      case DiaryType.Symptoms:
        return 'Symptômes';
      // V1 support
      case DiaryType.Positive:
        return 'Positif';
      case DiaryType.Discomfort:
        return 'Incomfort';
      default:
        return 'Inconnu';
    }
  }

  get typeIcon(): string {
    switch (this.type) {
      case DiaryType.Collation:
        return 'assets/images/diary/types/snack.svg';
      case DiaryType.Feeling:
        return 'assets/images/diary/types/feeling.svg';
      case DiaryType.Hydration:
        return 'assets/images/diary/types/hydration.svg';
      case DiaryType.Meal:
        return 'assets/images/diary/types/meal.svg';
      case DiaryType.PhysicalActivity:
        return 'assets/images/diary/types/physical_activity.svg';
      case DiaryType.ChallengeOfTheDay:
        return 'assets/images/diary/types/challenge_of_the_day.svg';
      case DiaryType.Sleep:
        return 'assets/images/diary/types/sleep.svg';
      case DiaryType.ExcludingMeals:
        return 'assets/images/diary/types/excluding_meals.svg';
      case DiaryType.Symptoms:
        return 'assets/images/diary/types/symptoms.svg';
      // v1 support
      case DiaryType.Positive:
        return 'assets/images/diary/types/positive.svg';
      case DiaryType.Discomfort:
        return 'assets/images/diary/types/discomfort.svg';
      default:
        return 'assets/images/diary/types/meal.svg';
    }
  }
}
