import { Exception } from 'src/app/core/logic/exception';

export class InvalidDocumentFileTypeException extends Exception<never> {
  constructor() {
    super('Type de fichier invalide, types acceptés: pdf/doc/xls/jpeg/png');
  }
}

export class DocumentFileTooBigException extends Exception<never> {
  constructor() {
    super('Taille de fichier trop importante, limite de 10Mo');
  }
}

export class DocumentNotFoundException extends Exception<never> {
  constructor() {
    super('Document non trouvé');
  }
}
