import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { DietitianStateProvider } from 'src/app/data/dietitian/domain/dietitian_state_provider';
import { PatientCommands } from 'src/app/data/patient/domain/patient_commands';
import { PatientStateProvider } from 'src/app/data/patient/domain/patient_state_provider';

@Injectable()
export class PatientGuard implements CanActivate {
  constructor(
    private router: Router,
    private dietitianStateProvider: DietitianStateProvider,
    private patientStateProvider: PatientStateProvider,
    private patientCommands: PatientCommands,
  ) {
    // do nothing
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const patientId = route.paramMap.get('patientId');
    const dietitian = this.dietitianStateProvider.state.entity;
    const patient = this.patientStateProvider.state.entity;

    if (!dietitian) {
      // not signed in as a dietitian, redirect
      return this.router.createUrlTree(['/auth']);
    }

    if (!patientId) {
      // no patientId provided, redirect
      return this.router.createUrlTree(['/dashboard']);
    }

    if (!patient || patient.id.toString() != patientId) {
      // load patient
      return this.patientCommands
        .setPatientState(patientId)
        .then((patient) => {
          // check patient
          if (patient && patient.dietId == dietitian.id.toString()) {
            return true;
          }
          // patient not found or not associated to dietitian, redirect
          return this.router.createUrlTree(['/dashboard']);
        })
        .catch(() => {
          return this.router.createUrlTree(['/dashboard']);
        });
    } else {
      // check patient
      if (patient.dietId == dietitian.id.toString()) {
        return true;
      }
      // patient not associated to dietitian, redirect
      return this.router.createUrlTree(['/dashboard']);
    }
  }
}
