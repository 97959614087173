import { DomainEvent } from '../../../../../core/domain/events/domain_event';
import { ScheduledTask } from '../../scheduled_task/scheduled_task';

export class ScheduledTaskEvent extends DomainEvent<ScheduledTask> {}

export class ScheduledTaskCreated extends ScheduledTaskEvent {}

export class ScheduledTaskUpdated extends ScheduledTaskEvent {}

export class ScheduledTaskDeleted extends ScheduledTaskEvent {}

export class ScheduledTaskBatchCreated extends ScheduledTaskEvent {
  tasks: ScheduledTask[];

  constructor(tasks: ScheduledTask[], notify?: boolean) {
    if (tasks.length == 0) throw new Error('Empty task list');
    super(tasks[0], notify);
    this.tasks = tasks;
  }
}

export class ScheduledTaskBatchDeleted extends ScheduledTaskEvent {
  tasks: ScheduledTask[];

  constructor(tasks: ScheduledTask[], notify?: boolean) {
    if (tasks.length == 0) throw new Error('Empty tasks list');
    super(tasks[0], notify);
    this.tasks = tasks;
  }
}
