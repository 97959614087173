import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from './user';

export enum AuthenticationStatus {
  AUTHENTICATED = 'AUTHENTICATED',
  ANONYMOUS = 'ANONYMOUS',
  LOADING = 'LOADING',
}

export interface AuthenticationState {
  status: AuthenticationStatus;
  email?: string;
  message?: string;
  user?: User;
  type?: 'PATIENT' | 'DIETITIAN';
  origin?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationStateProvider {
  private authenticationSubject$ = new BehaviorSubject<AuthenticationState>(<
    AuthenticationState
  >{
    status: AuthenticationStatus.LOADING,
  });

  get authentication$(): Observable<AuthenticationState> {
    return this.authenticationSubject$.asObservable();
  }

  setState(state: AuthenticationState) {
    this.authenticationSubject$.next(state);
  }
}
