import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { PatientId } from 'src/app/data/patient/domain/patient';

import { SharedDocumentId } from '../../../document/domain/shared_document/shared_document';
import { DietitianId } from '../dietitian';
import { Template, TemplateId } from '../templates/template';
import { TemplateField } from '../templates/template_field';
import { NoteField } from './note_field';

export interface NoteProps {
  dietitianId: DietitianId;
  patientId: PatientId;
  templateId: TemplateId;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  fields: NoteField[];
  sharedDocumentId?: SharedDocumentId | undefined;
  sharedAt?: Date | undefined;
}

export class NoteId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): NoteId {
    return new NoteId(id);
  }
}

export class Note extends Entity<NoteProps> {
  get noteId(): NoteId {
    return NoteId.create(this.id);
  }

  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get patientId(): PatientId {
    return this.props.patientId;
  }

  get templateId(): TemplateId {
    return this.props.templateId;
  }

  get name(): string {
    return this.props.name;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get fields(): NoteField[] {
    return this.props.fields;
  }

  get sharedDocumentId(): SharedDocumentId | undefined {
    return this.props.sharedDocumentId;
  }

  set sharedDocumentId(value: SharedDocumentId | undefined) {
    this.props.sharedDocumentId = value;
  }

  get sharedAt(): Date | undefined {
    return this.props.sharedAt;
  }

  set sharedAt(value: Date | undefined) {
    this.props.sharedAt = value;
  }

  private constructor(props: NoteProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: NoteProps): Note {
    return Note.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  patch(notesTemplate: Template): Note {
    const templateFields: { [key: string]: TemplateField } = {};
    const reversedTemplateFields: { [key: string]: NoteField } = {};
    for (const field of notesTemplate.fields) {
      templateFields[field.id.toString()] = field;
    }
    for (const field of this.fields) {
      reversedTemplateFields[field.notesTemplateFieldId.id.toString()] = field;
    }

    // add missing fields
    let patchedFields = this.fields ?? [];
    for (const field of notesTemplate.fields) {
      if (reversedTemplateFields[field.id.toString()]) continue;

      patchedFields.push(
        NoteField.create({
          noteId: this.noteId,
          notesTemplateFieldId: field.templateFieldId,
          label: field.label,
          type: field.type,
          params: field.params,
          value: null,
          order: field.order,
        }),
      );
    }

    patchedFields = this.fields
      .map((field) => {
        const tf = templateFields[field.notesTemplateFieldId.id.toString()];
        return tf ? field.patch(tf) : field;
      })
      .sort((a, b) => a.order - b.order);

    return this.copyWith({
      templateId: TemplateId.create(
        new UniqueEntityID(notesTemplate.id.toString()),
      ),
      name: notesTemplate.name,
      fields: patchedFields,
    } as NoteProps);
  }

  updateFields(fields: NoteField[]): Note {
    return this.copyWith({
      fields,
    } as NoteProps);
  }

  updateFieldValues(values: { [key: string]: unknown }): Note {
    return this.copyWith({
      fields: this.fields.map((f) => {
        return f.updateValue(values[f.id.toString()] ?? f.value);
      }),
    } as NoteProps);
  }

  public static create(props: NoteProps, id?: UniqueEntityID): Note {
    return new Note(props, id);
  }
}
