import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Dietitian } from '../../../dietitian/domain/dietitian';
import { CustomNutritionRepository } from '../../repositories/custom-nutrition.repository';
import { CustomNutritionEventProvider } from '../events/custom_nutrition/custom_nutrition_event_provider';
import {
  CustomNutritionCreated,
  CustomNutritionDeleted,
  CustomNutritionDuplicated,
  CustomNutritionSaved,
} from '../events/custom_nutrition/custom_nutrition_events';
import { Patient } from '../patient';
import { CustomNutrition } from './custom-nutrition';

@Injectable()
export class CustomNutritionCommands {
  constructor(
    private repository: CustomNutritionRepository,
    private eventProvider: CustomNutritionEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof CustomNutritionCreated) {
        this.toastr.success('Journée type créée');
      } else if (event instanceof CustomNutritionDuplicated) {
        this.toastr.success(
          'Journée type ' +
            event.origin.name +
            ' a été dupliquée vers ' +
            event.entity.name,
        );
      } else if (event instanceof CustomNutritionSaved) {
        this.toastr.success(
          'Journée type pour le patient ' +
            event.patient.firstName +
            ' ' +
            event.patient.lastName +
            ' enregistrée',
        );
      } else if (event instanceof CustomNutritionDeleted) {
        this.toastr.success('Journée type effacée');
      }
    });
  }

  async loadCustomNutrition(
    patient: Patient,
    dietitian: Dietitian,
    customNutritionId: string,
  ): Promise<CustomNutrition> {
    return this.repository.load(
      patient,
      customNutritionId,
      dietitian.id.toString(),
    );
  }

  async findAllCustomNutrition(patient: Patient): Promise<CustomNutrition[]> {
    return this.repository.findAll(patient);
  }

  async saveCustomNutrition(
    customNutrition: CustomNutrition,
    withEventDispatch = true,
  ): Promise<CustomNutrition> {
    await this.repository.save(customNutrition);
    if (withEventDispatch) {
      this.eventProvider.dispatch(
        new CustomNutritionSaved(customNutrition, customNutrition.patient),
      );
    }
    return customNutrition;
  }

  async deleteCustomNutrition(customNutrition: CustomNutrition) {
    await this.repository.delete(
      customNutrition.patient.id.toString(),
      customNutrition.id.toString(),
    );
    this.eventProvider.dispatch(new CustomNutritionDeleted(customNutrition));
  }

  async generateCustomNutritionPdf(
    patientId: string,
    customNutritionId: string,
  ) {
    return await this.repository.generatePdf(patientId, customNutritionId);
  }

  async duplicateCustomNutrition(
    customNutrition: CustomNutrition,
    dietitian: Dietitian,
  ) {
    const duplicatedCustomNutrition = await this.repository.duplicate(
      customNutrition,
      dietitian,
    );
    this.eventProvider.dispatch(
      new CustomNutritionDuplicated(duplicatedCustomNutrition, customNutrition),
    );
    return duplicatedCustomNutrition;
  }
}
