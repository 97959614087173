import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { LoadingStatus } from 'src/app/core/domain/events/state_provider';
import { DietitianStateProvider } from 'src/app/data/dietitian/domain/dietitian_state_provider';

@Injectable()
export class TemplateIdGuard implements CanActivate {
  constructor(
    private router: Router,
    private dietitianStateProvider: DietitianStateProvider,
  ) {
    // do nothing
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const templateId = route.paramMap.get('templateId');

    return this.dietitianStateProvider.state$
      .pipe(
        filter((s) => {
          return s.loading != LoadingStatus.LOADING;
        }), // ignore loading
      )
      .pipe(
        map((e) => {
          // signed in as dietitian, continue
          if (e.entity != null) {
            if (templateId != null) {
              return true;
            } else {
              return this.router.createUrlTree(['/dashboard']);
            }
          }

          // redirect
          return this.router.createUrlTree(['/auth']);
        }),
      );
  }
}
