import { Injectable } from '@angular/core';
import deepEqual from 'deep-equal';
import { ToastrService } from 'ngx-toastr';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { TrackerCellModelsWrapper } from '../../../../ui/components/tracker/tracker-manager/tracker-manager.service';
import { UserId } from '../../../auth/domain/user';
import { CustomTrackerId } from '../../../dietitian/domain/custom_tracker/custom_tracker';
import { DietitianId } from '../../../dietitian/domain/dietitian';
import {
  PatientGroup,
  PatientGroupId,
} from '../../../dietitian/domain/patient_group/patient_group';
import { CustomTrackerRepository } from '../../../dietitian/repositories/custom_tracker_repository';
import { Patient, PatientId } from '../../../patient/domain/patient';
import { TrackerRepository } from '../../repositories/tracker_repository';
import { DiaryType } from '../diary/diary_type';
import { TrackerEventProvider } from '../events/tracker/tracker_event_provider';
import {
  CustomTrackerBatchCreated,
  TrackerBatchCreated,
  TrackerBatchSaved,
  TrackerCreated,
  TrackerDeleted,
  TrackerUpdated,
} from '../events/tracker/tracker_events';
import { Tracker, TrackerProps } from './tracker';
import {
  ProtectedTrackerUnchangeableException,
  TrackerNotFoundException,
  TrackerTargetException,
} from './tracker_exceptions';
import { TrackerType } from './tracker_type';

export interface TrackersWrapper {
  origin: 'PATIENT' | 'PATIENT_GROUP' | 'DIETITIAN' | 'UNKNOWN';
  trackers: Tracker[];
}

@Injectable()
export class TrackerCommands {
  constructor(
    private repository: TrackerRepository,
    private customTrackersRepository: CustomTrackerRepository,
    private eventProvider: TrackerEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (!event.notify) return;

      if (event instanceof TrackerCreated) {
        this.toastr.success('Tracker créé');
      } else if (event instanceof TrackerBatchCreated) {
        this.toastr.success('Trackers créés');
      } else if (event instanceof CustomTrackerBatchCreated) {
        this.toastr.success('Trackers personnalisés ajoutés');
      } else if (event instanceof TrackerBatchSaved) {
        this.toastr.success('Trackers sauvegardés');
      } else if (event instanceof TrackerUpdated) {
        this.toastr.success('Tracker mis à jour');
      } else if (event instanceof TrackerDeleted) {
        this.toastr.success('Tracker supprimé');
      }
    });
  }

  getTrackerEvent() {
    return this.eventProvider.events$;
  }

  private async getTracker(
    dietitianId: string | undefined,
    patientGroupId: string | undefined,
    patientId: string | undefined,
    trackId: string,
  ): Promise<Tracker | undefined> {
    if (patientId) {
      return this.repository.loadPatient(patientId, trackId);
    } else if (patientGroupId) {
      return this.repository.loadPatientGroup(patientGroupId, trackId);
    } else if (dietitianId) {
      return this.repository.loadDietitian(dietitianId, trackId);
    } else {
      throw new TrackerTargetException();
    }
  }

  async getTrackers(
    dietitianId: string | undefined,
    patientGroupId: string | undefined,
    patientId: string | undefined,
  ): Promise<TrackersWrapper> {
    let trackers: Tracker[] = [];
    let origin: 'PATIENT' | 'PATIENT_GROUP' | 'DIETITIAN' | 'UNKNOWN' =
      'UNKNOWN';
    if (patientId) {
      trackers = await this.repository.findByPatientId(patientId);
      origin = 'PATIENT';
      if (trackers.length === 0) {
        if (patientGroupId) {
          trackers = await this.repository.findByPatientGroupId(patientGroupId);
          trackers = trackers.map((tracker) => {
            return Tracker.create({
              ...tracker.props,
              patientId: PatientId.create(new UniqueEntityID(patientId)),
              dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
            });
          });
          origin = 'PATIENT_GROUP';
          if (trackers.length === 0) {
            if (dietitianId) {
              trackers = await this.repository.findByDietitianId(dietitianId);
              trackers = trackers.map((tracker) => {
                return Tracker.create({
                  ...tracker.props,
                  patientId: PatientId.create(new UniqueEntityID(patientId)),
                  dietitianId: DietitianId.create(
                    new UniqueEntityID(dietitianId),
                  ),
                });
              });
              origin = 'DIETITIAN';
            } else {
              throw new TrackerTargetException();
            }
          }
        } else {
          if (dietitianId) {
            trackers = await this.repository.findByDietitianId(dietitianId);
            trackers = trackers.map((tracker) => {
              return Tracker.create({
                ...tracker.props,
                patientId: PatientId.create(new UniqueEntityID(patientId)),
                dietitianId: DietitianId.create(
                  new UniqueEntityID(dietitianId),
                ),
              });
            });
            origin = 'DIETITIAN';
          } else {
            throw new TrackerTargetException();
          }
        }
      }
    } else if (patientGroupId) {
      trackers = await this.repository.findByPatientGroupId(patientGroupId);
      origin = 'PATIENT_GROUP';
      if (trackers.length === 0) {
        if (dietitianId) {
          trackers = await this.repository.findByDietitianId(dietitianId);
          trackers = trackers.map((tracker) => {
            return Tracker.create({
              ...tracker.props,
              patientGroupId: PatientGroupId.create(
                new UniqueEntityID(patientGroupId),
              ),
              dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
            });
          });
          origin = 'DIETITIAN';
        } else {
          throw new TrackerTargetException();
        }
      }
    } else if (dietitianId) {
      trackers = await this.repository.findByDietitianId(dietitianId);
      origin = 'DIETITIAN';
    } else {
      throw new TrackerTargetException();
    }
    return { origin, trackers };
  }

  async saveTracker(
    trackerProps: TrackerProps,
    trackerId?: string,
    notify = true,
  ): Promise<Tracker> {
    let tracker = Tracker.create(trackerProps, new UniqueEntityID(trackerId));
    if (trackerId) {
      const oldTracker = await this.getTracker(
        tracker.dietitianId?.id.toString() ?? undefined,
        tracker.patientGroupId?.id.toString() ?? undefined,
        tracker.patientId?.id.toString() ?? undefined,
        tracker.trackerId.id.toString(),
      );
      if (oldTracker) {
        if (oldTracker.protected) {
          const comparedTracker = oldTracker.copyWith({
            activatedAt: tracker.activatedAt,
            updatedAt: tracker.updatedAt,
          } as TrackerProps);
          if (!deepEqual(comparedTracker.props, tracker.props)) {
            throw new ProtectedTrackerUnchangeableException();
          }
        }
        tracker = await this.repository.save(tracker);
        this.eventProvider.dispatch(new TrackerUpdated(tracker, notify));
      } else {
        tracker = await this.repository.create(tracker);
        this.eventProvider.dispatch(new TrackerCreated(tracker, notify));
      }
    } else {
      tracker = await this.repository.create(tracker);
      this.eventProvider.dispatch(new TrackerCreated(tracker, notify));
    }
    return tracker;
  }

  async deleteAllTrackersOfPatient(patient: Patient) {
    await this.repository.deleteAllOfPatient(patient.id.toString());
  }

  async deleteTracker(
    dietitianId: string | undefined,
    patientGroupId: string | undefined,
    patientId: string | undefined,
    trackerId: string,
  ): Promise<Tracker> {
    const tracker = await this.getTracker(
      dietitianId,
      patientGroupId,
      patientId,
      trackerId,
    );
    if (tracker) {
      if (tracker.protected) {
        throw new ProtectedTrackerUnchangeableException();
      }
      await this.repository.delete(
        dietitianId,
        patientGroupId,
        patientId,
        trackerId,
      );
      this.eventProvider.dispatch(new TrackerDeleted(tracker));

      return tracker;
    }
    throw new TrackerNotFoundException();
  }

  async createCustomTrackers(
    dietitianId: string,
    patientId: string | undefined,
    patientUserId: string | undefined,
  ) {
    const customTrackers = await this.customTrackersRepository.findByDietId(
      dietitianId,
    );
    const trackers: Tracker[] = [];
    for (const customTracker of customTrackers) {
      trackers.push(
        Tracker.create({
          dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
          patientId: patientId
            ? PatientId.create(new UniqueEntityID(patientId))
            : undefined,
          patientUserId: patientUserId
            ? UserId.create(new UniqueEntityID(patientUserId))
            : undefined,
          diaryType: customTracker.diaryType,
          max: customTracker.max,
          name: customTracker.name,
          protected: true,
          dietitianTrackerId: CustomTrackerId.create(customTracker.id),
          type: customTracker.type,
        } as TrackerProps),
      );
    }

    if (trackers.length == 0) {
      return [];
    }

    try {
      await this.repository.saveBatch(trackers);
      this.eventProvider.dispatch(new CustomTrackerBatchCreated(trackers));
      return trackers;
    } catch (e) {
      return [];
    }
  }

  async updateCustomTrackers(dietitianId: string) {
    const customTrackers = await this.customTrackersRepository.findByDietId(
      dietitianId,
    );
    const trackers = await this.repository.findByDietitianId(dietitianId);
    for (const customTracker of customTrackers) {
      if (
        trackers.filter(
          (t) =>
            t.dietitianTrackerId &&
            t.dietitianTrackerId.id.toString() === customTracker.id.toString(),
        ).length === 0
      ) {
        const tracker = await this.repository.create(
          Tracker.create({
            dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
            diaryType: customTracker.diaryType,
            max: customTracker.max,
            name: customTracker.name,
            protected: !customTracker.id,
            dietitianTrackerId: CustomTrackerId.create(customTracker.id),
            type: customTracker.type,
          } as TrackerProps),
        );
        this.eventProvider.dispatch(new TrackerCreated(tracker, false));
      }
    }
  }

  async createDefaultTrackers(
    dietitianId: string,
    patientId: string | undefined,
    patientUserId: string | undefined,
  ): Promise<Tracker[]> {
    const trackers: Tracker[] = [];
    for (const diaryType of [
      DiaryType.Meal,
      DiaryType.Collation,
      DiaryType.ExcludingMeals,
    ]) {
      trackers.push(
        Tracker.create({
          dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
          patientId: patientId
            ? PatientId.create(new UniqueEntityID(patientId))
            : undefined,
          patientUserId: patientUserId
            ? UserId.create(new UniqueEntityID(patientUserId))
            : undefined,
          name: 'Sensation de faim',
          diaryType,
          type: TrackerType.Note,
          max: 10,
          protected: true,
          activatedAt: new Date(),
        }),
      );
      trackers.push(
        Tracker.create({
          dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
          patientId: patientId
            ? PatientId.create(new UniqueEntityID(patientId))
            : undefined,
          patientUserId: patientUserId
            ? UserId.create(new UniqueEntityID(patientUserId))
            : undefined,
          name: 'Rassasiement',
          diaryType,
          type: TrackerType.Percent,
          max: 200,
          protected: true,
          activatedAt: new Date(),
        }),
      );
      trackers.push(
        Tracker.create({
          dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
          patientId: patientId
            ? PatientId.create(new UniqueEntityID(patientId))
            : undefined,
          patientUserId: patientUserId
            ? UserId.create(new UniqueEntityID(patientUserId))
            : undefined,
          name: 'Durée du repas (min)',
          diaryType,
          type: TrackerType.Number,
          max: 120,
          protected: true,
        }),
      );
      trackers.push(
        Tracker.create({
          dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
          patientId: patientId
            ? PatientId.create(new UniqueEntityID(patientId))
            : undefined,
          patientUserId: patientUserId
            ? UserId.create(new UniqueEntityID(patientUserId))
            : undefined,
          name: 'Présence au repas',
          diaryType,
          type: TrackerType.Note,
          max: 10,
          protected: true,
        }),
      );
      trackers.push(
        Tracker.create({
          dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
          patientId: patientId
            ? PatientId.create(new UniqueEntityID(patientId))
            : undefined,
          patientUserId: patientUserId
            ? UserId.create(new UniqueEntityID(patientUserId))
            : undefined,
          name: 'Niveau de fatigue',
          diaryType,
          type: TrackerType.Note,
          max: 10,
          protected: true,
          createdAt: new Date(),
        }),
      );
      trackers.push(
        Tracker.create({
          dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
          patientId: patientId
            ? PatientId.create(new UniqueEntityID(patientId))
            : undefined,
          patientUserId: patientUserId
            ? UserId.create(new UniqueEntityID(patientUserId))
            : undefined,
          name: 'Satisfaction nutritionnelle',
          diaryType,
          type: TrackerType.Note,
          max: 10,
          protected: true,
        }),
      );
      trackers.push(
        Tracker.create({
          dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
          patientId: patientId
            ? PatientId.create(new UniqueEntityID(patientId))
            : undefined,
          patientUserId: patientUserId
            ? UserId.create(new UniqueEntityID(patientUserId))
            : undefined,
          name: 'Échelle de sérénité',
          diaryType,
          type: TrackerType.Note,
          max: 10,
          protected: true,
        }),
      );
      trackers.push(
        Tracker.create({
          dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
          patientId: patientId
            ? PatientId.create(new UniqueEntityID(patientId))
            : undefined,
          patientUserId: patientUserId
            ? UserId.create(new UniqueEntityID(patientUserId))
            : undefined,
          name: 'Plaisir alimentaire',
          diaryType,
          type: TrackerType.Note,
          max: 10,
          protected: true,
        }),
      );
    }

    // Physical activity
    trackers.push(
      Tracker.create({
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        patientId: patientId
          ? PatientId.create(new UniqueEntityID(patientId))
          : undefined,
        patientUserId: patientUserId
          ? UserId.create(new UniqueEntityID(patientUserId))
          : undefined,
        name: 'Intensité',
        diaryType: DiaryType.PhysicalActivity,
        type: TrackerType.Note,
        max: 10,
        protected: true,
        activatedAt: new Date(),
      }),
    );
    trackers.push(
      Tracker.create({
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        patientId: patientId
          ? PatientId.create(new UniqueEntityID(patientId))
          : undefined,
        patientUserId: patientUserId
          ? UserId.create(new UniqueEntityID(patientUserId))
          : undefined,
        name: 'Durée (min)',
        diaryType: DiaryType.PhysicalActivity,
        type: TrackerType.Number,
        max: 120,
        protected: true,
        activatedAt: new Date(),
      }),
    );
    trackers.push(
      Tracker.create({
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        patientId: patientId
          ? PatientId.create(new UniqueEntityID(patientId))
          : undefined,
        patientUserId: patientUserId
          ? UserId.create(new UniqueEntityID(patientUserId))
          : undefined,
        name: 'Satisfaction',
        diaryType: DiaryType.PhysicalActivity,
        type: TrackerType.Note,
        max: 10,
        protected: true,
      }),
    );

    // Sleep
    trackers.push(
      Tracker.create({
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        patientId: patientId
          ? PatientId.create(new UniqueEntityID(patientId))
          : undefined,
        patientUserId: patientUserId
          ? UserId.create(new UniqueEntityID(patientUserId))
          : undefined,
        name: 'Heures de sommeil',
        diaryType: DiaryType.Sleep,
        type: TrackerType.Number,
        max: 12,
        protected: true,
        activatedAt: new Date(),
      }),
    );

    // Challenge of the day
    trackers.push(
      Tracker.create({
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        patientId: patientId
          ? PatientId.create(new UniqueEntityID(patientId))
          : undefined,
        patientUserId: patientUserId
          ? UserId.create(new UniqueEntityID(patientUserId))
          : undefined,
        name: 'Défi du jour',
        diaryType: DiaryType.ChallengeOfTheDay,
        type: TrackerType.Note,
        max: 10,
        protected: true,
      }),
    );

    // Feeling
    trackers.push(
      Tracker.create({
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        patientId: patientId
          ? PatientId.create(new UniqueEntityID(patientId))
          : undefined,
        patientUserId: patientUserId
          ? UserId.create(new UniqueEntityID(patientUserId))
          : undefined,
        name: 'Ressentis',
        diaryType: DiaryType.Feeling,
        type: TrackerType.Note,
        max: 10,
        protected: true,
      }),
    );
    try {
      await this.repository.saveBatch(trackers);
      this.eventProvider.dispatch(new TrackerBatchCreated(trackers));
      return trackers;
    } catch (e) {
      return [];
    }
  }

  public async saveGroupBatch(
    trackers: TrackerCellModelsWrapper,
    group: PatientGroup,
  ) {
    try {
      const trackerEntities = trackers.trackerCellModels.map(
        (tcm) => tcm.entity,
      );
      trackerEntities.forEach(
        (entity) =>
          (entity.patientGroupId = PatientGroupId.create(
            new UniqueEntityID(group.id.toString()),
          )),
      );
      await this.repository.saveBatch(trackerEntities);
      this.eventProvider.dispatch(new TrackerBatchSaved(trackerEntities));
      return trackers;
    } catch (e) {
      return [];
    }
  }

  public async createBatch(trackers: Tracker[]) {
    await this.repository.saveBatch(trackers);
    this.eventProvider.dispatch(new TrackerBatchSaved(trackers));
  }

  async savePatientBatch(trackers: TrackerCellModelsWrapper, patient: Patient) {
    try {
      const trackerEntities = trackers.trackerCellModels.map(
        (tcm) => tcm.entity,
      );
      trackerEntities.forEach(
        (entity) =>
          (entity.patientId = PatientId.create(
            new UniqueEntityID(patient.id.toString()),
          )),
      );
      await this.repository.saveBatch(trackerEntities);
      this.eventProvider.dispatch(new TrackerBatchSaved(trackerEntities));
      return trackers;
    } catch (e) {
      return [];
    }
  }
}
