import ValueObject from 'src/app/core/domain/value_object';

interface PictureProps {
  url: string;
  path: string;
}

export class Picture extends ValueObject<PictureProps> {
  get url(): string {
    return this.props.url;
  }

  get path(): string {
    return this.props.path;
  }

  private constructor(props: PictureProps) {
    super(props);
  }

  public static create(props: PictureProps): Picture {
    return new Picture(props);
  }
}
