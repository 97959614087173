import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { Tracker } from '../../tracker/tracker';

export class TrackerEvent extends DomainEvent<Tracker> {}

export class TrackerCreated extends TrackerEvent {}

export class TrackerBatchCreated extends TrackerEvent {
  trackers: Tracker[];

  constructor(trackers: Tracker[], notify?: boolean) {
    if (trackers.length == 0) throw new Error('Empty tracker list');

    super(trackers[0], notify);

    this.trackers = trackers;
  }
}

export class TrackerBatchSaved extends TrackerEvent {
  trackers: Tracker[];

  constructor(trackers: Tracker[], notify?: boolean) {
    if (trackers.length == 0) throw new Error('Empty tracker list');

    super(trackers[0], notify);

    this.trackers = trackers;
  }
}

export class CustomTrackerBatchCreated extends TrackerEvent {
  trackers: Tracker[];

  constructor(trackers: Tracker[], notify?: boolean) {
    if (trackers.length == 0) throw new Error('Empty custom tracker list');

    super(trackers[0], notify);

    this.trackers = trackers;
  }
}

export class TrackerUpdated extends TrackerEvent {}

export class TrackerDeleted extends TrackerEvent {}
