import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationStateProvider } from '../../data/auth/domain/authentication_state_provider';

@Injectable({
  providedIn: 'root',
})
export class SurveyGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationStateProvider: AuthenticationStateProvider,
  ) {
    // Nada
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!route.queryParams['s'] || !route.queryParams['d']) {
      this.router.navigateByUrl('/').then();
      return false;
    }
    return true;
  }
}
