import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import {
  AuthenticationStateProvider,
  AuthenticationStatus,
} from 'src/app/data/auth/domain/authentication_state_provider';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationStateProvider: AuthenticationStateProvider,
  ) {
    // do nothing
  }
  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.authenticationStateProvider.authentication$
      .pipe(
        filter((s) => s.status != AuthenticationStatus.LOADING), // ignore loading
      )
      .pipe(
        map((e) => {
          // not signed in, continue
          if (e.user == null) return true;

          // redirect
          this.router.navigateByUrl('/');
          return false;
        }),
      );
  }
}
