import Entity from '../entity';
import UniqueEntityID from '../unique_entity_id';

export abstract class DomainEvent<T extends Entity<unknown>> {
  date: Date;
  entity: T;
  notify: boolean;

  constructor(entity: T, notify = true) {
    this.date = new Date();
    this.entity = entity;
    this.notify = notify;
  }

  getEntityId(): UniqueEntityID {
    return this.entity.id;
  }
}
