import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { UnAuthorizedException } from 'src/app/core/logic/exception';
import {
  PatientActivated,
  PatientArchived,
} from 'src/app/data/patient/domain/events/patient.events';
import { PatientEventProvider } from 'src/app/data/patient/domain/events/patient_event_provider';

import { Patient } from '../../../patient/domain/patient';
import { PatientCommands } from '../../../patient/domain/patient_commands';
import { PatientGroupRepository } from '../../repositories/patient_group_repository';
import { DietitianStateProvider } from '../dietitian_state_provider';
import { EncouragementEventProvider } from '../events/encouragement/encouragement_event_provider';
import {
  EncouragementBatchDeleted,
  EncouragementCreated,
  EncouragementDeleted,
} from '../events/encouragement/encouragement_events';
import { PatientGroupEventProvider } from '../events/patient_group_event_provider';
import {
  PatientGroupCreated,
  PatientGroupDeleted,
  PatientGroupUpdated,
} from '../events/patient_group_events';
import { PatientGroup, PatientGroupProps } from './patient_group';
import { PatientGroupExistingNameException } from './patient_group_exceptions';

@Injectable()
export class PatientGroupCommands {
  constructor(
    private repository: PatientGroupRepository,
    private eventProvider: PatientGroupEventProvider,
    private patientEventProvider: PatientEventProvider,
    private encouragementEventProvider: EncouragementEventProvider,
    private dietitianStateProvider: DietitianStateProvider,
    private patientCommands: PatientCommands,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof PatientGroupCreated) {
        this.toastr.success('Groupe créé');
      } else if (event instanceof PatientGroupUpdated) {
        this.toastr.success('Groupe mis à jour');
      } else if (event instanceof PatientGroupDeleted) {
        this.toastr.success('Groupe supprimé');
      }
    });

    this.patientEventProvider.events$.subscribe((event) => {
      if (
        event instanceof PatientActivated ||
        event instanceof PatientArchived
      ) {
        this.onPatientActivatedOrArchived(event).then();
      }
    });

    this.encouragementEventProvider.events$.subscribe((event) => {
      if (
        event instanceof EncouragementCreated ||
        event instanceof EncouragementDeleted ||
        event instanceof EncouragementBatchDeleted
      ) {
        this.onEncouragementCreatedOrDeleted(event).then();
      }
    });
  }

  async getPatientGroup(patientGroupId: string): Promise<PatientGroup> {
    return this.repository.load(patientGroupId);
  }

  async getPatientGroups(patientGroupIds: string[]): Promise<PatientGroup[]> {
    if (patientGroupIds) {
      const results: PatientGroup[] = [];
      for (const id of patientGroupIds) {
        results.push(await this.repository.load(id));
      }
      return Promise.resolve(results);
    }
    return [];
  }

  getDietitianPatientGroups(dietitianId: string): Promise<PatientGroup[]> {
    return this.repository.findByDietitianId(dietitianId);
  }

  getCurrentDietitianPatientGroups(): Promise<PatientGroup[]> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }
    return this.getDietitianPatientGroups(
      this.dietitianStateProvider.state.entity.id.toString(),
    );
  }

  async savePatientGroup(
    patientGroupProps: PatientGroupProps,
    patientGroupId?: string,
    notify = true,
  ): Promise<PatientGroup> {
    let patientGroup = PatientGroup.create(
      patientGroupProps,
      new UniqueEntityID(patientGroupId),
    );
    if (patientGroupId !== undefined) {
      patientGroup = await this.repository.save(patientGroup);
      this.eventProvider.dispatch(
        new PatientGroupUpdated(patientGroup, notify),
      );
    } else {
      const exists = await this.repository.existsForDietitianIdAndName(
        patientGroupProps.dietitianId.id.toString(),
        patientGroupProps.name,
        patientGroupId,
      );

      if (exists) {
        throw new PatientGroupExistingNameException(patientGroupProps.name);
      }

      patientGroup = await this.repository.create(patientGroup);
      this.eventProvider.dispatch(
        new PatientGroupCreated(patientGroup, notify),
      );
    }
    return patientGroup;
  }

  getPatientGroupPatients(
    dietitianId: string,
    groupId: string,
    includeArchived = true,
  ): Promise<Patient[]> {
    return this.patientCommands.getPatientGroupPatients(
      dietitianId,
      groupId,
      includeArchived,
    );
  }

  async addSinglePatientToGroup(
    dietitianId: string,
    patientId: string,
    groupId: string,
    updateTrackers: boolean,
  ) {
    let patient = await this.patientCommands.getPatient(patientId);
    if (patient?.dietId != dietitianId) {
      throw new UnAuthorizedException();
    }
    // update previous group
    let oldPatientGroups: PatientGroup[] = [];
    if (patient.groupId) {
      oldPatientGroups = await this.getPatientGroups(patient.groupId);
    }

    const nextPatientGroup = await this.getPatientGroup(groupId);
    // Vérification des nouveaux groupes
    if (nextPatientGroup.dietitianId.id.toString() !== dietitianId) {
      throw new UnAuthorizedException();
    }

    // Comptage des groupes ajoutés
    if (
      oldPatientGroups
        .map((g) => g.id.toString())
        .indexOf(nextPatientGroup.id.toString()) < 0
    ) {
      await this.savePatientGroup(
        nextPatientGroup.updatePatientCount(
          Math.max(nextPatientGroup.patientCount + 1, 0),
          patient.archived
            ? nextPatientGroup.activatedPatientCount
            : Math.max(nextPatientGroup.activatedPatientCount + 1, 0),
          patient.archived
            ? Math.max(nextPatientGroup.archivedPatientCount + 1, 0)
            : nextPatientGroup.archivedPatientCount,
        ),
        nextPatientGroup.id.toString(),
        false,
      );
    }

    if (patient.groupId && patient.groupId.length > 0) {
      patient = patient.updateGroupId([...patient.groupId, groupId]);
    } else {
      patient = patient.updateGroupId([groupId]);
    }
    await this.patientCommands.update(patient, true, false);

    if (updateTrackers) {
      await this.patientCommands.updateTrackersByGroup(patient);
    }
  }

  async updatePatientGroupPatients(
    groupId: string,
    patientIds: string[],
    updateTrackers: boolean,
  ): Promise<void> {
    const dietitianId =
      this.dietitianStateProvider.state.entity?.dietitianId.id.toString();

    if (!dietitianId) throw new UnAuthorizedException();

    const nextPatientGroup = await this.getPatientGroup(groupId);
    // Vérification des nouveaux groupes
    if (nextPatientGroup.dietitianId.id.toString() !== dietitianId) {
      throw new UnAuthorizedException();
    }

    const existingPatientsForGroup = await this.getPatientGroupPatients(
      dietitianId,
      groupId,
    );

    const operations: Promise<unknown>[] = [];
    let patientCount = 0;
    let activatedPatientCount = 0;
    let archivedPatientCount = 0;

    // Vérification des patients qui sont sortis du groupe
    for (const existingPatient of existingPatientsForGroup) {
      const index = patientIds.indexOf(existingPatient.id.toString());
      if (index === -1) {
        if (existingPatient.groupId && existingPatient.groupId.length > 0) {
          existingPatient.groupId.splice(
            existingPatient.groupId.indexOf(groupId),
            1,
          );
          existingPatient.updateGroupId(existingPatient.groupId);
        }
        operations.push(
          this.patientCommands.update(existingPatient, false, false),
        );
      }
    }

    // Vérification des patients qui sont déjà dans le groupe
    for (const existingPatient of existingPatientsForGroup) {
      const index = patientIds.indexOf(existingPatient.id.toString());
      if (index >= 0) {
        patientIds.splice(index, 1);
        patientCount++;
        if (existingPatient.archived) {
          archivedPatientCount++;
        } else {
          activatedPatientCount++;
        }
      }
    }

    // Seulement les nouveaux patients affectés au groupe
    const patients: Patient[] = [];
    for (const id of patientIds) {
      const patient = await this.patientCommands.getPatient(id);
      if (patient?.dietId === dietitianId) {
        patients.push(patient);
      }
    }

    patientCount += patients.length;
    archivedPatientCount += patients.filter((p) => p.archived).length;
    activatedPatientCount += patients.filter((p) => !p.archived).length;
    for (let patient of patients) {
      if (patient.groupId) {
        patient = patient.updateGroupId([...patient.groupId, groupId]);
      } else {
        patient = patient.updateGroupId([groupId]);
      }
      operations.push(this.patientCommands.update(patient, true, false));
      if (updateTrackers) {
        operations.push(this.patientCommands.updateTrackersByGroup(patient));
      }
    }

    await Promise.all(operations);

    await this.savePatientGroup(
      nextPatientGroup.updatePatientCount(
        patientCount,
        activatedPatientCount,
        archivedPatientCount, // archivedPatientCount, for now archived patient count is always 0
      ),
      nextPatientGroup.id.toString(),
    );
  }

  async onPatientActivatedOrArchived(
    event: PatientActivated | PatientArchived,
  ) {
    if (!event.entity.groupId) return;

    // check access
    const dietitianId =
      this.dietitianStateProvider.state.entity?.dietitianId.id.toString();
    if (!dietitianId) return;

    // check group access
    const patientGroups = await this.getPatientGroups(event.entity.groupId);
    for (const patientGroup of patientGroups) {
      if (patientGroup.dietitianId.id.toString() !== dietitianId) {
        return;
      }
      await this.savePatientGroup(
        patientGroup.updatePatientCount(
          patientGroup.patientCount,
          patientGroup.activatedPatientCount + (event.entity.archived ? -1 : 1),
          0, // all archived are now removed from group (see next)
        ),
        patientGroup.id.toString(),
      );
    }
  }

  async onEncouragementCreatedOrDeleted(
    event:
      | EncouragementCreated
      | EncouragementDeleted
      | EncouragementBatchDeleted,
  ) {
    if (!event.entity.scheduled || !event.entity.groupId) return;

    // check access
    const dietitianId =
      this.dietitianStateProvider.state.entity?.dietitianId.id.toString();
    if (!dietitianId) return;

    // check group access
    const patientGroup = await this.getPatientGroup(
      event.entity.groupId.id.toString(),
    );
    if (patientGroup.dietitianId.id.toString() !== dietitianId) {
      return;
    }

    let countChange = 0;
    if (event instanceof EncouragementCreated) {
      countChange++;
    } else if (event instanceof EncouragementDeleted) {
      countChange--;
    } else {
      countChange -= event.encouragements.filter((e) => e.scheduled).length;
    }

    // update encouragements count
    await this.savePatientGroup(
      patientGroup.copyWith({
        encouragementCount: patientGroup.encouragementCount + countChange,
      } as PatientGroupProps),
      patientGroup.id.toString(),
    );
  }

  async deletePatientGroup(patientGroupId: string): Promise<PatientGroup> {
    const patientGroup = await this.repository.load(patientGroupId);

    const patients = await this.getPatientGroupPatients(
      patientGroup.dietitianId.id.toString(),
      patientGroup.id.toString(),
    );

    const operations: Promise<unknown>[] = [];
    for (const patient of patients) {
      operations.push(
        this.patientCommands.update(patient.updateGroupId(), true, false),
      );
    }

    await Promise.all(operations);
    await this.repository.delete(patientGroupId);
    this.eventProvider.dispatch(new PatientGroupDeleted(patientGroup));
    return patientGroup;
  }

  async addMultiPatientsToGroup(
    dietitianId: string,
    patientIds: string[],
    selectedGroupId: string,
    updateTrackers: boolean,
  ): Promise<Patient[]> {
    const patients: Patient[] = [];
    let countNextPatientGroup = 0;
    const nextPatientGroup = await this.getPatientGroup(selectedGroupId);
    if (nextPatientGroup.dietitianId.id.toString() !== dietitianId) {
      throw new UnAuthorizedException();
    }

    for (const patientId of patientIds) {
      let patient = await this.patientCommands.getPatient(patientId);
      if (patient?.dietId != dietitianId) {
        throw new UnAuthorizedException();
      }
      // update previous group
      let oldPatientGroups: PatientGroup[] = [];
      if (patient.groupId) {
        oldPatientGroups = await this.getPatientGroups(patient.groupId);
      }

      // Comptage des groupes ajoutés
      if (
        oldPatientGroups
          .map((g) => g.id.toString())
          .indexOf(nextPatientGroup.id.toString()) < 0
      ) {
        countNextPatientGroup++;
      }

      if (patient.groupId && patient.groupId.length > 0) {
        patient = patient.updateGroupId([...patient.groupId, selectedGroupId]);
      } else {
        patient = patient.updateGroupId([selectedGroupId]);
      }
      await this.patientCommands.update(patient, true, false);

      if (updateTrackers) {
        await this.patientCommands.updateTrackersByGroup(patient);
      }

      patients.push(patient);
    }

    await this.savePatientGroup(
      nextPatientGroup.updatePatientCount(
        Math.max(nextPatientGroup.patientCount + countNextPatientGroup, 0),
        Math.max(
          nextPatientGroup.activatedPatientCount + countNextPatientGroup,
          0,
        ),
        nextPatientGroup.archivedPatientCount,
      ),
      nextPatientGroup.id.toString(),
      false,
    );

    return patients;
  }

  async addGroupedPatientsToGroup(
    dietitianId: string,
    allPatientOfGroupId: string,
    selectedGroupId: string,
    updateTrackers: boolean,
  ): Promise<Patient[]> {
    const patients = await this.patientCommands.getPatientGroupPatients(
      dietitianId,
      allPatientOfGroupId,
      true,
    );
    let countNextPatientGroup = 0;
    const nextPatientGroup = await this.getPatientGroup(selectedGroupId);
    if (nextPatientGroup.dietitianId.id.toString() !== dietitianId) {
      throw new UnAuthorizedException();
    }

    for (let patient of patients) {
      if (patient?.dietId != dietitianId) {
        throw new UnAuthorizedException();
      }
      // update previous group
      let oldPatientGroups: PatientGroup[] = [];
      if (patient.groupId) {
        oldPatientGroups = await this.getPatientGroups(patient.groupId);
      }

      // Comptage des groupes ajoutés
      if (
        oldPatientGroups
          .map((g) => g.id.toString())
          .indexOf(nextPatientGroup.id.toString()) < 0
      ) {
        countNextPatientGroup++;
      }

      if (patient.groupId && patient.groupId.length > 0) {
        patient = patient.updateGroupId([...patient.groupId, selectedGroupId]);
      } else {
        patient = patient.updateGroupId([selectedGroupId]);
      }
      await this.patientCommands.update(patient, true, false);

      if (updateTrackers) {
        await this.patientCommands.updateTrackersByGroup(patient);
      }
    }
    await this.savePatientGroup(
      nextPatientGroup.updatePatientCount(
        Math.max(nextPatientGroup.patientCount + countNextPatientGroup, 0),
        Math.max(
          nextPatientGroup.activatedPatientCount + countNextPatientGroup,
          0,
        ),
        nextPatientGroup.archivedPatientCount,
      ),
      nextPatientGroup.id.toString(),
      false,
    );
    return patients;
  }

  async removeMultiPatientsFromGroup(
    dietitianId: string,
    patientIds: string[],
    selectedGroupId: string,
  ): Promise<Patient[]> {
    const patients: Patient[] = [];
    let countNextPatientGroup = 0;
    const nextPatientGroup = await this.getPatientGroup(selectedGroupId);
    if (nextPatientGroup.dietitianId.id.toString() !== dietitianId) {
      throw new UnAuthorizedException();
    }

    for (const patientId of patientIds) {
      let patient = await this.patientCommands.getPatient(patientId);
      if (patient?.dietId != dietitianId) {
        throw new UnAuthorizedException();
      }
      let oldPatientGroups: PatientGroup[] = [];
      if (patient.groupId) {
        oldPatientGroups = await this.getPatientGroups(patient.groupId);
      }

      if (patient.groupId && patient.groupId.length > 0) {
        const currents = patient.groupId;
        const groupIdx = patient.groupId.indexOf(selectedGroupId);
        if (groupIdx >= 0) {
          currents.splice(groupIdx, 1);
        }
        patient = patient.updateGroupId(currents);
        await this.patientCommands.update(patient, true, false);

        // Comptage des groupes retirés
        if (
          oldPatientGroups
            .map((g) => g.id.toString())
            .indexOf(nextPatientGroup.id.toString()) >= 0
        ) {
          countNextPatientGroup++;
        }
      }
      patients.push(patient);
    }

    await this.savePatientGroup(
      nextPatientGroup.updatePatientCount(
        Math.max(nextPatientGroup.patientCount - countNextPatientGroup, 0),
        Math.max(
          nextPatientGroup.activatedPatientCount - countNextPatientGroup,
          0,
        ),
        nextPatientGroup.archivedPatientCount,
      ),
      nextPatientGroup.id.toString(),
      false,
    );

    return patients;
  }

  async removeSinglePatientFromGroup(
    dietitianId: string,
    patientId: string,
    selectedGroupId: string,
  ) {
    let patient = await this.patientCommands.getPatient(patientId);
    if (patient?.dietId != dietitianId) {
      throw new UnAuthorizedException();
    }
    let oldPatientGroups: PatientGroup[] = [];
    if (patient.groupId) {
      oldPatientGroups = await this.getPatientGroups(patient.groupId);
    }

    const nextPatientGroup = await this.getPatientGroup(selectedGroupId);
    if (nextPatientGroup.dietitianId.id.toString() !== dietitianId) {
      throw new UnAuthorizedException();
    }
    if (patient.groupId && patient.groupId.length > 0) {
      const currents = patient.groupId;
      const groupIdx = patient.groupId.indexOf(selectedGroupId);
      if (groupIdx >= 0) {
        currents.splice(groupIdx, 1);
      }
      patient = patient.updateGroupId(currents);
      await this.patientCommands.update(patient, true, false);

      // Comptage des groupes retirés
      if (
        oldPatientGroups
          .map((g) => g.id.toString())
          .indexOf(nextPatientGroup.id.toString()) >= 0
      ) {
        await this.savePatientGroup(
          nextPatientGroup.updatePatientCount(
            Math.max(nextPatientGroup.patientCount - 1, 0),
            patient.archived
              ? nextPatientGroup.activatedPatientCount
              : Math.max(nextPatientGroup.activatedPatientCount - 1, 0),
            patient.archived
              ? Math.max(nextPatientGroup.archivedPatientCount - 1, 0)
              : nextPatientGroup.archivedPatientCount,
          ),
          nextPatientGroup.id.toString(),
          false,
        );
      }
    }
  }
}
