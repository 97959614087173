import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export interface EncouragementTemplateProps {
  title: string;
  content: string;
}

export class EncouragementTemplateId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): EncouragementTemplateId {
    return new EncouragementTemplateId(id);
  }
}

export class EncouragementTemplate extends Entity<EncouragementTemplateProps> {
  get encouragementTemplateId(): EncouragementTemplateId {
    return EncouragementTemplateId.create(this.id);
  }

  get content(): string {
    return this.props.content;
  }

  set content(value: string) {
    this.props.content = value;
  }

  get title(): string {
    return this.props.title;
  }

  set title(value: string) {
    this.props.title = value;
  }

  private constructor(props: EncouragementTemplateProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: EncouragementTemplateProps): EncouragementTemplate {
    return EncouragementTemplate.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: EncouragementTemplateProps,
    id?: UniqueEntityID,
  ): EncouragementTemplate {
    return new EncouragementTemplate(props, id);
  }
}
