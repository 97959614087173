import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { UserId } from 'src/app/data/auth/domain/user';

import { PatientId } from '../../../patient/domain/patient';
import { DiaryTracker } from './diary_tracker';
import { DiaryType } from './diary_type';
import { Picture } from './picture';

export interface DiaryProps {
  patientId: PatientId;
  patientUserId?: UserId;
  createdAt?: Date;
  updatedAt?: Date;
  name?: string;
  type: DiaryType;
  pictures: Picture[];
  trackers: DiaryTracker[];
  archivedAt?: Date;
  comment?: string;
}

export class DiaryId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): DiaryId {
    return new DiaryId(id);
  }
}

export class Diary extends Entity<DiaryProps> {
  get diaryId(): DiaryId {
    return DiaryId.create(this.id);
  }

  get patientId(): PatientId {
    return this.props.patientId;
  }

  get patientUserId(): UserId | undefined {
    return this.props.patientUserId;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get archivedAt(): Date | undefined {
    return this.props.archivedAt;
  }

  get name(): string | undefined {
    return this.props.name;
  }

  get type(): DiaryType {
    return this.props.type;
  }

  get pictures(): Picture[] {
    return this.props.pictures;
  }

  get trackers(): DiaryTracker[] {
    return this.props.trackers;
  }

  get givenTrackers(): DiaryTracker[] {
    return this.props.trackers.filter((tracker) => tracker.value >= 0);
  }

  get comment(): string | undefined {
    return this.props.comment;
  }

  private constructor(props: DiaryProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: DiaryProps): Diary {
    return Diary.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: DiaryProps, id?: UniqueEntityID): Diary {
    return new Diary(props, id);
  }
}
