import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, map, Observable } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { DietitianId } from '../domain/dietitian';
import { FeatureId } from '../domain/subscription/feature';
import { PaymentMethod } from '../domain/subscription/payment_method';
import { StripePriceId } from '../domain/subscription/premium';
import {
  StripeProductId,
  StripeSubscriptionId,
  SubscriptionStatus,
} from '../domain/subscription/subscription';
import { SubscriptionFeature } from '../domain/subscription/subscription_feature';
import { PaymentMethodSchema } from './subscription_repository';

export interface SubscriptionFeatureSchema {
  stripeSubscriptionId: string | null;
  stripeProductId: string | null;
  stripePriceId: string | null;
  featureId: string;
  dietitianId: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  status: SubscriptionStatus;
  periodStart: Timestamp;
  periodEnd: Timestamp;
  paymentMethod: PaymentMethodSchema | null;
  invoiceActionRequired: string | null;
}

@Injectable()
export class SubscriptionFeatureRepository {
  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    // Nada
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<SubscriptionFeatureSchema>(
      'subscription_features',
      queryFn,
    );
  }

  toSchema(
    subscriptionFeature: SubscriptionFeature,
  ): SubscriptionFeatureSchema {
    return {
      featureId: subscriptionFeature.featureId.id.toString(),
      dietitianId: subscriptionFeature.dietitianId.id.toString(),
      stripeSubscriptionId:
        subscriptionFeature.stripeSubscriptionId?.id.toString() ?? null,
      stripeProductId:
        subscriptionFeature.stripeProductId?.id.toString() ?? null,
      stripePriceId: subscriptionFeature.stripePriceId?.id.toString() ?? null,
      createdAt:
        subscriptionFeature.createdAt !== undefined
          ? Timestamp.fromDate(subscriptionFeature.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      status: subscriptionFeature.status,
      periodStart: Timestamp.fromDate(subscriptionFeature.periodStart),
      periodEnd: Timestamp.fromDate(subscriptionFeature.periodEnd),
      paymentMethod: subscriptionFeature.paymentMethod
        ? {
            id: subscriptionFeature.paymentMethod.id,
            type: subscriptionFeature.paymentMethod.type,
            owner: subscriptionFeature.paymentMethod.owner ?? null,
            cardBrand: subscriptionFeature.paymentMethod.cardBrand ?? null,
            cardLast4Digits:
              subscriptionFeature.paymentMethod.cardLast4Digits ?? null,
            sepaBankCode:
              subscriptionFeature.paymentMethod.sepaBankCode ?? null,
            expMonth: subscriptionFeature.paymentMethod.expMonth ?? null,
            expYear: subscriptionFeature.paymentMethod.expYear ?? null,
            default: subscriptionFeature.paymentMethod.default,
          }
        : null,
      invoiceActionRequired: subscriptionFeature.invoiceActionRequired ?? null,
    };
  }

  fromSchema(
    schema: SubscriptionFeatureSchema,
    id: string,
  ): SubscriptionFeature {
    return SubscriptionFeature.create(
      {
        featureId: FeatureId.create(new UniqueEntityID(schema.featureId)),
        dietitianId: DietitianId.create(new UniqueEntityID(schema.dietitianId)),
        stripeSubscriptionId: schema.stripeSubscriptionId
          ? StripeSubscriptionId.create(
              new UniqueEntityID(schema.stripeSubscriptionId),
            )
          : undefined,
        stripeProductId: schema.stripeProductId
          ? StripeProductId.create(new UniqueEntityID(schema.stripeProductId))
          : undefined,
        stripePriceId: schema.stripePriceId
          ? StripePriceId.create(new UniqueEntityID(schema.stripePriceId))
          : undefined,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        status: schema.status,
        periodStart: schema.periodStart.toDate(),
        periodEnd: schema.periodEnd.toDate(),
        paymentMethod: schema.paymentMethod
          ? PaymentMethod.create({
              id: schema.paymentMethod.id,
              type: schema.paymentMethod.type,
              owner: schema.paymentMethod.owner ?? undefined,
              cardBrand: schema.paymentMethod.cardBrand ?? undefined,
              cardLast4Digits:
                schema.paymentMethod.cardLast4Digits ?? undefined,
              sepaBankCode: schema.paymentMethod.sepaBankCode ?? undefined,
              expMonth: schema.paymentMethod.expMonth ?? undefined,
              expYear: schema.paymentMethod.expYear ?? undefined,
              default: schema.paymentMethod.default,
            })
          : undefined,
        invoiceActionRequired: schema.invoiceActionRequired ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async findDietitianFeatureRunningSubscriptionFeature(
    dietitianId: string,
    featureId: string,
  ): Promise<SubscriptionFeature | undefined> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .where('featureId', '==', featureId)
          .where('status', 'in', [
            SubscriptionStatus.Active,
            SubscriptionStatus.Trialing,
            SubscriptionStatus.PendingCancellation,
            SubscriptionStatus.Unpaid,
            SubscriptionStatus.PastDue,
            SubscriptionStatus.Incomplete,
          ])
          .where('periodEnd', '>=', new Date())
          .orderBy('periodEnd', 'desc')
          .orderBy('createdAt', 'desc'),
      ).get(),
    );

    if (snap.size == 0) return undefined;

    const docs = snap.docs.sort((a, b) =>
      a.data().status.localeCompare(b.data().status),
    );

    return this.fromSchema(docs[0].data(), docs[0].id);
  }

  async findDietitianFeaturesRunningSubscription(
    dietitianId: string,
  ): Promise<{ [key: string]: SubscriptionFeature } | undefined> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .where('status', 'in', [
            SubscriptionStatus.Active,
            SubscriptionStatus.Trialing,
            SubscriptionStatus.PendingCancellation,
          ])
          .where('periodEnd', '>=', new Date())
          .orderBy('periodEnd', 'desc')
          .orderBy('createdAt', 'desc'),
      ).get(),
    );

    if (snap.size == 0) {
      return undefined;
    }

    const featureSubscriptions = snap.docs
      .sort((a, b) => a.data().status.localeCompare(b.data().status))
      .map((d) => this.fromSchema(d.data(), d.id));

    const tmp: SubscriptionFeature[] = [];
    for (const subscription of featureSubscriptions) {
      if (
        tmp.filter((s) => s.stripePriceId === subscription.stripePriceId)
          .length === 0
      ) {
        tmp.push(subscription);
      }
    }

    if (tmp.length === 0) {
      return undefined;
    } else {
      const result: { [key: string]: SubscriptionFeature } = {};
      for (const item of tmp) {
        if (item.stripePriceId) {
          result[item.stripePriceId.id.toString()] = item;
        }
      }
      return result;
    }
  }

  async findById(id: string): Promise<SubscriptionFeature> {
    const snap = await firstValueFrom(this.collection().doc(id).get());
    return this.fromSchema(snap.data() as SubscriptionFeatureSchema, snap.id);
  }

  subscriptionFeatureValueChanges(
    subscriptionFeatureId: string,
  ): Observable<SubscriptionFeature | undefined> {
    return this.collection()
      .doc(subscriptionFeatureId)
      .valueChanges()
      .pipe(
        map((v) => {
          if (v) {
            return this.fromSchema(v, subscriptionFeatureId);
          } else {
            return undefined;
          }
        }),
      );
  }
}
