import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { Composition } from '../../composition/composition';

export class CompositionEvent extends DomainEvent<Composition> {}

export class CompositionCreated extends CompositionEvent {}

export class CompositionUpdated extends CompositionEvent {}

export class CompositionDeleted extends CompositionEvent {}
