import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { EmailModule } from '../email/email.module';
import { DocumentCommands } from './domain/document/document_commands';
import { DocumentEventProvider } from './domain/events/document/document_event_provider';
import { SharedDocumentEventProvider } from './domain/events/shared_document/shared_document_event_provider';
import { SharedDocumentCommands } from './domain/shared_document/shared_document_commands';
import { DocumentRepository } from './repositories/document_repository';
import { SharedDocumentRepository } from './repositories/shared_document_repository';

@NgModule({
  declarations: [],
  imports: [CommonModule, ToastrModule.forRoot(), EmailModule],
  providers: [
    DocumentRepository,
    SharedDocumentRepository,
    DocumentEventProvider,
    SharedDocumentEventProvider,
    DocumentCommands,
    SharedDocumentCommands,
  ],
})
export class DocumentModule {
  static forRoot(): ModuleWithProviders<DocumentModule> {
    return {
      ngModule: DocumentModule,
      providers: [
        {
          provide: DocumentEventProvider,
        },
        {
          provide: SharedDocumentEventProvider,
        },
        {
          provide: DocumentCommands,
        },
        {
          provide: SharedDocumentCommands,
        },
      ],
    };
  }
}
