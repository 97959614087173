import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { Notification } from '../domain/notification/notification';
import { NotificationType } from '../domain/notification/notification';

export interface NotificationSchema {
  createdAt: Timestamp;
  type: NotificationType;
  readAt: Timestamp | null;
  payload: Map<string, string> | null;
}

@Injectable()
export class NotificationRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection<NotificationSchema>('dietitians')
      .doc(dietitianId)
      .collection<NotificationSchema>('notifications', queryFn);
  }

  toSchema(notification: Notification): NotificationSchema {
    return <NotificationSchema>{
      type: notification.type,
      payload: notification.payload,
      createdAt: Timestamp.fromDate(notification.createdAt),
      readAt:
        notification.readAt !== undefined
          ? Timestamp.fromDate(notification.readAt)
          : null,
    };
  }

  fromSchema(schema: NotificationSchema, id: string): Notification {
    return Notification.create(
      {
        readAt: schema.readAt?.toDate() ?? undefined,
        createdAt: schema.createdAt.toDate(),
        type: schema.type,
        payload: schema.payload ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async findDietitianNotifications(
    dietitianId: string,
  ): Promise<Notification[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('readAt', '==', null).orderBy('createdAt', 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  save(dietitianId: string, notification: Notification): Promise<Notification> {
    const schema = this.toSchema(notification);
    return this.collection(dietitianId)
      .doc(notification.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(schema, notification.notificationId.id.toString()),
      );
  }
}
