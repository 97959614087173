import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';

import { GlobalErrorHandler } from './error/global-error-handler';
import { CapitalizePipe } from './pipe/capitalize.pipe';
import { DateToStringPipe } from './pipe/date-to-string.pipe';
import { FormatDatePipe } from './pipe/format-date.pipe';
import { FormatMoneyPipe } from './pipe/format-money.pipe';
import { LeftPadPipe } from './pipe/left-pad.pipe';
import { PhoneRenderPipe } from './pipe/phone-render.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';

@NgModule({
  declarations: [
    DateToStringPipe,
    SafeHtmlPipe,
    CapitalizePipe,
    PhoneRenderPipe,
    LeftPadPipe,
    FormatDatePipe,
    FormatMoneyPipe,
  ],
  imports: [CommonModule],
  exports: [
    DateToStringPipe,
    SafeHtmlPipe,
    CapitalizePipe,
    PhoneRenderPipe,
    LeftPadPipe,
    FormatDatePipe,
    FormatMoneyPipe,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class CoreModule {}
