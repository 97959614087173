export enum PatientSerie {
  ArmSize = 'arm_size',
  FatMass = 'fat_mass',
  FormWeight = 'form_weight',
  HipSize = 'hip_size',
  MuscularMass = 'muscular_mass',
  Size = 'size',
  ThighSize = 'thigh_size',
  WaistSize = 'waist_size',
  Weight = 'weight',
}
