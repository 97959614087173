import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'rxjs';

import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { MessageModel } from '../../../../ui/components/message/message.models';
import { Dietitian } from '../../../dietitian/domain/dietitian';
import { Patient } from '../../../patient/domain/patient';
import {
  MessageRepository,
  UploadResult,
} from '../../repositories/message_repository';
import { MessageEventProvider } from '../events/message/message_event_provider';
import {
  MessageReceived,
  MessageSent,
  MessageUpdated,
} from '../events/message/message_events';
import { Message, MessageProps } from './message';

@Injectable()
export class MessageCommands {
  private audioEngine = new Audio();
  private firstLoading = true;

  constructor(
    private repository: MessageRepository,
    private eventProvider: MessageEventProvider,
    private toastr: ToastrService,
  ) {
    this.audioEngine.src = './assets/sounds/new-message.wav';
    this.audioEngine.load();

    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof MessageSent) {
        // this.toastr.success('Message envoyé');
      } else if (event instanceof MessageReceived) {
        this.audioEngine.currentTime = 0;
        this.audioEngine.play();
        this.toastr.success('Nouveau message');
      }
    });
  }

  listen(messageId: string, dietitian: Dietitian, patient: Patient) {
    return this.repository
      .listenOnMessageId(messageId)
      .pipe(
        tap((messages) => {
          if (this.firstLoading) {
            this.firstLoading = false;
            return;
          }

          if (messages.length > 0) {
            const lastItem = messages[messages.length - 1];
            if (lastItem) {
              // TODO: called two times ? why ?
              // this.eventProvider.dispatch(new ConversationReceived(lastItem));
            }
          }
        }),
      )
      .pipe(
        map((messages) => {
          return messages.map((message) => {
            let avatarUrl, name, initials;
            if (message.senderId.equals(patient.id)) {
              avatarUrl = patient.avatarUrl;
              name = patient.fullName;
              initials = patient.initials;
            } else {
              avatarUrl = dietitian.picture?.url;
              name = dietitian.fullName;
              initials = dietitian.initials;
            }

            return {
              avatarUrl,
              name,
              date: message.createdAt,
              message: message.message,
              initials,
              audioUrl: message.mimeType?.includes('audio/')
                ? message.url
                : undefined,
              imageUrl: message.mimeType?.includes('image/')
                ? message.url
                : undefined,
            } as MessageModel;
          });
        }),
      );
  }

  uploadPicture(
    messageId: string,
    userId: string,
    imageUrl: string,
  ): Promise<UploadResult> {
    return this.repository.savePictureAttachment(messageId, userId, imageUrl);
  }

  uploadAudio(
    messageId: string,
    userId: string,
    audioBlob: Blob,
  ): Promise<UploadResult> {
    return this.repository.saveAudioAttachment(messageId, userId, audioBlob);
  }

  async save(
    conversationId: string,
    props: MessageProps,
    messageId?: string,
  ): Promise<Message> {
    let message = Message.create(props, new UniqueEntityID(messageId));
    if (messageId !== undefined) {
      message = await this.repository.save(conversationId, message);
      this.eventProvider.dispatch(new MessageUpdated(message));
    } else {
      message = await this.repository.create(conversationId, message);
      this.eventProvider.dispatch(new MessageSent(message));
    }

    return message;
  }
}
