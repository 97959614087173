import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { News } from '../domain/news';

export interface NewsSchema {
  subtitle: string;
  content: string;
  date: Timestamp;
}

@Injectable()
export class NewsRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<NewsSchema>('news', queryFn);
  }

  toSchema(news: News): NewsSchema {
    return <NewsSchema>{
      subtitle: news.subtitle,
      content: news.content,
      date: Timestamp.fromDate(news.date),
    };
  }

  fromSchema(schema: NewsSchema, id: string): News {
    return News.create(
      {
        subtitle: schema.subtitle,
        content: schema.content,
        date: schema.date.toDate(),
      },
      new UniqueEntityID(id),
    );
  }

  async findLastTwoNews(): Promise<News[]> {
    const snap = await firstValueFrom(
      this.collection((ref) => ref.orderBy('date', 'desc').limit(2)).get(),
    );
    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async findAllAfterDate(after: Date): Promise<News[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref.where('date', '>=', after).orderBy('date', 'desc'),
      ).get(),
    );
    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async create(news: News): Promise<News> {
    const schema = this.toSchema(news);
    const ref = await this.collection().add(schema);
    return this.fromSchema(schema, ref.id);
  }

  async save(news: News): Promise<News> {
    const schema = this.toSchema(news);
    await this.collection().doc(news.newsId.id.toString()).set(schema);
    return this.fromSchema(schema, news.newsId.id.toString());
  }

  async delete(newsId: string): Promise<void> {
    return this.collection().doc(newsId).delete();
  }
}
