import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { PatientSerieItem } from '../../patient_serie/patient_serie_item';

export class PatientSerieEvent extends DomainEvent<PatientSerieItem> {}

export class PatientSerieItemCreated extends PatientSerieEvent {}

export class PatientSerieItemUpdated extends PatientSerieEvent {}

export class PatientSerieItemDeleted extends PatientSerieEvent {}
