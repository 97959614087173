import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

export interface UserProps {
  phoneNumber?: string | undefined;
  email?: string | undefined;
  emailVerified?: boolean | undefined;
}

export class UserId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): UserId {
    return new UserId(id);
  }
}

export class User extends Entity<UserProps> {
  get userId(): UserId {
    return UserId.create(this.id);
  }

  get phoneNumber(): string | undefined {
    return this.props.phoneNumber;
  }

  get email(): string | undefined {
    return this.props.email;
  }

  get emailVerified(): boolean | undefined {
    return this.props.emailVerified;
  }

  private constructor(props: UserProps, id?: UniqueEntityID) {
    super(props, id);
  }

  public static create(props: UserProps, id?: UniqueEntityID): User {
    return new User(props, id);
  }
}
