import { Injectable } from '@angular/core';

import { NewsRepository } from '../repositories/news-repository';
import { News } from './news';

@Injectable()
export class NewsCommands {
  constructor(private newsRepository: NewsRepository) {
    // Nada
  }

  async findAllAfterDate(after: Date): Promise<News[]> {
    return this.newsRepository.findAllAfterDate(after);
  }

  async findLastTwoNews(): Promise<News[]> {
    return this.newsRepository.findLastTwoNews();
  }
}
