import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { DiaryType } from '../../tracker/domain/diary/diary_type';
import { TrackerType } from '../../tracker/domain/tracker/tracker_type';
import { CustomTracker } from '../domain/custom_tracker/custom_tracker';
import { CustomTrackerNotFoundException } from '../domain/custom_tracker/custom_tracker_exceptions';
import { DietitianId } from '../domain/dietitian';

export interface CustomTrackerSchema {
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  diaryType: DiaryType;
  type: TrackerType;
  max: number;
}

@Injectable()
export class CustomTrackerRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<CustomTrackerSchema>('custom_trackers', queryFn);
  }

  toSchema(customTracker: CustomTracker): CustomTrackerSchema {
    return {
      name: customTracker.name,
      createdAt:
        customTracker.createdAt !== undefined
          ? Timestamp.fromDate(customTracker.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      diaryType: customTracker.diaryType,
      type: customTracker.type,
      max: customTracker.max,
    };
  }

  fromSchema(
    schema: CustomTrackerSchema,
    dietitianId: string,
    id: string,
  ): CustomTracker {
    return CustomTracker.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        name: schema.name,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        diaryType: schema.diaryType,
        type: schema.type,
        max: schema.max ?? 0,
      },
      new UniqueEntityID(id),
    );
  }

  async load(
    dietitianId: string,
    customTrackerId: string,
  ): Promise<CustomTracker> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(customTrackerId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new CustomTrackerNotFoundException();
    }

    return this.fromSchema(
      snap.data() as CustomTrackerSchema,
      dietitianId,
      snap.id,
    );
  }

  async findByDietId(
    dietitianId: string,
    asc = false,
  ): Promise<CustomTracker[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.orderBy('createdAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async create(customTracker: CustomTracker): Promise<CustomTracker> {
    const schema = this.toSchema(customTracker);
    const ref = await this.collection(
      customTracker.dietitianId.id.toString(),
    ).add(schema);

    return this.fromSchema(
      schema,
      customTracker.dietitianId.id.toString(),
      ref.id,
    );
  }

  save(customTracker: CustomTracker): Promise<CustomTracker> {
    const schema = this.toSchema(customTracker);
    return this.collection(customTracker.dietitianId.id.toString())
      .doc(customTracker.customTrackerId.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(
          schema,
          customTracker.dietitianId.id.toString(),
          customTracker.customTrackerId.id.toString(),
        ),
      );
  }

  delete(dietitianId: string, customTrackerId: string): Promise<void> {
    return this.collection(dietitianId).doc(customTrackerId).delete();
  }
}
