import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { PatientGroup } from 'src/app/data/dietitian/domain/patient_group/patient_group';
import { Email } from 'src/app/data/email/domain/email/email';
import { EmailCommands } from 'src/app/data/email/domain/email/email_commands';
import { Template } from 'src/app/data/email/domain/email/template';
import { Patient } from 'src/app/data/patient/domain/patient';

import { SharedDocumentRepository } from '../../repositories/shared_document_repository';
import { SharedDocumentEventProvider } from '../events/shared_document/shared_document_event_provider';
import {
  SharedDocumentBatchCreated,
  SharedDocumentCreated,
  SharedDocumentDeleted,
  SharedDocumentUpdated,
} from '../events/shared_document/shared_document_events';
import { SharedDocument, SharedDocumentProps } from './shared_document';
import { SharedDocumentCategory } from './shared_document_category';
import { SharedDocumentUpload } from './shared_document_upload';

@Injectable()
export class SharedDocumentCommands {
  constructor(
    private repository: SharedDocumentRepository,
    private eventProvider: SharedDocumentEventProvider,
    private emailCommands: EmailCommands,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof SharedDocumentCreated) {
        this.toastr.success('Document partagé créé');
      } else if (event instanceof SharedDocumentUpdated) {
        this.toastr.success('Document partagé mis à jour');
      } else if (event instanceof SharedDocumentDeleted) {
        this.toastr.success('Document partagé supprimé');
      } else if (event instanceof SharedDocumentBatchCreated) {
        this.toastr.success('Documents partagés créés');
      }
    });
  }

  async getSharedDocument(documentId: string): Promise<SharedDocument> {
    return this.repository.load(documentId);
  }

  async getDietitianSharedDocuments(
    dietitianId: string,
    asc = false,
  ): Promise<SharedDocument[]> {
    return this.repository.findByDietitianId(dietitianId, asc);
  }

  async getPatientSharedDocuments(
    dietitianId: string,
    patientId: string,
    category: SharedDocumentCategory | null,
    asc = false,
  ): Promise<SharedDocument[]> {
    return this.repository.findByPatientId(
      dietitianId,
      patientId,
      category,
      asc,
    );
  }

  uploadSharedDocument(
    sharedDocumentProps: SharedDocumentProps,
    documentId?: string,
  ): SharedDocumentUpload {
    const document = SharedDocument.create(
      sharedDocumentProps,
      new UniqueEntityID(documentId),
    );
    return this.repository.upload(document);
  }

  cancelSharedDocumentUpload(upload: SharedDocumentUpload): Promise<void> {
    return this.repository.cancelUpload(upload);
  }

  // Commenté le 6 mars 2024 par Michael.
  // async saveSharedDocument(
  //   patient: Patient,
  //   sharedDocumentProps: SharedDocumentProps,
  //   documentId?: string,
  // ): Promise<SharedDocument> {
  //   let document = SharedDocument.create(
  //     sharedDocumentProps,
  //     new UniqueEntityID(documentId),
  //   );
  //   if (documentId !== undefined) {
  //     document = await this.repository.save(document);
  //     this.eventProvider.dispatch(new SharedDocumentUpdated(document));
  //   } else {
  //     document = await this.repository.create(document);
  //     this.eventProvider.dispatch(new SharedDocumentCreated(document));
  //
  //     if (!patient.isActive && patient.email) {
  //       await this.sendEmailNotificationToPatient(patient.email, [document]);
  //     }
  //   }
  //
  //   return document;
  // }

  async createSharedDocuments(
    patient: Patient,
    sharedDocumentProps: SharedDocumentProps[],
  ): Promise<SharedDocument[]> {
    if (sharedDocumentProps.length == 0) return [];

    const operations: Promise<SharedDocument>[] = [];
    for (const props of sharedDocumentProps) {
      operations.push(this.repository.create(SharedDocument.create(props)));
    }

    const documents = await Promise.all(operations);
    this.eventProvider.dispatch(new SharedDocumentBatchCreated(documents));

    if (!patient.isActive && patient.email) {
      await this.sendEmailNotificationToPatient(patient.email, documents);
    }

    return documents;
  }

  async createSharedDocumentsForPatients(
    target: PatientGroup | Patient[] | null,
    sharedDocumentProps: SharedDocumentProps[],
  ): Promise<SharedDocument[]> {
    if (sharedDocumentProps.length == 0) return [];

    const operations: Promise<SharedDocument>[] = [];
    for (const props of sharedDocumentProps) {
      operations.push(this.repository.create(SharedDocument.create(props)));
    }

    let documents = await Promise.all(operations);

    documents = await this.repository.copySharedDocumentsForPatients(
      target
        ? target instanceof PatientGroup
          ? target.patientGroupId.id.toString()
          : target.map((t) => t.id.toString())
        : null,
      documents,
    );

    this.eventProvider.dispatch(new SharedDocumentBatchCreated(documents));

    return documents;
  }

  async shareDocument(
    patient: Patient,
    documentId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const sharedDocument = await this.repository.shareDocument(
      documentId,
      category,
    );
    this.eventProvider.dispatch(new SharedDocumentCreated(sharedDocument));

    if (!patient.isActive && patient.email) {
      await this.sendEmailNotificationToPatient(patient.email, [
        sharedDocument,
      ]);
    }

    return sharedDocument;
  }

  async shareNote(
    patient: Patient,
    noteId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const sharedDocument = await this.repository.shareNote(noteId, category);
    this.eventProvider.dispatch(new SharedDocumentCreated(sharedDocument));

    if (!patient.isActive && patient.email) {
      await this.sendEmailNotificationToPatient(patient.email, [
        sharedDocument,
      ]);
    }
    return sharedDocument;
  }

  async shareSurvey(
    patient: Patient,
    surveyId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const sharedDocument = await this.repository.shareSurvey(
      surveyId,
      category,
    );
    this.eventProvider.dispatch(new SharedDocumentCreated(sharedDocument));

    if (!patient.isActive && patient.email) {
      await this.sendEmailNotificationToPatient(patient.email, [
        sharedDocument,
      ]);
    }

    return sharedDocument;
  }

  async shareComposition(
    patient: Patient,
    compositionId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const sharedComposition = await this.repository.shareComposition(
      compositionId,
      category,
    );
    this.eventProvider.dispatch(new SharedDocumentCreated(sharedComposition));

    if (!patient.isActive && patient.email) {
      await this.sendEmailNotificationToPatient(patient.email, [
        sharedComposition,
      ]);
    }

    return sharedComposition;
  }

  async shareCustomNutrition(
    patient: Patient,
    customNutritionId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const sharedComposition = await this.repository.shareCustomNutrition(
      patient.id.toString(),
      customNutritionId,
      category,
    );
    this.eventProvider.dispatch(new SharedDocumentCreated(sharedComposition));

    if (!patient.isActive && patient.email) {
      await this.sendEmailNotificationToPatient(patient.email, [
        sharedComposition,
      ]);
    }

    return sharedComposition;
  }

  async deleteSharedDocument(
    documentId: string,
    userId: string,
  ): Promise<SharedDocument> {
    const document = await this.repository.load(documentId);
    await this.repository.delete(documentId, userId);
    this.eventProvider.dispatch(new SharedDocumentDeleted(document));

    return document;
  }

  private sendEmailNotificationToPatient(
    email: string,
    documents: SharedDocument[],
  ): Promise<Email> {
    return this.emailCommands.sendEmail({
      to: [email],
      template: Template.create({
        name: 'shared_document_notification',
        data: {
          files: documents.map((doc) => {
            return { name: doc.name, url: doc.url };
          }),
        },
      }),
    });
  }
}
