import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { firstValueFrom } from 'rxjs';
import { GenericException } from 'src/app/core/logic/exception';

import { Picture } from '../domain/picture/picture';

@Injectable()
export class PictureRepository {
  constructor(private storage: AngularFireStorage) {
    // do nothing
  }

  async save(picture: Picture): Promise<Picture> {
    if (picture.path == null || picture.file == null) {
      throw new GenericException();
    }
    try {
      const fileRef = this.storage.ref(picture.path);
      await this.storage.upload(picture.path, picture.file, {
        contentType: picture.file?.type,
      });

      return Picture.create({
        url: await firstValueFrom(fileRef.getDownloadURL()),
        path: picture.path,
      });
    } catch (error: unknown) {
      throw new GenericException(error);
    }
  }

  async delete(picture: Picture): Promise<void> {
    if (picture.path == null) {
      throw new GenericException();
    }
    try {
      const fileRef = this.storage.ref(picture.path);
      return firstValueFrom(fileRef.delete());
    } catch (error: unknown) {
      throw new GenericException(error);
    }
  }
}
