import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { SharedDocument } from '../../../document/domain/shared_document/shared_document';
import { Conversation } from '../../../message/domain/conversation/conversation';
import { Message } from '../../../message/domain/message/message';
import { DietitianId } from '../dietitian';
import { Template } from '../templates/template';

export enum ScheduledTaskStatus {
  TO_PROCESS = 'TO_PROCESS',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  DELETED = 'DELETED',
}

export enum ScheduledTaskType {
  DOCUMENT = 'DOCUMENT',
  MESSAGE = 'MESSAGE',
  SURVEY = 'SURVEY',
}

export enum ScheduledTaskTargetType {
  GROUP = 'GROUP',
  PATIENT = 'PATIENT',
}

export interface ScheduledTaskTargetRecipientProps {
  patientId: string;
  patientUserId?: string | undefined;
}

export interface ScheduledTaskTargetProps {
  type: ScheduledTaskTargetType;
  groupId?: string | undefined;
  recipients: ScheduledTaskTargetRecipientProps[];
}

export interface ScheduledTaskProps {
  dietitianId: DietitianId;
  target: ScheduledTaskTargetProps | undefined;
  recipientName: string | undefined;
  type: ScheduledTaskType;
  contentId: UniqueEntityID | undefined;
  content: Conversation | SharedDocument | Template | undefined;
  subContent?: Message | string | undefined;
  scheduledAt: Date;
  status: ScheduledTaskStatus | undefined;
  error?: string | undefined;
  createdAt?: Date | undefined;
  updatedAt?: Date | undefined;
}

export class ScheduledTaskId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): ScheduledTaskId {
    return new ScheduledTaskId(id);
  }
}

export class ScheduledTask extends Entity<ScheduledTaskProps> {
  get scheduledTaskId(): ScheduledTaskId {
    return ScheduledTaskId.create(this.id);
  }

  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get target(): ScheduledTaskTargetProps | undefined {
    return this.props.target;
  }

  get recipientName(): string | undefined {
    return this.props.recipientName;
  }

  get type(): ScheduledTaskType {
    return this.props.type;
  }

  get contentId(): UniqueEntityID | undefined {
    return this.props.contentId;
  }

  get content(): Conversation | SharedDocument | Template | undefined {
    return this.props.content;
  }

  get subContent(): Message | string | undefined {
    return this.props.subContent;
  }

  get scheduledAt(): Date {
    return this.props.scheduledAt;
  }

  get status(): ScheduledTaskStatus | undefined {
    return this.props.status;
  }

  get error(): string | undefined {
    return this.props.error;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  private constructor(props: ScheduledTaskProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: ScheduledTaskProps): ScheduledTask {
    return ScheduledTask.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: ScheduledTaskProps,
    id?: UniqueEntityID,
  ): ScheduledTask {
    return new ScheduledTask(props, id);
  }
}
