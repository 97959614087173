import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { UserId } from '../../auth/domain/user';
import { PatientId } from '../domain/patient';
import { PatientSerie } from '../domain/patient_serie/patient_serie';
import { PatientSerieItemNotFoundException } from '../domain/patient_serie/patient_serie_exceptions';
import { PatientSerieItem } from '../domain/patient_serie/patient_serie_item';

export interface PatientSerieItemSchema {
  patientUserId: string | null;
  serie: PatientSerie;
  value: number;
  timestamp: Timestamp;
}

@Injectable()
export class PatientSerieRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collection(patientId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('patients')
      .doc(patientId)
      .collection<PatientSerieItemSchema>('series', queryFn);
  }

  private toSchema(patientSerieItem: PatientSerieItem): PatientSerieItemSchema {
    return <PatientSerieItemSchema>{
      patientUserId: patientSerieItem.patientUserId?.id.toString() ?? null,
      serie: patientSerieItem.serie,
      value: patientSerieItem.value,
      timestamp:
        patientSerieItem.timestamp !== undefined
          ? Timestamp.fromDate(patientSerieItem.timestamp)
          : Timestamp.now(),
    };
  }

  private fromSchema(
    schema: PatientSerieItemSchema,
    patientId: string,
    id: string,
  ): PatientSerieItem {
    return PatientSerieItem.create(
      {
        patientId: PatientId.create(new UniqueEntityID(patientId)),
        patientUserId: schema.patientUserId
          ? UserId.create(new UniqueEntityID(schema.patientUserId))
          : undefined,
        serie: schema.serie,
        value: schema.value,
        timestamp: schema.timestamp.toDate(),
      },
      new UniqueEntityID(id),
    );
  }

  async loadItem(patientId: string, itemId: string): Promise<PatientSerieItem> {
    const data = await firstValueFrom(
      this.collection(patientId).doc(itemId).get(),
    ).then((snap) => snap.data());
    if (!data) throw new PatientSerieItemNotFoundException();

    return this.fromSchema(data, patientId, itemId);
  }

  async findItems(
    patientId: string,
    start: Date,
    end: Date,
    serie?: PatientSerie,
  ): Promise<PatientSerieItem[]> {
    const snap = await firstValueFrom(
      this.collection(patientId, (ref) =>
        (serie ? ref.where('serie', '==', serie) : ref)
          .where('timestamp', '>=', start)
          .where('timestamp', '<=', end)
          .orderBy('timestamp'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), patientId, doc.id),
    );
  }

  async findAllItems(
    patientId: string,
    serie: PatientSerie,
  ): Promise<PatientSerieItem[] | undefined> {
    const snap = await firstValueFrom(
      this.collection(patientId, (ref) =>
        ref.where('serie', '==', serie).orderBy('timestamp', 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), patientId, doc.id),
    );
  }

  async findLastItem(
    patientId: string,
    serie?: PatientSerie,
  ): Promise<PatientSerieItem | undefined> {
    const snap = await firstValueFrom(
      this.collection(patientId, (ref) =>
        (serie ? ref.where('serie', '==', serie) : ref)
          .orderBy('timestamp', 'desc')
          .limit(1),
      ).get(),
    );

    if (!snap.docs || snap.docs.length <= 0) {
      return;
    }

    return this.fromSchema(snap.docs[0].data(), patientId, snap.docs[0].id);
  }

  async createItem(
    patientSerieItem: PatientSerieItem,
  ): Promise<PatientSerieItem> {
    const schema = this.toSchema(patientSerieItem);
    const ref = await this.collection(
      patientSerieItem.patientId.id.toString(),
    ).add(schema);
    return this.fromSchema(
      schema,
      patientSerieItem.patientId.id.toString(),
      ref.id,
    );
  }

  saveItem(patientSerieItem: PatientSerieItem): Promise<PatientSerieItem> {
    const schema = this.toSchema(patientSerieItem);
    return this.collection(patientSerieItem.patientId.id.toString())
      .doc(patientSerieItem.patientSerieItemId.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(
          schema,
          patientSerieItem.patientId.id.toString(),
          patientSerieItem.id.toString(),
        ),
      );
  }

  deleteItem(patientId: string, patientSerieItemId: string): Promise<void> {
    return this.collection(patientId).doc(patientSerieItemId).delete();
  }
}
