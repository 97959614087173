import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

export enum NotificationType {
  PatientCreated = 'patient_created',
  PatientMsdpCreated = 'patient_msdp_created',
  AppointmentMsdpCreated = 'appointment_msdp_created',
  PatientBirthday = 'patient_birthday',
  SurveyUpdated = 'survey_updated',
}

export interface NotificationProps {
  createdAt: Date;
  type: NotificationType;
  readAt?: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export class NotificationId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): NotificationId {
    return new NotificationId(id);
  }
}

export class Notification extends Entity<NotificationProps> {
  get notificationId(): NotificationId {
    return NotificationId.create(this.id);
  }

  get type(): NotificationType {
    return this.props.type;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get payload(): any | undefined {
    return this.props.payload;
  }

  get readAt(): Date | undefined {
    return this.props.readAt;
  }

  get createdAt(): Date {
    return this.props.createdAt;
  }

  get title(): string {
    switch (this.type) {
      case NotificationType.PatientCreated:
      case NotificationType.PatientMsdpCreated:
        return 'Nouveau patient';
      case NotificationType.AppointmentMsdpCreated:
        return 'Rendez-vous Nouveau Patient';
      case NotificationType.PatientBirthday:
        return 'Anniversaire';
      case NotificationType.SurveyUpdated:
        return 'Réponses questionnaire';
      default:
        return '--';
    }
  }

  get message(): string {
    switch (this.type) {
      case NotificationType.PatientCreated:
        return `Un nouveau patient ${
          this.props.payload?.patientFullName
            ? '"' + this.props.payload?.patientFullName + '" '
            : ''
        }vient d'être rattaché à votre compte.`;
      case NotificationType.PatientMsdpCreated:
        return `Un nouveau patient ${
          this.props.payload?.patientFullName
            ? '"' + this.props.payload?.patientFullName + '" '
            : ''
        }vient d'être créé depuis votre compte MonSuiviDiet Plus.`;
      case NotificationType.AppointmentMsdpCreated: {
        const appointmentDate: Date = new Date(this.props.payload?.date);
        const date =
          (appointmentDate.getDate() < 10
            ? '0' + appointmentDate.getDate()
            : appointmentDate.getDate()) +
          '/' +
          (appointmentDate.getMonth() + 1 < 10
            ? '0' + (appointmentDate.getMonth() + 1)
            : appointmentDate.getMonth() + 1) +
          '/' +
          appointmentDate.getFullYear();
        return `Un nouveau rendez-vous le ${date} ${
          this.props.payload?.patientFullName
            ? 'avec "' + this.props.payload?.patientFullName + '" '
            : ''
        }vient d'être ajouté depuis MonSuiviDiet Plus.`;
      }
      case NotificationType.PatientBirthday:
        return `Le patient ${
          this.props.payload?.patientFullName
            ? '"' + this.props.payload?.patientFullName + '" '
            : ''
        }fête en ce jour, son anniversaire.`;
      case NotificationType.SurveyUpdated:
        return `Le patient ${
          this.props.payload?.patientFullName
            ? '"' + this.props.payload?.patientFullName + '" '
            : ''
        }a répondu au questionnaire ${this.props.payload?.surveyName}.`;
      default:
        return '--';
    }
  }

  get isActionAvailable(): boolean {
    switch (this.type) {
      case NotificationType.PatientCreated:
        return !!this.payload['userId'];
      default:
        return false;
    }
  }

  private constructor(props: NotificationProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: NotificationProps): Notification {
    return Notification.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: NotificationProps,
    id?: UniqueEntityID,
  ): Notification {
    return new Notification(props, id);
  }
}
