import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { Patient } from '../patient';

export class PatientEvent extends DomainEvent<Patient> {}

export class PatientCreated extends PatientEvent {}

export class PatientUpdated extends PatientEvent {}

export class PatientActivated extends PatientEvent {}

export class PatientArchived extends PatientEvent {}

export class PatientSignedIn extends PatientEvent {}

export class PatientSignedOut extends PatientEvent {}
