import { Observable, Subject } from 'rxjs';

import Entity from '../entity';
import { DomainEvent } from './domain_event';

export default abstract class DomainEventProvider<
  T extends DomainEvent<Entity<unknown>>,
> {
  eventsSubject = new Subject<T>();

  get events$(): Observable<T> {
    return this.eventsSubject.asObservable();
  }

  dispatch(event: T) {
    this.eventsSubject.next(event);
  }
}
