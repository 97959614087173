import { Injectable } from '@angular/core';

import { EncouragementRepository } from '../../repositories/encouragement_repository';
import { Encouragement } from './encouragement';

@Injectable()
export class EncouragementCommands {
  constructor(private repository: EncouragementRepository) {
    // do nothing
  }

  async getPatientEncouragement(
    patientId: string,
    encouragementId: string,
  ): Promise<Encouragement> {
    return this.repository.load(patientId, encouragementId);
  }

  async getPatientEncouragements(
    patientId: string,
    start: Date,
    end: Date,
  ): Promise<Encouragement[]> {
    return this.repository.findEncouragements(patientId, start, end);
  }
}
