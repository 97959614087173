import { Exception } from '../../../../core/logic/exception';

export class ScheduledTaskNotFoundException extends Exception<never> {
  constructor() {
    super('La tâche programmée est introuvable');
  }
}

export class ScheduledTaskAlreadyProcessingException extends Exception<never> {
  constructor() {
    super('La tâche programmée est déjà traitée, impossible de la supprimer');
  }
}

export class InvalidScheduledTaskDateException extends Exception<never> {
  constructor() {
    super('La date de programmation est invalide');
  }
}

export class ScheduledTaskBatchSizeException extends Exception<never> {
  constructor() {
    super(
      'Une tâche programmée ne peut pas adresser plus de 500 destinataires',
    );
  }
}
