import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { FieldType } from '../../../../ui/template/routes/editor/components/fields-library/field_type';
import { PatientSerie } from '../../../patient/domain/patient_serie/patient_serie';
import { TemplateField, TemplateFieldId } from '../templates/template_field';
import { SurveyId } from './survey';

export interface SurveyFieldProps {
  surveyId: SurveyId;
  templateFieldId: TemplateFieldId;
  label: string;
  updatedAt?: Date;
  type: FieldType;
  params: unknown;
  value: unknown;
  serieItemId?: string;
  order: number;
}

export class SurveyFieldId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): SurveyFieldId {
    return new SurveyFieldId(id);
  }
}

export class SurveyField extends Entity<SurveyFieldProps> {
  get surveyFieldId(): SurveyFieldId {
    return SurveyFieldId.create(this.id);
  }

  get templateFieldId(): TemplateFieldId {
    return this.props.templateFieldId;
  }

  get label(): string {
    return this.props.label;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get type(): FieldType {
    return this.props.type;
  }

  get params(): unknown {
    return this.props.params;
  }

  get value(): unknown {
    return this.props.value;
  }

  get serieItemId(): string | undefined {
    return this.props.serieItemId;
  }

  get order(): number {
    return this.props.order;
  }

  get matchingPatientSerie(): PatientSerie | undefined {
    return SurveyField.patientSerieMatches[this.props.type];
  }

  private constructor(props: SurveyFieldProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: SurveyFieldProps): SurveyField {
    return SurveyField.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  patch(templateField: TemplateField): SurveyField {
    return this.copyWith({
      templateFieldId: templateField.templateFieldId,
      label: templateField.label,
      type: templateField.type,
      params: templateField.params,
      order: templateField.order,
      value: this.convertValue(this.value),
    } as SurveyFieldProps);
  }

  private convertValue(value: unknown): unknown {
    switch (this.type) {
      case FieldType.Information:
        return null;
      case FieldType.MultiLineText:
      case FieldType.Text:
        return value as string;
      case FieldType.Checkbox:
        return typeof value === 'boolean'
          ? value
          : typeof value === 'string'
          ? value == 'true'
          : false;
      case FieldType.Date:
      case FieldType.DateOfBirth:
        return value instanceof Date
          ? value
          : typeof value === 'string'
          ? new Date(value as string)
          : undefined;
      case FieldType.Today:
        return value instanceof Date
          ? value
          : typeof value === 'string'
          ? new Date(value as string)
          : new Date();
      case FieldType.Number:
      case FieldType.ArmSize:
      case FieldType.FatMass:
      case FieldType.FormWeight:
      case FieldType.HipSize:
      case FieldType.MuscularMass:
      case FieldType.Size:
      case FieldType.ThighSize:
      case FieldType.WaistSize:
      case FieldType.Weight:
        return Number(value);
      case FieldType.Range:
      case FieldType.Assessment:
      case FieldType.InvAssessment:
        return Number(value ?? 0);
      case FieldType.NzAssessment:
        return Number(value ?? 1);
      default:
        return value;
    }
  }

  updateValue(value: unknown): SurveyField {
    return this.copyWith({
      value: this.convertValue(value),
    } as SurveyFieldProps);
  }

  updateSerieItemId(serieItemId: string): SurveyField {
    return this.copyWith({
      serieItemId,
    } as SurveyFieldProps);
  }

  public static create(
    props: SurveyFieldProps,
    id?: UniqueEntityID,
  ): SurveyField {
    return new SurveyField(props, id);
  }

  private static patientSerieMatches: { [key: string]: PatientSerie } = {
    [FieldType.ArmSize]: PatientSerie.ArmSize,
    [FieldType.FatMass]: PatientSerie.FatMass,
    [FieldType.FormWeight]: PatientSerie.FormWeight,
    [FieldType.HipSize]: PatientSerie.HipSize,
    [FieldType.MuscularMass]: PatientSerie.MuscularMass,
    [FieldType.Size]: PatientSerie.Size,
    [FieldType.ThighSize]: PatientSerie.ThighSize,
    [FieldType.WaistSize]: PatientSerie.WaistSize,
    [FieldType.Weight]: PatientSerie.Weight,
  };
}
