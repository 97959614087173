import { Exception } from '../../../../core/logic/exception';

export class NutritionNotFoundException extends Exception<never> {
  constructor() {
    super('Modèle de plan alimentaire non trouvé');
  }
}

export class NutritionExistingNameException extends Exception<string> {
  constructor(name: string) {
    super(
      `Un modèle de plan alimentaire avec le nom "${name}" existe déjà`,
      name,
    );
  }
}

export class NutritionQuantityUnitNotFoundException extends Exception<string> {
  constructor() {
    super('Quantité non trouvée');
  }
}

export class NutritionIngredientNotFoundException extends Exception<string> {
  constructor() {
    super('Aliment non trouvée');
  }
}
