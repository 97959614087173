import { Injectable } from '@angular/core';

import { MessageTemplateRepository } from '../../repositories/message-template-repository';
import { Dietitian, Gender } from '../dietitian';
import { MessageTemplate, MessageTemplateType } from './message-template';

@Injectable()
export class MessageTemplateCommands {
  constructor(private messageTemplateRepository: MessageTemplateRepository) {
    // Nada
  }

  async getAllTemplate(
    dietitianId: string,
    type: MessageTemplateType,
  ): Promise<MessageTemplate[]> {
    return this.messageTemplateRepository.loadAll(dietitianId, type);
  }

  async saveTemplate(
    dietitianId: string,
    type: MessageTemplateType,
    template: MessageTemplate,
  ): Promise<MessageTemplate> {
    return this.messageTemplateRepository.save(dietitianId, type, template);
  }

  getSign(dietitian: Dietitian): string {
    return (
      dietitian.firstName +
      ' ' +
      dietitian.lastName +
      '\n' +
      (dietitian.gender === Gender.NB
        ? 'Diététicien.ne/Nutritionniste'
        : dietitian.gender === Gender.WOMAN
        ? 'Diététicienne/Nutritionniste'
        : 'Diététicien/Nutritionniste')
    );
  }
}
