<div class="modal-body">
  <div id="modal-header-wrapper">
    <div class="d-inline-flex align-items-center">
      <img src="/assets/images/simple-logo-msd.png" class="logo" alt="logo">
      <span class="title">Nouveautés</span>
    </div>
  </div>
  <div *ngFor="let n of news" class="content">
    <p class="subtitle">{{n.subtitle}}</p>
    <div [innerHTML]="n.content"></div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex w-100 justify-content-between">
    <div class="form-check">
      <input class="form-check-input"
             [(ngModel)]="checkbox"
             id="redisplay-checkbox"
             type="checkbox">
      <label class="form-check-label call-to-action-md" for="redisplay-checkbox">
        Revoir ces nouveautés à la prochaine connexion
      </label>
    </div>
    <button id="close-button" (click)="hideModal();" type="button" class="btn btn-primary btn-gradient">
      Fermer
    </button>
  </div>
</div>

