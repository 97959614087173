import deepEqual from 'deep-equal';

import UniqueEntityID from './unique_entity_id';

export default abstract class Entity<T> {
  protected readonly $id: UniqueEntityID;
  public readonly props: T;

  constructor(props: T, id?: UniqueEntityID) {
    this.$id = id ?? new UniqueEntityID();
    this.props = props;
  }

  get id(): UniqueEntityID {
    return this.$id;
  }

  public equals(object?: Entity<T>): boolean {
    if (object === null || object === undefined) {
      return false;
    }

    if (this === object) {
      return true;
    }

    if (!Entity.isEntity(object)) {
      return false;
    }

    return this.$id.equals(object.$id);
  }

  public deepEquals(object?: Entity<T>): boolean {
    if (!this.equals(object)) {
      return false;
    }

    return deepEqual(this.props, object?.props);
  }

  private static isEntity(v: unknown): v is Entity<unknown> {
    return v instanceof Entity;
  }
}
