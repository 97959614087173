import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { SharedDocument } from '../../shared_document/shared_document';

export class SharedDocumentEvent extends DomainEvent<SharedDocument> {}

export class SharedDocumentCreated extends SharedDocumentEvent {}

export class SharedDocumentUpdated extends SharedDocumentEvent {}

export class SharedDocumentDeleted extends SharedDocumentEvent {}

export class SharedDocumentBatchCreated extends SharedDocumentEvent {
  documents: SharedDocument[];

  constructor(documents: SharedDocument[], notify?: boolean) {
    if (documents.length == 0) throw new Error('Empty document list');

    super(documents[0], notify);

    this.documents = documents;
  }
}
