import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { SynthesisType } from '../../../../ui/template/routes/editor/components/editor-synthesis-element/synthesis-model';
import { TemplateId } from './template';
import { TemplateSynthesisDataset } from './template-synthesis-dataset';

export class TemplateSynthesisProps {
  templateId?: TemplateId;
  name: string;
  commentDietitian?: string | undefined;
  commentPatient?: string | undefined;
  source?: string | undefined;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  type: SynthesisType;
  dataset?: TemplateSynthesisDataset[];
}

export class TemplateSynthesisId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): TemplateSynthesisId {
    return new TemplateSynthesisId(id);
  }
}

export class TemplateSynthesis extends Entity<TemplateSynthesisProps> {
  get templateSynthesisId() {
    return TemplateSynthesisId.create(this.id);
  }

  get templateId(): TemplateId | undefined {
    return this.props.templateId;
  }

  get name(): string {
    return this.props.name;
  }

  get commentDietitian(): string | undefined {
    return this.props.commentDietitian;
  }

  get commentPatient(): string | undefined {
    return this.props.commentPatient;
  }

  get source(): string | undefined {
    return this.props.source;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get deletedAt(): Date | undefined {
    return this.props.deletedAt;
  }

  get type(): SynthesisType {
    return this.props.type;
  }

  get templateSynthesisDataset(): TemplateSynthesisDataset[] | undefined {
    return this.props.dataset;
  }

  private constructor(props: TemplateSynthesisProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: TemplateSynthesisProps): TemplateSynthesis {
    return TemplateSynthesis.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: TemplateSynthesisProps,
    id?: UniqueEntityID,
  ): TemplateSynthesis {
    return new TemplateSynthesis(props, id);
  }
}
