import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { Message } from '../../message/message';

export class MessageEvent extends DomainEvent<Message> {}

export class MessageSent extends MessageEvent {}

export class MessageUpdated extends MessageEvent {}

export class MessageReceived extends MessageEvent {}
