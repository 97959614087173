import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { NutritionFoodstuff } from './nutrition-foodstuff';

export interface NutritionMealProps {
  name: string;
  foodstuffs: NutritionFoodstuff[];
  sorter: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export class NutritionMeal extends Entity<NutritionMealProps> {
  private constructor(props: NutritionMealProps, id?: UniqueEntityID) {
    super(props, id);
  }

  get name(): string {
    return this.props.name;
  }

  set name(v: string) {
    this.props.name = v;
  }

  get foodstuffs(): NutritionFoodstuff[] {
    return this.props.foodstuffs;
  }

  set foodstuffs(v: NutritionFoodstuff[]) {
    this.props.foodstuffs = v;
  }

  get sorter(): number {
    return this.props.sorter;
  }

  set sorter(v: number) {
    this.props.sorter = v;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  public static create(
    props: NutritionMealProps,
    id?: UniqueEntityID,
  ): NutritionMeal {
    return new NutritionMeal(props, id);
  }

  copyWith(props: NutritionMealProps): NutritionMeal {
    return NutritionMeal.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }
}
