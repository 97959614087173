import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://d526cff99a5667e931661644343f7d4e@o4505164848955392.ingest.sentry.io/4506178171699201',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/dashboard\.monsuividiet\.com/,
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  environment: environment.production ? 'production' : 'develop',
  // Performance Monitoring
  tracesSampleRate: environment.production ? 0.02 : 0.5,
  // Session Replay
  replaysSessionSampleRate: environment.production ? 0.01 : 0,
  replaysOnErrorSampleRate: environment.production ? 0.01 : 0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
