export class Exception<T> extends Error {
  public readonly data?: T;

  constructor(message: string, data?: T) {
    super();

    this.name = this.constructor.name;
    this.data = data;
    this.message = message;
  }
}

export class GenericException extends Exception<unknown> {
  constructor(error?: unknown) {
    if (error instanceof Error || typeof error === 'string') {
      console.error(error);
    }
    super('Une erreur est survenue, veuillez réessayer', error);
  }
}

export class UnAuthorizedException extends Exception<never> {
  constructor() {
    super("Vous n'êtes pas autorisé à effectuer cette action");
  }
}
