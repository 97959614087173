export enum SharedDocumentCategory {
  ConsultationReport = 'consultation_report',
  Document = 'document',
  Exercises = 'exercises',
  MealPlan = 'meal_plan',
  Questionnaire = 'questionnaire',
  Recipes = 'recipes',
  Tips = 'tips',
  Invoices = 'invoices',
}
