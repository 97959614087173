import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { BillLineDescription } from '../domain/bill/bill-line-description';
import { DietitianId } from '../domain/dietitian';

export interface BillLineDescriptionSchema {
  label: string;
  amount?: number | null;
  createdAt?: Timestamp | null;
  updatedAt?: Timestamp | null;
}

@Injectable()
export class BillLineDescriptionRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<BillLineDescriptionSchema>('bill_descriptions', queryFn);
  }

  public toSchema(
    billLineDescription: BillLineDescription,
  ): BillLineDescriptionSchema {
    return {
      label: billLineDescription.label,
      amount: billLineDescription.amount ?? null,
      createdAt: billLineDescription.createdAt
        ? Timestamp.fromDate(billLineDescription.createdAt)
        : Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
  }

  public fromSchema(
    schema: BillLineDescriptionSchema,
    dietitianId: string,
    id?: string,
  ): BillLineDescription {
    return BillLineDescription.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        label: schema.label,
        amount: schema.amount ?? undefined,
        createdAt: schema.createdAt?.toDate() ?? undefined,
        updatedAt: schema.updatedAt?.toDate() ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async create(
    billLineDescription: BillLineDescription,
  ): Promise<BillLineDescription> {
    const schema = this.toSchema(billLineDescription);
    if (billLineDescription.dietitianId) {
      const ref = await this.collection(
        billLineDescription.dietitianId.id.toString(),
      ).add(schema);
      return this.fromSchema(
        schema,
        billLineDescription.dietitianId.id.toString(),
        ref.id,
      );
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async save(
    billLineDescription: BillLineDescription,
  ): Promise<BillLineDescription> {
    const schema = this.toSchema(billLineDescription);
    if (billLineDescription.dietitianId) {
      const dietitianId = billLineDescription.dietitianId.id.toString();
      await this.collection(dietitianId)
        .doc(billLineDescription.id.toString())
        .set(schema);
      return this.fromSchema(
        schema,
        dietitianId,
        billLineDescription.id.toString(),
      );
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async findAll(dietitianId: string): Promise<BillLineDescription[]> {
    const snap = await firstValueFrom(this.collection(dietitianId).get());
    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }
}
