import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { DiaryRepository } from '../../repositories/diary_repository';
import { DiaryEventProvider } from '../events/diary/diary_event_provider';
import { DiaryCreated, DiaryUpdated } from '../events/diary/diary_events';
import { Diary, DiaryProps } from './diary';

@Injectable()
export class DiaryCommands {
  constructor(
    private repository: DiaryRepository,
    private eventProvider: DiaryEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (!event.notify) return;

      if (event instanceof DiaryCreated) {
        this.toastr.success('Journal créé');
      } else if (event instanceof DiaryUpdated) {
        this.toastr.success('Journal mis à jour');
      }
    });
  }

  async getPatientDiary(patientId: string, diaryId: string): Promise<Diary> {
    return this.repository.load(patientId, diaryId);
  }

  async getPatientDiaries(
    patientId: string,
    start: Date,
    end: Date,
  ): Promise<Diary[]> {
    return this.repository.findDiaries(patientId, start, end);
  }

  async saveDiary(diaryProps: DiaryProps, diaryId?: string): Promise<Diary> {
    let diary = Diary.create(diaryProps, new UniqueEntityID(diaryId));
    if (diaryId !== undefined) {
      diary = await this.repository.save(diary);
      this.eventProvider.dispatch(new DiaryUpdated(diary));
    } else {
      diary = await this.repository.create(diary);
      this.eventProvider.dispatch(new DiaryCreated(diary));
    }

    return diary;
  }
}
