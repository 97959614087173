import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export enum NutritionUnit {
  G = 'g',
  MG = 'mg',
  KG = 'kg',
  MiG = 'µg',
}

export interface NutritionQuantityUnitProps {
  id: string;
  dietitianId: string;
  name: string;
  value: number;
  unit: NutritionUnit;
  createdAt?: Date;
  updatedAt?: Date;
}

export class NutritionQuantityUnitId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): NutritionQuantityUnitId {
    return new NutritionQuantityUnitId(id);
  }
}

export class NutritionQuantityUnit extends Entity<NutritionQuantityUnitProps> {
  private constructor(props: NutritionQuantityUnitProps, id?: UniqueEntityID) {
    super(props, id);
  }

  get nutritionQuantityUnitId(): NutritionQuantityUnitId {
    return NutritionQuantityUnitId.create(this.id);
  }

  get dietitianId(): string | undefined {
    return this.props.dietitianId;
  }

  get name(): string {
    return this.props.name;
  }

  set name(value: string) {
    this.props.name = value;
  }

  get value(): number {
    return this.props.value;
  }

  get unit(): NutritionUnit {
    return this.props.unit;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get weight(): number {
    switch (this.unit) {
      case NutritionUnit.G:
        return this.value;
      case NutritionUnit.KG:
        return this.value * 1000;
      case NutritionUnit.MG:
        return this.value / 1000;
      case NutritionUnit.MiG:
        return this.value / 1000000;
    }
  }

  public static create(
    props: NutritionQuantityUnitProps,
    id?: UniqueEntityID,
  ): NutritionQuantityUnit {
    return new NutritionQuantityUnit(props, id);
  }

  copyWith(props: NutritionQuantityUnitProps): NutritionQuantityUnit {
    return NutritionQuantityUnit.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }
}
