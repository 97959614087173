import { Injectable } from '@angular/core';
import StateProvider, {
  State,
} from 'src/app/core/domain/events/state_provider';

import { Memo } from './memo';

export type MemoState = State<Memo>;

@Injectable({ providedIn: 'root' })
export class MemoStateProvider extends StateProvider<MemoState> {}
