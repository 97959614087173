import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { NotificationRepository } from '../../repositories/notification_repository';
import { NotificationEventProvider } from '../events/notification/notification_event_provider';
import {
  NotificationCreated,
  NotificationUpdated,
} from '../events/notification/notification_events';
import { Notification, NotificationProps } from './notification';

@Injectable()
export class NotificationCommands {
  constructor(
    private repository: NotificationRepository,
    private eventProvider: NotificationEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof NotificationCreated) {
        this.toastr.success('Notification créé');
      } else if (event instanceof NotificationUpdated) {
        this.toastr.success('Notification mise à jour');
      }
    });
  }

  async update(
    dietitianId: string,
    notificationId: string,
    props: NotificationProps,
    notify = true,
  ) {
    let notification = Notification.create(
      props,
      new UniqueEntityID(notificationId),
    );
    notification = await this.repository.save(dietitianId, notification);
    if (notify) {
      this.eventProvider.dispatch(new NotificationUpdated(notification));
    }
    return notification;
  }

  findDietitianNotifications(dietitianId: string): Promise<Notification[]> {
    return this.repository.findDietitianNotifications(dietitianId);
  }
}
