import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { EncouragementTemplate } from '../domain/encouragement_template/encouragement-template';

interface EncouragementTemplateSchema {
  title: string;
  content: string;
}

@Injectable()
export class EncouragementTemplateRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection(dietitianId: string) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<EncouragementTemplateSchema>('encouragement_templates');
  }

  toSchema(template: EncouragementTemplate): EncouragementTemplateSchema {
    return <EncouragementTemplateSchema>{
      content: template.content,
      title: template.title,
    };
  }

  fromSchema(
    schema: EncouragementTemplateSchema,
    id: string,
  ): EncouragementTemplate {
    return EncouragementTemplate.create(
      {
        content: schema.content,
        title: schema.title,
      },
      new UniqueEntityID(id),
    );
  }

  async loadAll(dietitianId: string): Promise<EncouragementTemplate[]> {
    const snap = await firstValueFrom(this.collection(dietitianId).get());
    return snap.docs
      .map((doc) => this.fromSchema(doc.data(), doc.id))
      .sort(this.sortEncouragementTemplate);
  }

  async save(
    dietitianId: string,
    template: EncouragementTemplate,
  ): Promise<EncouragementTemplate> {
    const schema = this.toSchema(template);
    const ref = await this.collection(dietitianId).add(schema);
    return this.fromSchema(schema, ref.id);
  }

  private sortEncouragementTemplate(
    a: EncouragementTemplate,
    b: EncouragementTemplate,
  ): number {
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    } else if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  }
}
