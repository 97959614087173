import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { NutritionGroupIngredient } from '../domain/nutritions/nutrition-group-ingredient';

export interface CiqualGroupIngredientSchema {
  label: string;
  level: number;
  parent: string;
}

@Injectable()
export class CiqualGroupIngredientRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection() {
    return this.firestore.collection<CiqualGroupIngredientSchema>(
      'ciqual_groups',
    );
  }

  toSchema(
    nutritionGroupIngredient: NutritionGroupIngredient,
  ): CiqualGroupIngredientSchema {
    return <CiqualGroupIngredientSchema>{
      label: nutritionGroupIngredient.label,
    };
  }

  fromSchema(
    schema: CiqualGroupIngredientSchema,
    id: string,
  ): NutritionGroupIngredient {
    return NutritionGroupIngredient.create(
      {
        label: schema.label,
        level: schema.level,
        parent: schema.parent,
      },
      new UniqueEntityID(id),
    );
  }

  async findAll(): Promise<NutritionGroupIngredient[]> {
    const snap = await firstValueFrom(this.collection().get());
    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }
}
