import ValueObject from 'src/app/core/domain/value_object';

interface EmailProps {
  value: string;
}
export class Email extends ValueObject<EmailProps> {
  get value(): string {
    return this.props.value;
  }

  private constructor(props: EmailProps) {
    super(props);
  }

  public static create(props: EmailProps): Email {
    return new Email(props);
  }
}

interface PasswordProps {
  value: string;
}
export class Password extends ValueObject<PasswordProps> {
  get value(): string {
    return this.props.value;
  }

  private constructor(props: PasswordProps) {
    super(props);
  }

  public static create(props: PasswordProps): Password {
    return new Password(props);
  }
}
