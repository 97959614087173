import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { News } from '../../../data/news/domain/news';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'msd-news-popup',
  templateUrl: './news-popup.component.html',
  styleUrls: ['./news-popup.component.scss'],
})
export class NewsPopupComponent {
  @Input() public news: News[] = [];
  @Output() result = new EventEmitter<boolean>();
  checkbox = false;

  constructor(private bsModalRef: BsModalRef) {
    // Nada
  }

  hideModal() {
    this.result.emit(this.checkbox);
    this.bsModalRef.hide();
  }
}
