import Entity from 'src/app/core/domain/entity';

import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { UserId } from '../../../auth/domain/user';
import { DietitianId } from '../../../dietitian/domain/dietitian';
import { PatientId } from '../../../patient/domain/patient';

export interface ProfileProps {
  profileId: DietitianId | PatientId;
  firstName: string;
  lastName: string;
  profilePicture?: string;
  lastMessageReadDate?: Date;
  userId?: UserId;
  hasNewMessage: boolean;
}

export class Profile extends Entity<ProfileProps> {
  get profileId(): DietitianId | PatientId {
    return this.props.profileId;
  }

  get firstName(): string {
    return this.props.firstName;
  }

  get lastName(): string {
    return this.props.lastName;
  }

  get userId(): UserId | undefined {
    return this.props.userId;
  }

  get profilePicture(): string | undefined {
    return this.props.profilePicture;
  }

  get hasNewMessage(): boolean {
    return this.props.hasNewMessage;
  }

  get lastMessageReadDate(): Date | undefined {
    return this.props.lastMessageReadDate;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get initials(): string {
    return `${this.firstName.length > 0 ? this.firstName.charAt(0) : ''}${
      this.lastName.length > 0 ? this.lastName.charAt(0) : ''
    }`;
  }

  private constructor(props: ProfileProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: ProfileProps): Profile {
    return Profile.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: ProfileProps, id?: UniqueEntityID): Profile {
    return new Profile(props, id);
  }
}
