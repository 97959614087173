import ValueObject from 'src/app/core/domain/value_object';

import { TrackerType } from '../tracker/tracker_type';

interface DiaryTrackerProps {
  name: string;
  type: TrackerType;
  max: number;
  value: number;
}

export class DiaryTracker extends ValueObject<DiaryTrackerProps> {
  get name(): string {
    return this.props.name;
  }

  get max(): number {
    return this.props.max;
  }

  get value(): number {
    return this.props.value;
  }

  get type(): TrackerType {
    return this.props.type;
  }

  private constructor(props: DiaryTrackerProps) {
    super(props);
  }

  public static create(props: DiaryTrackerProps): DiaryTracker {
    return new DiaryTracker(props);
  }
}
