import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { CiqualCompositionRepository } from '../../repositories/ciqual_composition.repository';
import { NutritionQuantityUnitRepository } from '../../repositories/nutrition-quantity-unit-repository';
import { NutritionRepository } from '../../repositories/nutrition-repository';
import { NutritionEventProvider } from '../events/nutrition/nutrition_event_provider';
import {
  NutritionArchived,
  NutritionCreated,
  NutritionRestored,
  NutritionSaved,
} from '../events/nutrition/nutrition_events';
import { Nutrition } from './nutrition';
import { NutritionComponent } from './nutrition-component';
import { NutritionExistingNameException } from './nutrition-exceptions';
import { NutritionQuantityUnit } from './nutrition-quantity-unit';

@Injectable()
export class NutritionCommands {
  constructor(
    private repository: NutritionRepository,
    private eventProvider: NutritionEventProvider,
    private toastr: ToastrService,
    private ciqualCompositionRepository: CiqualCompositionRepository,
    private nutritionQuantityUnitRepository: NutritionQuantityUnitRepository,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof NutritionCreated) {
        this.toastr.success('Modèle de plan alimentaire créé');
      } else if (event instanceof NutritionArchived) {
        this.toastr.success('Modèle de plan alimentaire désactivé');
      } else if (event instanceof NutritionRestored) {
        this.toastr.success('Modèle de plan alimentaire activé');
      } else if (event instanceof NutritionSaved) {
        this.toastr.success('Modèle de plan alimentaire enregistré');
      }
    });
  }

  get components(): NutritionComponent[] {
    return this.ciqualCompositionRepository.components;
  }

  async getNutrition(
    dietitianId: string,
    nutritionId: string,
  ): Promise<Nutrition> {
    return this.repository.load(dietitianId, nutritionId);
  }

  async getDietitianActiveNutritionsFilteredAndSorted(
    dietitianId: string,
    orderBy: string,
    direction: string,
  ): Promise<Nutrition[]> {
    return this.repository.findActiveNutritionsByDietitianIdFilteredAndSorted(
      dietitianId,
      orderBy,
      direction,
    );
  }

  async getDietitianActiveNutritions(
    dietitianId: string,
  ): Promise<Nutrition[]> {
    return this.repository.findActiveNutritionsByDietitianId(dietitianId);
  }

  async getDietitianArchivedNutritionsFilteredAndSorted(
    dietitianId: string,
    orderBy: string,
    direction: string,
  ): Promise<Nutrition[]> {
    return this.repository.findArchivedNutritionsByDietitianIdFilteredAndSorted(
      dietitianId,
      orderBy,
      direction,
    );
  }

  async saveNutrition(
    nutrition: Nutrition,
    withEventDispatch = true,
  ): Promise<Nutrition> {
    if (nutrition.dietitianId) {
      const pattern = /[(][0-9]+[)]$/g;
      while (
        await this.repository.existsForDietitianIdAndName(
          nutrition.dietitianId.id.toString(),
          nutrition.name,
          nutrition.id.toString(),
        )
      ) {
        const matches = nutrition.name.match(pattern);
        if (matches && matches.length === 1) {
          const position = nutrition.name.indexOf(matches[0]);
          const current = Number(
            matches[0].substring(1, matches[0].length - 1),
          );
          nutrition.name =
            nutrition.name.substring(0, position) + '(' + (current + 1) + ')';
        } else {
          nutrition.name = nutrition.name + ' (1)';
        }
      }
      nutrition = await this.repository.save(nutrition);
      if (withEventDispatch) {
        this.eventProvider.dispatch(new NutritionSaved(nutrition));
      }
      return nutrition;
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async archiveNutrition(
    dietitianId: string,
    nutritionId: string,
  ): Promise<Nutrition> {
    let nutrition = (
      await this.repository.load(dietitianId, nutritionId)
    ).archive();
    nutrition = await this.repository.save(nutrition);
    this.eventProvider.dispatch(new NutritionArchived(nutrition));
    return nutrition;
  }

  async restoreNutrition(
    dietitianId: string,
    nutritionId: string,
  ): Promise<Nutrition> {
    let nutrition = (
      await this.repository.load(dietitianId, nutritionId)
    ).restore();
    nutrition = await this.repository.save(nutrition);
    this.eventProvider.dispatch(new NutritionRestored(nutrition));
    return nutrition;
  }

  async duplicate(nutrition: Nutrition): Promise<Nutrition> {
    const duplicata = nutrition.copyWith(nutrition.props);
    const nutritionProps = duplicata.props;
    nutritionProps.name = 'Duplicata de "' + nutritionProps.name + '"';
    nutritionProps.archivedAt = undefined;
    nutritionProps.updatedAt = undefined;
    nutritionProps.createdAt = new Date();

    if (nutritionProps.dietitianId) {
      const exists = await this.repository.existsForDietitianIdAndName(
        nutritionProps.dietitianId.id.toString(),
        nutritionProps.name,
      );

      if (exists) {
        throw new NutritionExistingNameException(nutritionProps.name);
      }

      let duplicatedNutrition = Nutrition.create(
        nutritionProps,
        new UniqueEntityID(),
      );
      duplicatedNutrition = await this.repository.create(duplicatedNutrition);
      this.eventProvider.dispatch(new NutritionCreated(duplicatedNutrition));
      return duplicatedNutrition;
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async createNutritionQuantityUnit(
    quantityUnit: NutritionQuantityUnit,
  ): Promise<NutritionQuantityUnit> {
    return this.repository.createQuantityUnit(quantityUnit);
  }

  async updateNutritionQuantityUnit(
    quantityUnit: NutritionQuantityUnit,
  ): Promise<NutritionQuantityUnit> {
    return this.repository.updateQuantityUnit(quantityUnit);
  }

  async findAllQuantityUnit(
    dietitianId: string,
  ): Promise<NutritionQuantityUnit[]> {
    return this.repository.findAllQuantityUnit(dietitianId);
  }

  async loadQuantityUnitIntoCache(dietitianId: string) {
    await this.nutritionQuantityUnitRepository.loadAllInCache(dietitianId);
  }
}
