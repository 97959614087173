import Entity from '../../../core/domain/entity';

export interface MsdpTokenProps {
  token?: string;
  userId?: string;
}

export class MsdpToken extends Entity<MsdpTokenProps> {
  get token(): string | undefined {
    return this.props.token;
  }

  get userId(): string | undefined {
    return this.props.userId;
  }

  copyWith(props: MsdpTokenProps): MsdpToken {
    return MsdpToken.create({
      ...this.props,
      ...props,
    });
  }

  public static create(props: MsdpTokenProps): MsdpToken {
    return new MsdpToken(props);
  }
}
