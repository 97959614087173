import ValueObject from 'src/app/core/domain/value_object';

interface TemplateProps {
  name: string;
  data: { [key: string]: unknown };
}

export class Template extends ValueObject<TemplateProps> {
  get name(): string {
    return this.props.name;
  }

  get data(): { [key: string]: unknown } {
    return this.props.data;
  }

  private constructor(props: TemplateProps) {
    super(props);
  }

  public static create(props: TemplateProps): Template {
    return new Template(props);
  }
}
