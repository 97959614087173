import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { MsdpTokenStateProvider } from './domain/msdp-token-state.provider';
import { MsdpGateway } from './gateway/msdp-gateway';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, ToastrModule.forRoot()],
  providers: [MsdpGateway],
})
export class MsdpModule {
  static forRoot(): ModuleWithProviders<MsdpModule> {
    return {
      ngModule: MsdpModule,
      providers: [
        { provide: MsdpGateway },
        {
          provide: MsdpTokenStateProvider,
        },
      ],
    };
  }
}
