import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { ConversationCommands } from './domain/conversation/conversation_commands';
import { ConversationEventProvider } from './domain/events/conversation/conversation_event_provider';
import { MessageEventProvider } from './domain/events/message/message_event_provider';
import { MessageCommands } from './domain/message/message_commands';
import { ConversationRepository } from './repositories/conversation_repository';
import { MessageRepository } from './repositories/message_repository';

@NgModule({
  declarations: [],
  imports: [CommonModule, ToastrModule.forRoot()],
  providers: [
    MessageRepository,
    MessageEventProvider,
    MessageCommands,
    ConversationRepository,
    ConversationEventProvider,
    ConversationCommands,
  ],
})
export class MessageModule {
  static forRoot(): ModuleWithProviders<MessageModule> {
    return {
      ngModule: MessageModule,
      providers: [
        {
          provide: MessageEventProvider,
        },
        {
          provide: MessageCommands,
        },
        {
          provide: ConversationEventProvider,
        },
        {
          provide: ConversationCommands,
        },
      ],
    };
  }
}
