import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import {
  Conversation,
  ConversationProps,
} from '../../conversation/conversation';

export class ConversationEvent extends DomainEvent<Conversation> {}

export class ConversationCreated extends ConversationEvent {}

export class ConversationsCreated extends ConversationEvent {
  constructor() {
    super(Conversation.create({} as ConversationProps));
  }
}

export class ConversationClosed extends ConversationEvent {}

export class ConversationUpdated extends ConversationEvent {}

export class ConversationRead extends ConversationEvent {}
