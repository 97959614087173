import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { NutritionComponent } from './nutrition-component';

export interface NutritionCompositionProps {
  confiance: string;
  component: NutritionComponent | undefined;
  date: Date | undefined;
  value: number | 'traces' | undefined;
  min?: number | undefined;
  max?: number | undefined;
}

export class NutritionCompositionId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): NutritionCompositionId {
    return new NutritionCompositionId(id);
  }
}

export class NutritionComposition extends Entity<NutritionCompositionProps> {
  get confiance(): string {
    return this.props.confiance;
  }

  get component(): NutritionComponent | undefined {
    return this.props.component;
  }

  set component(v: NutritionComponent | undefined) {
    this.props.component = v;
  }

  get value(): number | 'traces' | undefined {
    return this.props.value;
  }

  set value(v: number | 'traces' | undefined) {
    this.props.value = v;
  }

  get min(): number | undefined {
    return this.props.min;
  }

  get max(): number | undefined {
    return this.props.max;
  }

  get date(): Date | undefined {
    return this.props.date;
  }

  private constructor(props: NutritionCompositionProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: NutritionCompositionProps): NutritionComposition {
    return NutritionComposition.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: NutritionCompositionProps,
    id?: UniqueEntityID,
  ): NutritionComposition {
    return new NutritionComposition(props, id);
  }
}
