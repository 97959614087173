import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { Document } from '../../document/document';

export class DocumentEvent extends DomainEvent<Document> {}

export class DocumentCreated extends DocumentEvent {}

export class DocumentUpdated extends DocumentEvent {}

export class DocumentDeleted extends DocumentEvent {}
