import { Logger } from '@angular/compiler-cli';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { FirebaseError } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';

import { AppService } from '../../app.service';
import { LoggerRepository } from '../../data/logger/repositories/logger_repository';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly logger: Logger;

  constructor(@Inject(Injector) private injector: Injector) {
    this.logger = injector.get(AppService).logger;
  }

  // prevent cyclic dependency error
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  private get loggerRepository(): LoggerRepository {
    return this.injector.get(LoggerRepository);
  }

  handleError(error: unknown) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rawError = error as any;
    if (rawError.promise && rawError.rejection) {
      error = rawError.rejection;
    }

    // si code: -100, on ne fait rien
    if (rawError && rawError.code && rawError.code === -100) {
      return;
    }

    // this prevent display firebase related error
    if (error instanceof FirebaseError) {
      this.logger.error(
        'GlobalErrorHandler',
        'FirebaseError',
        JSON.stringify(error),
      );
      this.loggerRepository
        .error('GlobalErrorHandler', 'FirebaseError', error)
        .then();
      return;
    }
    if (error instanceof Error) {
      const chunkFailedMessage = /(?=.*Loading)(?=.*chunk)(?=.*failed)/;
      const unexpectedRouteMessage =
        /(?=.*Cannot)(?=.*match)(?=.*any)(?=.*routes)/;
      const operationNotSupportedMessage =
        /(?=.*The)(?=.*operation)(?=.*is)(?=.*not)(?=.*supported)/;
      if (
        unexpectedRouteMessage.test(error.message) ||
        operationNotSupportedMessage.test(error.message)
      ) {
        return;
      }

      if (chunkFailedMessage.test(error.message)) {
        this.loggerRepository
          .error('GlobalErrorHandler', 'ChunkFailedMessage', error)
          .then();
        if (
          confirm(
            'Une nouvelle version du site est disponible, un rechargement est requis. Voulez-vous recharger la page ?',
          )
        ) {
          window.location.reload();
        }
      } else {
        this.logger.error(
          'GlobalErrorHandler',
          'FunctionalError',
          JSON.stringify(error),
        );
        this.loggerRepository
          .error('GlobalErrorHandler', 'FunctionalError', error)
          .then();
        this.toastrService.clear();
        this.toastrService.error(
          error.message && error.message.length > 0
            ? error.message
            : 'Une erreur est survenue',
          'Erreur',
          {
            onActivateTick: true,
          },
        );
        throw error;
      }
    }
  }
}
