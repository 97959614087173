import { Exception } from 'src/app/core/logic/exception';

export class PatientNotFoundException extends Exception<never> {
  constructor() {
    super('Patient non trouvé');
  }
}

export class PatientExistingNumberException extends Exception<string> {
  constructor(phoneNumber: string) {
    super(
      `Un patient avec le numéro "${phoneNumber}" existe déjà`,
      phoneNumber,
    );
  }
}
