import { Exception } from '../../../../core/logic/exception';

export class CustomNutritionNotFoundException extends Exception<never> {
  constructor() {
    super('Plan alimentaire non trouvé');
  }
}

export class CustomNutritionExistingNameException extends Exception<string> {
  constructor(name: string) {
    super(`Un plan alimentaire avec le nom "${name}" existe déjà`, name);
  }
}
