import ValueObject from 'src/app/core/domain/value_object';

import { InvalidPictureFileTypeException } from './picture_exceptions';

export const PICTURE_MIME_TYPE_REGEX = /(^image\/(jpeg|png)$)/;

interface PictureProps {
  url?: string;
  path?: string;
  file?: File;
}

export class Picture extends ValueObject<PictureProps> {
  get url(): string | undefined {
    return this.props.url;
  }

  get path(): string | undefined {
    return this.props.path;
  }

  get file(): File | undefined {
    return this.props.file;
  }

  private constructor(props: PictureProps) {
    super(props);
  }

  public static create(props: PictureProps): Picture {
    if (props.file !== undefined) {
      if (!props.file.type.match(PICTURE_MIME_TYPE_REGEX)) {
        throw new InvalidPictureFileTypeException();
      }
    }
    return new Picture(props);
  }
}
