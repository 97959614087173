import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { NutritionComponent } from '../domain/nutritions/nutrition-component';
import { firstValueFrom } from 'rxjs';

export interface CiqualComponentSchema {
  label: string;
}

@Injectable()
export class CiqualComponentRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection() {
    return this.firestore.collection<CiqualComponentSchema>(
      'ciqual_consts',
      (ref) => ref.orderBy('label'),
    );
  }

  toSchema(nutritionComponent: NutritionComponent): CiqualComponentSchema {
    return <CiqualComponentSchema>{
      label: nutritionComponent.label,
    };
  }

  fromSchema(schema: CiqualComponentSchema, id: string): NutritionComponent {
    return NutritionComponent.create(
      {
        label: schema.label,
      },
      new UniqueEntityID(id),
    );
  }

  async findAll(): Promise<NutritionComponent[]> {
    const snap = await firstValueFrom(this.collection().get());
    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }
}
