import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, map } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { Message, MessageProps } from '../domain/message/message';

export interface MessageSchema {
  message: string | null;
  createdAt: Timestamp;
  mimetype: string | null;
  recipientId: string | null;
  url: string | null;
  resourcePath: string | null;
  senderId: string;
  hasMedia: boolean;
  sender: MessageSenderSchema;
}

interface MessageSenderSchema {
  firstName: string;
  lastName: string;
  readDate: Timestamp;
  id: string;
  profilePicture: string | null;
}

export interface UploadResult {
  url: string;
  path: string;
}

@Injectable()
export class MessageRepository {
  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) {
    // do nothing
  }

  private collection(messageId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('conversations')
      .doc(messageId)
      .collection<MessageSchema>('messages', queryFn);
  }

  public toSchema(message: Message): MessageSchema {
    return <MessageSchema>{
      url: message.url ?? null,
      resourcePath: message.resourcePath ?? null,
      recipientId: message.recipientId ? message.recipientId.toString() : null,
      message: message.message ?? null,
      mimetype: message.mimeType ?? null,
      senderId: message.senderId.toString(),
      hasMedia:
        message.mimeType != null &&
        message.resourcePath != null &&
        message.url != null,
      createdAt:
        message.createdAt !== undefined
          ? Timestamp.fromDate(message.createdAt)
          : Timestamp.now(),
    };
  }

  public fromSchema(schema: MessageSchema, id?: string): Message {
    return Message.create(
      {
        message: schema.message,
        resourcePath: schema.resourcePath,
        recipientId: schema.recipientId
          ? new UniqueEntityID(schema.recipientId)
          : undefined,
        createdAt: schema.createdAt.toDate(),
        mimeType: schema.mimetype,
        hasMedia: schema.hasMedia,
        url: schema.url,
        senderId: new UniqueEntityID(schema.senderId),
      } as MessageProps,
      id ? new UniqueEntityID(id) : undefined,
    );
  }

  async saveAudioAttachment(
    messageId: string,
    userId: string,
    audioBlob: Blob,
  ): Promise<UploadResult> {
    const path = `users/${userId}/conversations/${messageId}/attachments/${Date.now()}.wav`;
    const uploadTask = await this.storage.upload(path, audioBlob, {
      contentType: 'audio/wav',
    });

    return {
      path,
      url: await uploadTask.ref.getDownloadURL(),
    };
  }

  async savePictureAttachment(
    messageId: string,
    userId: string,
    base64Image: string,
  ): Promise<UploadResult> {
    const path = `users/${userId}/conversations/${messageId}/attachments/${Date.now()}`;
    const uploadTask = await this.storage
      .ref(path)
      .putString(base64Image.split(',')[1], 'base64', {
        contentType: 'image/*',
      });

    return {
      path,
      url: await uploadTask.ref.getDownloadURL(),
    };
  }

  listenOnMessageId(messageId: string) {
    return this.collection(messageId, (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges()
      .pipe(
        map((messages) => {
          return messages.reverse().map((messageSchema) => {
            return this.fromSchema(messageSchema as MessageSchema);
          });
        }),
      );
  }

  async findByMessageId(messageId: string): Promise<Message[]> {
    const snap = await firstValueFrom(
      this.collection(messageId, (ref) =>
        ref.orderBy('createdAt', 'asc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async create(messageId: string, message: Message): Promise<Message> {
    const schema = this.toSchema(message);
    const ref = await this.collection(messageId).add(schema);
    return this.fromSchema(schema, ref.id);
  }

  save(messageId: string, message: Message): Promise<Message> {
    const schema = this.toSchema(message);

    return this.collection(messageId)
      .doc(message.id.toString())
      .set(schema)
      .then(() => this.fromSchema(schema));
  }

  delete(conversationId: string, messageId: string): Promise<void> {
    return this.collection(conversationId).doc(messageId).delete();
  }
}
