import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

export enum SubscriptionBannerType {
  trial,
  pending_cancel,
  expired,
  unpaid,
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'msd-subscription-banner',
  templateUrl: './subscription-banner.component.html',
  styleUrls: ['./subscription-banner.component.scss'],
})
export class SubscriptionBannerComponent implements OnChanges {
  @Input() type?: SubscriptionBannerType | undefined;
  @Input() remainingDays?: number;
  @Output() close = new EventEmitter();

  public title: string;
  public subtitle: string;

  constructor() {
    // nothing
  }

  ngOnChanges(): void {
    switch (this.type) {
      case SubscriptionBannerType.trial:
        this.title = "Période d'essai :";
        if (this.remainingDays && this.remainingDays > 0) {
          this.subtitle = `${this.remainingDays.toFixed(0)} jour${
            this.remainingDays > 1 ? 's' : ''
          } restant${this.remainingDays > 1 ? 's' : ''}`;
        }
        break;
      case SubscriptionBannerType.expired:
        this.title = 'Votre abonnement a expiré !';
        this.subtitle =
          'Veuillez renouveller votre abonnement pour continuer à utiliser nos services.';
        break;
      case SubscriptionBannerType.pending_cancel:
        this.title = 'Votre abonnement est annulé.';
        if (this.remainingDays && this.remainingDays > 0) {
          this.subtitle = `${this.remainingDays.toFixed(0)} jour${
            this.remainingDays > 1 ? 's' : ''
          } restant${this.remainingDays > 1 ? 's' : ''}`;
        }
        break;
      case SubscriptionBannerType.unpaid:
        this.title = 'Votre abonnement a été interrompu !';
        this.subtitle =
          'Un incident de paiement a eu lieu, veuillez vérifier le moyen de paiement de votre abonnement pour continuer à utiliser nos services.';
        break;
    }
  }

  onClose() {
    this.close?.emit();
  }
}
