import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { NutritionComponent } from '../domain/nutritions/nutrition-component';
import { NutritionComposition } from '../domain/nutritions/nutrition-composition';
import { NutritionIngredient } from '../domain/nutritions/nutrition-ingredient';

export interface NutritionIngredientSchema {
  code: string;
  label: string;
  group: string;
  ssGroup: string;
  ssSsGroup: string;
  compositions: NutritionCompositionSchema[];
}

export interface NutritionCompositionSchema {
  component: NutritionComponentSchema;
  value: number;
  date: Timestamp;
}

export interface NutritionComponentSchema {
  id: string;
  label: string;
}

@Injectable()
export class NutritionIngredientRepository {
  private readonly saveIngredientIntoAlgolia: (
    data: unknown,
  ) => Observable<void>;

  private readonly searchIngredientsToAlgolia: (
    data: unknown,
  ) => Observable<{ [key: string]: unknown }[]>;
  private static INDEX = 'nutrition_ingredients';

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    this.saveIngredientIntoAlgolia = this.functions.httpsCallable<
      unknown,
      void
    >('nutrition-saveIngredientIntoAlgolia');

    this.searchIngredientsToAlgolia = this.functions.httpsCallable<
      unknown,
      { [key: string]: unknown }[]
    >('nutrition-searchIngredients');
  }

  private collection(dietitianId: string, qry?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<NutritionIngredientSchema>('nutrition_ingredients', qry);
  }

  toSchema(
    nutritionIngredient: NutritionIngredient,
  ): NutritionIngredientSchema {
    return <NutritionIngredientSchema>{
      code: nutritionIngredient.id.toString(),
      label: nutritionIngredient.label,
      group: nutritionIngredient.group,
      ssGroup: nutritionIngredient.ssGroup,
      ssSsGroup: nutritionIngredient.ssSsGroup,
      compositions: nutritionIngredient.compositions
        ? nutritionIngredient.compositions.map((c) =>
            this.toCompositionSchema(c),
          )
        : [],
    };
  }

  fromSchema(
    schema: NutritionIngredientSchema,
    id?: string | undefined,
  ): NutritionIngredient {
    if (schema) {
      return NutritionIngredient.create(
        {
          label: schema.label,
          group: schema.group,
          ssGroup: schema.ssGroup,
          ssSsGroup: schema.ssSsGroup,
          compositions: schema.compositions
            ? schema.compositions.map((c) => this.fromCompositionSchema(c))
            : [],
        },
        new UniqueEntityID(id),
      );
    } else {
      return NutritionIngredient.create(
        {
          label: 'ERREUR',
          group: '',
          ssGroup: '',
          ssSsGroup: '',
          compositions: [],
        },
        new UniqueEntityID(id),
      );
    }
  }

  async load(
    dietitianId: string,
    ingredientId: string,
  ): Promise<NutritionIngredient | undefined> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(ingredientId).get(),
    );
    if (!snap.exists || snap.data == null) {
      return undefined;
    }

    const ingredient = this.fromSchema(
      snap.data() as NutritionIngredientSchema,
      snap.id,
    );
    return ingredient;
  }

  async findAll(dietitianId: string, my = false) {
    if (my) {
      const snap = await firstValueFrom(
        this.collection(dietitianId, (ref) =>
          ref.where('group', '==', 'MY'),
        ).get(),
      );
      return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
    } else {
      const snap = await firstValueFrom(
        this.collection(dietitianId, (ref) =>
          ref.where('group', '!=', 'MY'),
        ).get(),
      );
      return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
    }
  }

  async save(
    dietitianId: string,
    nutritionIngredient: NutritionIngredient,
  ): Promise<NutritionIngredient> {
    const schema = this.toSchema(nutritionIngredient);
    await this.collection(dietitianId)
      .doc(nutritionIngredient.code)
      .set(schema);
    const sync = firstValueFrom(
      this.saveIngredientIntoAlgolia({
        code: nutritionIngredient.code,
        label: nutritionIngredient.label,
        group: nutritionIngredient.group,
        ssGroup: nutritionIngredient.ssGroup,
        ssSsGroup: nutritionIngredient.ssSsGroup,
        dietitianId: dietitianId,
        index: NutritionIngredientRepository.INDEX,
      }),
    ).catch();
    await sync;
    return this.fromSchema(schema);
  }

  async searchIngredients(
    dietitianId: string,
    query: string,
  ): Promise<NutritionIngredient[]> {
    const index = NutritionIngredientRepository.INDEX;
    const results = await firstValueFrom(
      this.searchIngredientsToAlgolia({
        query,
        index,
        dietitianId,
      }),
    );
    return results.map(this.fromMap);
  }

  fromMap(map: { [key: string]: unknown }): NutritionIngredient {
    return NutritionIngredient.create(
      {
        label: map['label'] as string,
        group: map['group'] as string,
        ssGroup: map['ssGroup'] as string,
        ssSsGroup: map['ssSsGroup'] as string,
        compositions: [],
      },
      new UniqueEntityID(map['objectID'] as string),
    );
  }

  toCompositionSchema(
    nutritionComposition: NutritionComposition,
  ): NutritionCompositionSchema {
    return <NutritionCompositionSchema>{
      component: {
        id: nutritionComposition.component?.id.toString(),
        label: nutritionComposition.component?.label,
      },
      value: nutritionComposition.value,
      date: Timestamp.now(),
    };
  }

  fromCompositionSchema(
    schema: NutritionCompositionSchema,
  ): NutritionComposition {
    return NutritionComposition.create({
      component: NutritionComponent.create(
        { label: schema.component.label },
        new UniqueEntityID(schema.component.id),
      ),
      value: schema.value,
      confiance: 'M',
      date: schema.date ? schema.date.toDate() : undefined,
    });
  }
}
