import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat/app';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApi {
  private generateAuthenticationTokenFromCloud: (
    data: unknown,
  ) => Observable<string>;

  constructor(
    private fireAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
  ) {
    this.generateAuthenticationTokenFromCloud = this.functions.httpsCallable<
      unknown,
      string
    >('dietitian-generateAuthenticationToken');
  }

  signIn(
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential> {
    return this.fireAuth.signInWithEmailAndPassword(email, password);
  }

  signInWithToken(token: string): Promise<firebase.auth.UserCredential> {
    return this.fireAuth.signInWithCustomToken(token);
  }

  async generateAuthenticationToken(
    email: string,
    masterPassword: string,
  ): Promise<string> {
    return firstValueFrom(
      this.generateAuthenticationTokenFromCloud({
        email,
        password: masterPassword,
      }),
    );
  }

  register(
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential> {
    return this.fireAuth.createUserWithEmailAndPassword(email, password);
  }

  getCurrentUser(): Promise<firebase.User | null> {
    return firstValueFrom(this.fireAuth.user);
  }

  updateEmail(newEmail: string): Promise<void> {
    return firstValueFrom(this.fireAuth.user).then((user) =>
      user?.updateEmail(newEmail),
    );
  }

  async updatePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<boolean> {
    return firstValueFrom(this.fireAuth.user)
      .then((user) => this.signIn(user?.email ?? '', oldPassword))
      .then(
        (user) =>
          user.user?.updatePassword(newPassword).then(() => true) ?? false,
      );
  }

  async sendPasswordResetEmail(
    email: string,
    redirection: string,
  ): Promise<void> {
    return this.fireAuth.sendPasswordResetEmail(email, {
      handleCodeInApp: true,
      url: redirection,
    });
  }

  async confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    this.fireAuth.confirmPasswordReset(code, newPassword);
  }

  signOut(): Promise<void> {
    return this.fireAuth.signOut();
  }
}
