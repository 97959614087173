import { Timestamp } from 'firebase/firestore';

export enum FieldType {
  Information = 'information',
  Small = 'small',
  Credit = 'credit',
  Description = 'description',
  Text = 'text',
  MultiLineText = 'multi_line_text',
  Date = 'date',
  Number = 'number',
  Checkbox = 'checkbox',
  Range = 'range',
  Today = 'today',
  DateOfBirth = 'date_of_birth',
  Assessment = 'assessment',
  InvAssessment = 'inv_assessment',
  NzAssessment = 'nz_assessment',
  Weight = 'weight',
  Size = 'size',
  WaistSize = 'waist_size',
  HipSize = 'hip_size',
  ThighSize = 'thigh_size',
  FormWeight = 'form_weight',
  ArmSize = 'arm_size',
  FatMass = 'fat_mass',
  MuscularMass = 'muscular_mass',
  Comment = 'comment',
}

export class FieldTool {
  static valueToSchema(fieldType: FieldType, value: unknown) {
    switch (fieldType) {
      case FieldType.Information:
      case FieldType.Small:
      case FieldType.Credit:
      case FieldType.Description:
        return null;
      case FieldType.MultiLineText:
      case FieldType.Text:
        return value as string;
      case FieldType.Checkbox:
        return value as boolean;
      case FieldType.Date:
      case FieldType.DateOfBirth:
      case FieldType.Today:
        return value instanceof Date ? Timestamp.fromDate(value) : null;
      case FieldType.Number:
      case FieldType.ArmSize:
      case FieldType.FatMass:
      case FieldType.FormWeight:
      case FieldType.HipSize:
      case FieldType.MuscularMass:
      case FieldType.Size:
      case FieldType.ThighSize:
      case FieldType.WaistSize:
      case FieldType.Weight:
      case FieldType.Range:
      case FieldType.Assessment:
        return Number(value);
      default:
        return value ?? null;
    }
  }

  static valueFromSchema(fieldType: FieldType, schema: unknown) {
    switch (fieldType) {
      case FieldType.Information:
      case FieldType.Small:
      case FieldType.Credit:
      case FieldType.Description:
        return null;
      case FieldType.MultiLineText:
      case FieldType.Text:
        return schema as string;
      case FieldType.Checkbox:
        return schema as boolean;
      case FieldType.Date:
      case FieldType.DateOfBirth:
      case FieldType.Today:
        return schema instanceof Timestamp ? schema.toDate() : undefined;
      case FieldType.Number:
      case FieldType.ArmSize:
      case FieldType.FatMass:
      case FieldType.FormWeight:
      case FieldType.HipSize:
      case FieldType.MuscularMass:
      case FieldType.Size:
      case FieldType.ThighSize:
      case FieldType.WaistSize:
      case FieldType.Weight:
      case FieldType.Range:
      case FieldType.Assessment:
        return Number(schema);
      default:
        return schema ?? null;
    }
  }
}
