import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';

import { SubscriptionBannerComponent } from './subscription-banner.component';

@NgModule({
  declarations: [SubscriptionBannerComponent],
  exports: [SubscriptionBannerComponent],
  imports: [CommonModule, NgxBootstrapIconsModule],
})
export class SubscriptionBannerModule {}
