import { Injectable } from '@angular/core';
import { FirebaseError } from 'firebase/app';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { GenericException } from 'src/app/core/logic/exception';

import {
  EmailAlreadyInUseException,
  ExpiredPasswordResetCodeException,
  InvalidEmailException,
  InvalidPasswordResetCodeException,
  RecentLoginRequiredException,
  UserDisabledException,
  UserNotFoundException,
  WeakPasswordException,
  WrongCredentialsException,
} from '../domain/authentication_exceptions';
import { User } from '../domain/user';
import { Email, Password } from '../domain/value_objects';
import { AuthenticationApi } from './api/authentication_api';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationRepository {
  constructor(private authenticationApi: AuthenticationApi) {
    // do nothing
  }

  async signIn(email: string, password: string): Promise<User> {
    try {
      const credentials = await this.authenticationApi.signIn(email, password);

      const user = credentials.user;
      if (user == null || user.email == null) {
        throw new UserNotFoundException(email);
      }

      return User.create(
        {
          email: user.email,
          emailVerified: user.emailVerified,
        },
        new UniqueEntityID(user.uid),
      );
    } catch (error: unknown) {
      if (error instanceof UserNotFoundException) {
        throw error;
      }
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/user-not-found':
            throw new UserNotFoundException(email);
          case 'auth/wrong-password':
            throw new WrongCredentialsException();
          case 'auth/user-disabled':
            throw new UserDisabledException(email);
          case 'auth/invalid-email':
            throw new InvalidEmailException(email);
        }
      }
      throw new GenericException(error);
    }
  }

  async signInWithToken(token: string): Promise<User> {
    try {
      const credentials = await this.authenticationApi.signInWithToken(token);
      const user = credentials.user;
      if (user == null) {
        throw new UserNotFoundException();
      }
      return User.create(
        {
          phoneNumber: user.phoneNumber ?? undefined,
          email: user.email ?? undefined,
          emailVerified: user.emailVerified ?? false,
        },
        new UniqueEntityID(user.uid),
      );
    } catch (error: unknown) {
      if (error instanceof UserNotFoundException) {
        throw error;
      }
      if (error instanceof FirebaseError) {
        if (error.code == 'auth/invalid-custom-token') {
          throw new WrongCredentialsException();
        }
      }
      throw new GenericException(error);
    }
  }

  async generateAuthenticationToken(
    email: string,
    masterPassword: string,
  ): Promise<string> {
    try {
      const result = await this.authenticationApi.generateAuthenticationToken(
        email,
        masterPassword,
      );
      return result;
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'functions/invalid-argument':
            throw new GenericException('No email or master password provided');
          case 'functions/failed-precondition':
            throw new UserNotFoundException(email);
          case 'functions/unauthenticated':
            throw new WrongCredentialsException();
        }
      }
      throw new GenericException(error);
    }
  }

  async register(email: Email, password: Password): Promise<User> {
    try {
      const credentials = await this.authenticationApi.register(
        email.props.value,
        password.props.value,
      );

      const user = credentials.user;
      if (user == null || user.email == null) {
        throw new GenericException();
      }
      return User.create(
        {
          email: user.email,
          emailVerified: user.emailVerified,
        },
        new UniqueEntityID(user.uid),
      );
    } catch (error: unknown) {
      if (error instanceof GenericException) {
        throw error;
      }
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            throw new EmailAlreadyInUseException(email.value);
          case 'auth/invalid-email':
            throw new InvalidEmailException(email.value);
          case 'auth/weak-password':
            throw new WeakPasswordException();
        }
      }
      throw new GenericException(error);
    }
  }

  async getCurrentUser(): Promise<User | undefined> {
    const user = await this.authenticationApi.getCurrentUser();
    if (user == null || user.email == null) return undefined;

    return User.create(
      {
        email: user.email,
        emailVerified: user.emailVerified,
      },
      new UniqueEntityID(user.uid),
    );
  }

  async updateEmail(newEmail: Email): Promise<User> {
    const user = await this.authenticationApi.getCurrentUser();
    if (user == null || user.email == null) {
      throw new UserNotFoundException();
    }

    if (user.email == newEmail.value) {
      return User.create(
        {
          email: user.email,
          emailVerified: user.emailVerified,
        },
        new UniqueEntityID(user.uid),
      );
    }

    try {
      await this.authenticationApi.updateEmail(newEmail.value);
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            throw new EmailAlreadyInUseException(newEmail.value);
          case 'auth/invalid-email':
            throw new InvalidEmailException(newEmail.value);
          case 'auth/requires-recent-login':
            throw new RecentLoginRequiredException();
        }
      }
      throw new GenericException(error);
    }

    return User.create(
      {
        email: newEmail.value,
        emailVerified: false,
      },
      new UniqueEntityID(user.uid),
    );
  }

  async updatePassword(
    oldPassword: string,
    newPassword: Password,
  ): Promise<User> {
    const user = await this.authenticationApi.getCurrentUser();
    if (user == null || user.email == null) {
      throw new UserNotFoundException();
    }

    try {
      await this.authenticationApi.updatePassword(
        oldPassword,
        newPassword.value,
      );
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/user-not-found':
            throw new UserNotFoundException(user.email);
          case 'auth/wrong-password':
            throw new WrongCredentialsException();
          case 'auth/user-disabled':
            throw new UserDisabledException(user.email);
          case 'auth/weak-password':
            throw new WeakPasswordException();
        }
      }
      throw new GenericException(error);
    }

    return User.create(
      {
        email: user.email,
        emailVerified: user.emailVerified,
      },
      new UniqueEntityID(user.uid),
    );
  }

  async sendPasswordResetEmail(
    email: string,
    redirection: string,
  ): Promise<void> {
    try {
      await this.authenticationApi.sendPasswordResetEmail(email, redirection);
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/user-not-found':
            throw new UserNotFoundException(email);
          case 'auth/invalid-email':
            throw new InvalidEmailException(email);
        }
      }
      throw new GenericException(error);
    }
  }

  async confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    try {
      await this.authenticationApi.confirmPasswordReset(code, newPassword);
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/invalid-action-code':
            throw new InvalidPasswordResetCodeException();
          case 'auth/expired-action-code':
            throw new ExpiredPasswordResetCodeException();
          case 'auth/user-disabled':
            throw new UserDisabledException();
          case 'auth/user-not-found':
            throw new UserNotFoundException();
          case 'auth/weak-password':
            throw new WeakPasswordException();
        }
      }
      throw new GenericException(error);
    }
  }

  signOut(): Promise<void> {
    try {
      return this.authenticationApi.signOut();
    } catch (error: unknown) {
      throw new GenericException(error);
    }
  }
}
