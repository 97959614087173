import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { UserId } from 'src/app/data/auth/domain/user';
import { DietitianId } from 'src/app/data/dietitian/domain/dietitian';
import { PatientId } from 'src/app/data/patient/domain/patient';

import { Document } from '../document/document';
import { SharedDocumentCategory } from './shared_document_category';
import {
  InvalidSharedDocumentFileTypeException,
  SharedDocumentFileTooBigException,
} from './shared_document_exceptions';

export const SHARED_DOCUMENT_MIME_TYPES = [
  'application/pdf',
  'application/vnd.ms-excel', // xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/msword', // doc,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.oasis.opendocument.spreadsheet', // ods
  'application/vnd.oasis.opendocument.text', // odt
  'application/zip',
  'application/x-7z-compressed', // 7z
  'application/x-rar-compressed', // rar
  'audio/aac',
  'audio/mpeg', // mp3
  'audio/ogg',
  'image/jpeg',
  'image/png',
];

export const SHARED_DOCUMENT_MAX_SIZE = 15 * 1024 * 1024;

export interface SharedDocumentProps {
  dietitianId: DietitianId;
  patientId?: PatientId;
  patientUserId?: UserId;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  url?: string;
  path?: string;
  file?: File;
  size: number;
  mimeType: string;
  category?: SharedDocumentCategory;
  deleted: boolean;
  deletedAt?: Date;
  deletedByUser?: string;
}

export class SharedDocumentId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): SharedDocumentId {
    return new SharedDocumentId(id);
  }
}

export class SharedDocument extends Entity<SharedDocumentProps> {
  get sharedDocumentId(): SharedDocumentId {
    return SharedDocumentId.create(this.id);
  }

  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get patientId(): PatientId | undefined {
    return this.props.patientId;
  }

  get patientUserId(): UserId | undefined {
    return this.props.patientUserId;
  }

  get name(): string {
    return this.props.name;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get url(): string | undefined {
    return this.props.url;
  }

  get path(): string | undefined {
    return this.props.path;
  }

  get file(): File | undefined {
    return this.props.file;
  }

  get size(): number {
    return this.props.size;
  }

  get mimeType(): string {
    return this.props.mimeType;
  }

  get category(): SharedDocumentCategory | undefined {
    return this.props.category;
  }

  get deleted(): boolean {
    return this.props.deleted;
  }

  get deletedAt(): Date | undefined {
    return this.props.deletedAt;
  }

  get deletedByUser(): string | undefined {
    return this.props.deletedByUser;
  }

  private constructor(props: SharedDocumentProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: SharedDocumentProps): SharedDocument {
    return SharedDocument.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: SharedDocumentProps,
    id?: UniqueEntityID,
  ): SharedDocument {
    if (props.file) {
      if (!SharedDocument.isTypeAllowed(props.file.type)) {
        throw new InvalidSharedDocumentFileTypeException();
      }
      if (!SharedDocument.isSizeAllowed(props.file.size)) {
        throw new SharedDocumentFileTooBigException();
      }
    }
    return new SharedDocument(props, id);
  }

  public static createFromDocument(
    document: Document,
    id?: UniqueEntityID,
  ): SharedDocument {
    if (!SharedDocument.isTypeAllowed(document.mimeType)) {
      throw new InvalidSharedDocumentFileTypeException();
    }
    if (!SharedDocument.isSizeAllowed(document.size)) {
      throw new SharedDocumentFileTooBigException();
    }

    return SharedDocument.create(
      {
        dietitianId: document.dietitianId,
        patientId: document.patientId,
        name: document.name,
        size: document.size,
        mimeType: document.mimeType,
        url: document.url,
        path: document.path,
        deleted: false,
      },
      id,
    );
  }

  public static isTypeAllowed = (type: string): boolean =>
    SHARED_DOCUMENT_MIME_TYPES.includes(type);

  public static isSizeAllowed = (size: number): boolean =>
    size <= SHARED_DOCUMENT_MAX_SIZE;

  public static getDietitianGenericSharedDocumentPath(
    dietitianId: string,
    documentId: string,
    filename: string,
  ): string {
    return `users/${dietitianId}/shared-documents/${documentId}-${filename}`;
  }

  public static getDietitianPatientSharedDocumentPath(
    dietitianId: string,
    patientId: string,
    documentId: string,
    filename: string,
  ): string {
    return `users/${dietitianId}/patients/${patientId}/shared-documents/${documentId}-${filename}`;
  }
}
