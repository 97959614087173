import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { NutritionTreeViewValue } from '../../../../ui/nutrition/routes/editor/components/nutrition-food-library/components/nutrition-tree-view-node/nutrition-tree-view-node';
import { NutritionComposition } from './nutrition-composition';

export interface NutritionIngredientProps {
  label: string;
  group: string;
  ssGroup: string;
  ssSsGroup: string;
  middle?: boolean;
  compositions: NutritionComposition[];
}

export class NutritionIngredient
  extends Entity<NutritionIngredientProps>
  implements NutritionTreeViewValue
{
  get code(): string {
    return this.id.toString();
  }

  get label(): string {
    return this.props.label;
  }

  set label(v: string) {
    this.props.label = v;
  }

  get group(): string {
    return this.props.group;
  }

  get ssGroup(): string {
    return this.props.ssGroup;
  }

  get ssSsGroup(): string {
    return this.props.ssSsGroup;
  }

  get compositions(): NutritionComposition[] {
    return this.props.compositions;
  }

  set compositions(value: NutritionComposition[]) {
    this.props.compositions = value;
  }

  private constructor(props: NutritionIngredientProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: NutritionIngredientProps): NutritionIngredient {
    return NutritionIngredient.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: NutritionIngredientProps,
    id?: UniqueEntityID,
  ): NutritionIngredient {
    return new NutritionIngredient(props, id);
  }
}
