import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { InvitationTemplate } from '../domain/invitation_template/invitation-template';

export interface InvitationTemplateSchema {
  content: string;
}

@Injectable()
export class InvitationTemplateRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection(dietitianId: string) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<InvitationTemplateSchema>('invitation_templates');
  }

  toSchema(template: InvitationTemplate): InvitationTemplateSchema {
    return <InvitationTemplateSchema>{
      content: template.content,
    };
  }

  fromSchema(schema: InvitationTemplateSchema, id: number): InvitationTemplate {
    return InvitationTemplate.create(
      {
        content: schema.content,
      },
      new UniqueEntityID(id),
    );
  }

  async loadAll(dietitianId: string): Promise<InvitationTemplate[]> {
    const snap = await firstValueFrom(this.collection(dietitianId).get());
    return snap.docs
      .map((doc) => this.fromSchema(doc.data(), Number(doc.id)))
      .sort(this.sortInvitationTemplate);
  }

  async save(
    dietitianId: string,
    template: InvitationTemplate,
  ): Promise<InvitationTemplate> {
    const templates = await this.loadAll(dietitianId);
    let id = 1;
    if (templates && templates.length > 0) {
      const max = templates
        .map((value) => value.id.toValue() as number)
        .reduce((acc, current) => (acc < current ? current : acc));
      id = max + 1;
    }
    const schema = this.toSchema(template);
    if (dietitianId) {
      await this.collection(dietitianId).doc(id.toString()).set(schema);
      return this.fromSchema(schema, id);
    } else {
      return Promise.reject("Modèle d'invitation non identifié");
    }
  }

  private sortInvitationTemplate(
    a: InvitationTemplate,
    b: InvitationTemplate,
  ): number {
    if (a.id.toValue() > b.id.toValue()) {
      return 1;
    } else if (a.id.toValue() < b.id.toValue()) {
      return -1;
    } else {
      return 0;
    }
  }
}
