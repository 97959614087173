import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreModule } from 'src/app/core/core.module';

import { AuthenticationCommands } from './domain/authentication_commands';
import { AuthenticationStateProvider } from './domain/authentication_state_provider';
import { AuthenticationEventProvider } from './domain/events/authentication_event_provider';
import { AuthenticationRepository } from './repositories/authentication_repository';

@NgModule({
  declarations: [],
  imports: [CommonModule, CoreModule],
  providers: [
    AuthenticationRepository,
    AuthenticationStateProvider,
    AuthenticationEventProvider,
    AuthenticationCommands,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: AuthenticationStateProvider,
        },
        {
          provide: AuthenticationEventProvider,
        },
        {
          provide: AuthenticationCommands,
        },
      ],
    };
  }
}
