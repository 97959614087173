import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { IncomingMessageEventProvider } from './incoming-message-event-provider';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        {
          provide: IncomingMessageEventProvider,
        },
      ],
    };
  }
}
