import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { SynthesisFormula } from '../../../../ui/template/routes/editor/components/editor-synthesis-element/synthesis-model';
import { TemplateFieldId } from './template_field';
import { TemplateSynthesisId } from './template-synthesis';

export class TemplateSynthesisDatasetProps {
  templateSynthesisId?: TemplateSynthesisId;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  values?: TemplateFieldId[];
  formula: SynthesisFormula;
}

export class TemplateSynthesisDatasetId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): TemplateSynthesisDatasetId {
    return new TemplateSynthesisDatasetId(id);
  }
}

export class TemplateSynthesisDataset extends Entity<TemplateSynthesisDatasetProps> {
  get templateSynthesisDatasetId(): TemplateSynthesisDatasetId {
    return TemplateSynthesisDatasetId.create(this.id);
  }

  get templateSynthesisId(): TemplateSynthesisId | undefined {
    return this.props.templateSynthesisId;
  }

  get name(): string {
    return this.props.name;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get deletedAt(): Date | undefined {
    return this.props.deletedAt;
  }

  get formula(): SynthesisFormula {
    return this.props.formula;
  }

  get values(): TemplateFieldId[] | undefined {
    return this.props.values;
  }

  private constructor(
    props: TemplateSynthesisDatasetProps,
    id?: UniqueEntityID,
  ) {
    super(props, id);
  }

  copyWith(props: TemplateSynthesisDatasetProps): TemplateSynthesisDataset {
    return TemplateSynthesisDataset.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: TemplateSynthesisDatasetProps,
    id?: UniqueEntityID,
  ): TemplateSynthesisDataset {
    return new TemplateSynthesisDataset(props, id);
  }
}
