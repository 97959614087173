import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { Feature, FeatureStatus } from '../domain/subscription/feature';
import { StripePriceId } from '../domain/subscription/premium';
import { StripeProductId } from '../domain/subscription/subscription';

export interface FeatureSchema {
  code: string;
  label: string;
  sort: number;
  stripeProductId: string;
  stripePriceId: string;
  status: FeatureStatus;
  details: string[];
  createdAt: Timestamp;
  updatedAt?: Timestamp | undefined;
}

@Injectable()
export class FeatureRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<FeatureSchema>('features', queryFn);
  }

  toSchema(feature: Feature): FeatureSchema {
    return {
      code: feature.code,
      label: feature.label,
      sort: feature.sort,
      stripeProductId: feature.stripeProductId?.id.toString() ?? null,
      stripePriceId: feature.stripePriceId?.id.toString() ?? null,
      createdAt:
        feature.createdAt !== undefined
          ? Timestamp.fromDate(feature.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      status: feature.status,
      details: feature.details,
    };
  }

  fromSchema(schema: FeatureSchema, id: string): Feature {
    return Feature.create(
      {
        code: schema.code,
        label: schema.label,
        sort: schema.sort,
        stripeProductId: StripeProductId.create(
          new UniqueEntityID(schema.stripeProductId),
        ),
        stripePriceId: StripePriceId.create(
          new UniqueEntityID(schema.stripePriceId),
        ),
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt?.toDate() ?? undefined,
        status: schema.status,
        details: schema.details,
      },
      new UniqueEntityID(id),
    );
  }

  async findAllActive(): Promise<Feature[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref.where('status', '==', FeatureStatus.Active).orderBy('sort', 'asc'),
      ).get(),
    );
    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async findActiveByCode(code: string): Promise<Feature> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('status', '==', FeatureStatus.Active)
          .where('code', '==', code),
      ).get(),
    );
    return snap.docs
      .map((doc) => this.fromSchema(doc.data(), doc.id))
      .filter((f) => f.code === code)[0];
  }
}
