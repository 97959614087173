import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { filter, map, Observable } from 'rxjs';

import { LoadingStatus } from '../../core/domain/events/state_provider';
import { DietitianStateProvider } from '../../data/dietitian/domain/dietitian_state_provider';

@Injectable()
export class DocumentGuard implements CanActivate {
  constructor(
    private router: Router,
    private dietitianStateProvider: DietitianStateProvider,
  ) {
    // Nada
  }
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.dietitianStateProvider.state$
      .pipe(
        filter((s) => s.loading != LoadingStatus.LOADING), // ignore loading
      )
      .pipe(
        map((e) => {
          // signed in as dietitian, continue
          if (e.entity != null) {
            return true;
          }

          // redirect
          this.router.navigate(['/auth']);
          return false;
        }),
      );
  }
}
