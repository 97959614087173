import { Injectable } from '@angular/core';

import { InvitationTemplateRepository } from '../../repositories/invitation-template-repository';
import { InvitationTemplate } from './invitation-template';

@Injectable()
export class InvitationTemplateCommands {
  constructor(
    private invitationTemplateRepository: InvitationTemplateRepository,
  ) {
    // Nada
  }

  async getAllTemplate(dietitianId: string): Promise<InvitationTemplate[]> {
    return this.invitationTemplateRepository.loadAll(dietitianId);
  }

  async saveTemplate(
    dietitianId: string,
    template: InvitationTemplate,
  ): Promise<InvitationTemplate> {
    return this.invitationTemplateRepository.save(dietitianId, template);
  }
}
