import { Injectable } from '@angular/core';

import {
  EntityState,
  EntityStateProvider,
} from '../../../../core/domain/events/state_provider';
import { Survey } from './survey';

export type SurveyState = EntityState<Survey>;

@Injectable({ providedIn: 'root' })
export class SurveyStateProvider extends EntityStateProvider<SurveyState> {}
