import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import {
  collection as firebaseCollection,
  getCountFromServer,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { SharedDocument } from '../../document/domain/shared_document/shared_document';
import {
  SharedDocumentRepository,
  SharedDocumentSchema,
} from '../../document/repositories/shared_document_repository';
import { Conversation } from '../../message/domain/conversation/conversation';
import { Message } from '../../message/domain/message/message';
import {
  ConversationRepository,
  ConversationSchema,
} from '../../message/repositories/conversation_repository';
import {
  MessageRepository,
  MessageSchema,
} from '../../message/repositories/message_repository';
import { DietitianId } from '../domain/dietitian';
import { EncouragementStatus } from '../domain/encouragement/encouragement';
import {
  ScheduledTask,
  ScheduledTaskStatus,
  ScheduledTaskTargetProps,
  ScheduledTaskTargetRecipientProps,
  ScheduledTaskTargetType,
  ScheduledTaskType,
} from '../domain/scheduled_task/scheduled_task';
import { ScheduledTaskNotFoundException } from '../domain/scheduled_task/scheduled_task_exceptions';
import { Template } from '../domain/templates/template';
import { TemplateRepository, TemplateSchema } from './template_repository';

export interface ScheduledTaskTargetRecipientSchema {
  patientId: string;
  patientUserId: string | null;
}

export interface ScheduledTaskTargetSchema {
  type: ScheduledTaskTargetType;
  groupId: string | null;
  recipients: ScheduledTaskTargetRecipientSchema[];
}

export interface ScheduledTaskSchema {
  dietitianId: string;
  target: ScheduledTaskTargetSchema | null;
  recipientName: string | null;
  type: ScheduledTaskType;
  contentId: string | null;
  content: ConversationSchema | SharedDocumentSchema | TemplateSchema | null;
  subContent: MessageSchema | string | null;
  scheduledAt: Timestamp;
  status: ScheduledTaskStatus | null;
  error: string | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

@Injectable()
export class ScheduledTaskRepository {
  constructor(
    private firestore: AngularFirestore,
    private sharedDocumentRepository: SharedDocumentRepository,
    private conversationRepository: ConversationRepository,
    private messageRepository: MessageRepository,
    private templateRepository: TemplateRepository,
  ) {
    // Nada
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<ScheduledTaskSchema>('scheduled_tasks', queryFn);
  }

  toSchema(scheduledTask: ScheduledTask): ScheduledTaskSchema {
    return <ScheduledTaskSchema>{
      dietitianId: scheduledTask.dietitianId.id.toString(),
      target: this.toTargetSchema(scheduledTask.target),
      recipientName: scheduledTask.recipientName ?? null,
      type: scheduledTask.type,
      contentId: scheduledTask.contentId?.toString() ?? null,
      content: this.getContentToSchema(scheduledTask),
      subContent: this.getSubContentToSchema(scheduledTask),
      scheduledAt: Timestamp.fromDate(scheduledTask.scheduledAt),
      status: scheduledTask.status ?? null,
      error: scheduledTask.error ?? null,
      createdAt:
        scheduledTask.createdAt !== undefined
          ? Timestamp.fromDate(scheduledTask.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
  }

  private toTargetSchema(
    target: ScheduledTaskTargetProps | undefined,
  ): ScheduledTaskTargetSchema | null {
    if (target) {
      return {
        type: target.type,
        groupId: target.groupId ?? null,
        recipients: target.recipients.map((r) =>
          this.toTargetRecipientSchema(r),
        ),
      };
    }
    return null;
  }

  private toTargetRecipientSchema(
    recipient: ScheduledTaskTargetRecipientProps,
  ): ScheduledTaskTargetRecipientSchema {
    return {
      patientId: recipient.patientId,
      patientUserId: recipient.patientUserId ?? null,
    };
  }

  private fromSchema(schema: ScheduledTaskSchema, id: string): ScheduledTask {
    return ScheduledTask.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(schema.dietitianId)),
        target: this.fromTargetSchema(schema.target),
        recipientName: schema.recipientName ?? undefined,
        type: schema.type,
        contentId: schema.contentId
          ? new UniqueEntityID(schema.contentId)
          : undefined,
        content: this.getContentFromSchema(schema),
        subContent: this.getSubContentFromSchema(schema),
        scheduledAt: schema.scheduledAt.toDate(),
        status: schema.status ?? undefined,
        error: schema.error ?? undefined,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
      },
      new UniqueEntityID(id),
    );
  }

  fromTargetSchema(
    schema: ScheduledTaskTargetSchema | null,
  ): ScheduledTaskTargetProps | undefined {
    if (schema) {
      return {
        type: schema.type,
        groupId: schema.groupId ?? undefined,
        recipients: schema.recipients.map((r) =>
          this.fromTargetRecipientSchema(r),
        ),
      };
    }
    return undefined;
  }

  fromTargetRecipientSchema(
    schema: ScheduledTaskTargetRecipientSchema,
  ): ScheduledTaskTargetRecipientProps {
    return {
      patientId: schema.patientId,
      patientUserId: schema.patientUserId ?? undefined,
    };
  }

  async load(
    dietitianId: string,
    scheduledTaskId: string,
  ): Promise<ScheduledTask> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(scheduledTaskId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new ScheduledTaskNotFoundException();
    }

    return this.fromSchema(snap.data() as ScheduledTaskSchema, snap.id);
  }

  async findByDietitianId(
    dietitianId: string,
    groupId: string | undefined,
    asc = true,
  ): Promise<ScheduledTask[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) => {
        let query = ref
          .where('status', '==', ScheduledTaskStatus.TO_PROCESS)
          .orderBy('scheduledAt', asc ? 'asc' : 'desc');

        if (groupId != undefined) {
          query = query.where('groupId', '==', groupId);
        }

        return query;
      }).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async create(scheduledTask: ScheduledTask): Promise<ScheduledTask> {
    const schema = this.toSchema(scheduledTask);
    const ref = await this.collection(scheduledTask.dietitianId.id.toString())
      .add(schema)
      .then(async (schema) => {
        return schema;
      });

    return this.fromSchema(schema, ref.id);
  }

  async save(scheduledTask: ScheduledTask): Promise<ScheduledTask> {
    const schema = this.toSchema(scheduledTask);
    return this.collection(scheduledTask.dietitianId.id.toString())
      .doc(scheduledTask.scheduledTaskId.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(schema, scheduledTask.scheduledTaskId.id.toString()),
      );
  }

  async delete(dietitianId: string, scheduledTaskId: string): Promise<void> {
    return this.collection(dietitianId)
      .doc(scheduledTaskId)
      .update({ status: ScheduledTaskStatus.DELETED });
  }

  async countScheduledTaskByDietitianId(dietitianId: string): Promise<number> {
    const collection = firebaseCollection(
      this.firestore.firestore,
      `dietitians/${dietitianId}/scheduled_tasks`,
    );
    const q = query(
      collection,
      where('status', '==', EncouragementStatus.TO_PROCESS),
    );
    const countResult = await getCountFromServer(q);
    return countResult.data().count;
  }

  async countScheduledTaskByDietitianIdAndType(
    dietitianId: string,
    type: ScheduledTaskType,
  ): Promise<number> {
    const collection = firebaseCollection(
      this.firestore.firestore,
      `dietitians/${dietitianId}/scheduled_tasks`,
    );
    const q = query(
      collection,
      where('status', '==', EncouragementStatus.TO_PROCESS),
      where('type', '==', type),
    );
    const countResult = await getCountFromServer(q);
    return countResult.data().count;
  }

  async countScheduledTaskCountByDietitianIdAndGroupId(
    dietitianId: string,
    groupId: string,
  ): Promise<number> {
    const collection = firebaseCollection(
      this.firestore.firestore,
      `dietitians/${dietitianId}/scheduled_tasks`,
    );
    const q = query(
      collection,
      where('status', '==', EncouragementStatus.TO_PROCESS),
      where('target.groupId', '==', groupId),
    );
    const countResult = await getCountFromServer(q);
    return countResult.data().count;
  }

  async countScheduledTaskCountByDietitianIdAndGroupIdAndType(
    dietitianId: string,
    groupId: string,
    type: ScheduledTaskType,
  ): Promise<number> {
    const collection = firebaseCollection(
      this.firestore.firestore,
      `dietitians/${dietitianId}/scheduled_tasks`,
    );
    const q = query(
      collection,
      where('status', '==', EncouragementStatus.TO_PROCESS),
      where('target.groupId', '==', groupId),
      where('type', '==', type),
    );
    const countResult = await getCountFromServer(q);
    return countResult.data().count;
  }

  private getContentFromSchema(
    schema: ScheduledTaskSchema,
  ): Conversation | SharedDocument | Template | undefined {
    if (schema.type === ScheduledTaskType.DOCUMENT) {
      return this.sharedDocumentRepository.fromSchema(
        schema.content as SharedDocumentSchema,
      );
    } else if (schema.type === ScheduledTaskType.MESSAGE) {
      return this.conversationRepository.fromSchema(
        schema.content as ConversationSchema,
      );
    } else if (schema.type === ScheduledTaskType.SURVEY) {
      return this.templateRepository.fromSchema(
        schema.content as TemplateSchema,
        schema.dietitianId,
      );
    }
    return undefined;
  }

  private getSubContentFromSchema(
    schema: ScheduledTaskSchema,
  ): Message | undefined {
    if (schema.subContent) {
      if (schema.type === ScheduledTaskType.MESSAGE) {
        return this.messageRepository.fromSchema(
          schema.subContent as MessageSchema,
        );
      }
    }
    return undefined;
  }

  private getContentToSchema(
    scheduledTask: ScheduledTask,
  ): ConversationSchema | SharedDocumentSchema | TemplateSchema | undefined {
    if (scheduledTask.type === ScheduledTaskType.DOCUMENT) {
      return this.sharedDocumentRepository.toSchema(
        scheduledTask.content as SharedDocument,
      );
    } else if (scheduledTask.type === ScheduledTaskType.MESSAGE) {
      return this.conversationRepository.toSchema(
        scheduledTask.content as Conversation,
      );
    } else if (scheduledTask.type === ScheduledTaskType.SURVEY) {
      return this.templateRepository.toSchema(
        scheduledTask.content as Template,
      );
    }
    return undefined;
  }

  private getSubContentToSchema(
    scheduledTask: ScheduledTask,
  ): MessageSchema | string | null {
    if (scheduledTask.subContent) {
      if (scheduledTask.type === ScheduledTaskType.MESSAGE) {
        return this.messageRepository.toSchema(
          scheduledTask.subContent as Message,
        );
      } else if (scheduledTask.type === ScheduledTaskType.SURVEY) {
        return scheduledTask.subContent as string;
      }
    }
    return null;
  }

  async getScheduledTasks(
    dietitianId: string,
    type: ScheduledTaskType,
    groupId: string | undefined,
  ): Promise<ScheduledTask[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) => {
        let query = ref
          .where('status', '==', ScheduledTaskStatus.TO_PROCESS)
          .where('type', '==', type)
          .orderBy('scheduledAt', 'desc');

        if (groupId != undefined) {
          query = query.where('target.groupId', '==', groupId);
        }

        return query;
      }).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async deleteScheduledTask(dietitianId: string, scheduledTaskId: string) {
    return this.collection(dietitianId).doc(scheduledTaskId).delete();
  }
}
