import { LogLevel } from '../app/app.service';

export const environment = {
  production: true,
  test: false,
  firebase: {
    apiKey: 'AIzaSyC9s1P4sB7GVElZt4-i4h3EMqqcC4kbpWI',
    authDomain: 'monsuividiet-prod-v2.firebaseapp.com',
    projectId: 'monsuividiet-prod-v2',
    storageBucket: 'monsuividiet-prod-v2.appspot.com',
    messagingSenderId: '907328249742',
    appId: '1:907328249742:web:24fb3bc21433aa0ee90edf',
    measurementId: 'G-YDC1BX79D2',
  },
  firebaseFunctionsUrl:
    'https://europe-west1-monsuividiet-prod-v2.cloudfunctions.net/',
  monthlySubscription: {
    price: 'price_1LFuqkI0uyVAlrPMwKIVzmTA',
    product: 'prod_LxqXGN0pm8iwMc',
  },
  yearlySubscription: {
    price: 'price_1NDogYI0uyVAlrPMax43yFzk',
    product: 'prod_LxqXGN0pm8iwMc',
  },
  monSuiviDietPlus: {
    urlApi: 'https://monsuividietplus.com/api/1.1/wf',
  },
  verbosity: LogLevel.warn,
};
