import { Injectable } from '@angular/core';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { PatientSerieRepository } from '../../repositories/patient_serie_repository';
import { PatientSerieEventProvider } from '../events/patient_serie/patient_serie_event_provider';
import {
  PatientSerieItemCreated,
  PatientSerieItemDeleted,
  PatientSerieItemUpdated,
} from '../events/patient_serie/patient_serie_events';
import { PatientSerie } from './patient_serie';
import { PatientSerieItem, PatientSerieItemProps } from './patient_serie_item';

@Injectable()
export class PatientSerieCommands {
  constructor(
    private repository: PatientSerieRepository,
    private eventProvider: PatientSerieEventProvider,
  ) {
    // do nothing
  }

  async getSerieItem(
    patientId: string,
    serieItemId: string,
  ): Promise<PatientSerieItem> {
    return this.repository.loadItem(patientId, serieItemId);
  }

  async getSerieItems(
    patientId: string,
    start: Date,
    end: Date,
    serie?: PatientSerie,
  ): Promise<PatientSerieItem[]> {
    return this.repository.findItems(patientId, start, end, serie);
  }

  async getSerieWeightAndSizeItems(
    patientId: string,
    mode: 'weight' | 'size' | 'weightAndSize',
  ): Promise<PatientSerieItem[] | undefined> {
    switch (mode) {
      case 'weight':
        return this.repository.findAllItems(patientId, PatientSerie.Weight);
      case 'size':
        return this.repository.findAllItems(patientId, PatientSerie.Size);
      default: {
        const weightItems = await this.repository.findAllItems(
          patientId,
          PatientSerie.Weight,
        );
        const sizeItems = await this.repository.findAllItems(
          patientId,
          PatientSerie.Size,
        );
        const result = weightItems?.concat(sizeItems ? sizeItems : []);
        return Promise.resolve(
          result ? result.sort(this.sortSerieItem) : undefined,
        );
      }
    }
  }

  private sortSerieItem(
    a: PatientSerieItem | undefined,
    b: PatientSerieItem | undefined,
  ): number {
    if ((a?.timestamp ?? -1) > (b?.timestamp ?? -1)) {
      return -1;
    } else if ((a?.timestamp ?? -1) < (b?.timestamp ?? -1)) {
      return 1;
    } else {
      return 0;
    }
  }

  async getLastSerieItem(
    patientId: string,
    serie: PatientSerie,
  ): Promise<PatientSerieItem | undefined> {
    return this.repository.findLastItem(patientId, serie);
  }

  async saveSerieItem(
    serieItemProps: PatientSerieItemProps,
    serieItemId?: string,
  ): Promise<PatientSerieItem> {
    let serieItem = PatientSerieItem.create(
      serieItemProps,
      new UniqueEntityID(serieItemId),
    );
    if (serieItemId !== undefined) {
      serieItem = await this.repository.saveItem(serieItem);
      this.eventProvider.dispatch(new PatientSerieItemUpdated(serieItem));
    } else {
      serieItem = await this.repository.createItem(serieItem);
      this.eventProvider.dispatch(new PatientSerieItemCreated(serieItem));
    }
    return serieItem;
  }

  async deleteSerieItem(
    patientId: string,
    serieItemId: string,
  ): Promise<PatientSerieItem> {
    const serieItem = await this.repository.loadItem(patientId, serieItemId);
    await this.repository.deleteItem(patientId, serieItemId);
    this.eventProvider.dispatch(new PatientSerieItemDeleted(serieItem));
    return serieItem;
  }

  async updatePatientSerieItem(
    serie: PatientSerieItem,
  ): Promise<PatientSerieItem> {
    serie = await this.repository.saveItem(serie);
    this.eventProvider.dispatch(new PatientSerieItemUpdated(serie));
    return Promise.resolve(serie);
  }
}
