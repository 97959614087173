import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { firstValueFrom, Observable } from 'rxjs';

import { GenericException } from '../../../core/logic/exception';

@Injectable()
export class LoggerRepository {
  private readonly loggerInsert: (data: unknown) => Observable<boolean>;
  private _who: string;

  set who(value: string) {
    this._who = value ?? 'Inconnu';
  }

  get who(): string {
    return this._who;
  }

  constructor(private functions: AngularFireFunctions) {
    this.loggerInsert = this.functions.httpsCallable<unknown, boolean>(
      'logger-insert',
    );
  }

  async debug(
    location: string,
    message: string,
    trace?: unknown | undefined,
  ): Promise<void> {
    try {
      await firstValueFrom(
        this.loggerInsert({
          gravity: 'DEBUG',
          location,
          message,
          who: this.who,
          trace: JSON.stringify(trace),
        }),
      );
    } catch (error) {
      throw new GenericException(error);
    }
  }

  async info(
    location: string,
    message: string,
    trace?: unknown | undefined,
  ): Promise<void> {
    try {
      await firstValueFrom(
        this.loggerInsert({
          gravity: 'INFO',
          location,
          message,
          who: this.who,
          trace: JSON.stringify(trace),
        }),
      );
    } catch (error) {
      throw new GenericException(error);
    }
  }

  async warn(
    location: string,
    message: string,
    trace?: unknown | undefined,
  ): Promise<void> {
    try {
      await firstValueFrom(
        this.loggerInsert({
          gravity: 'WARN',
          location,
          message,
          who: this.who,
          trace: JSON.stringify(trace),
        }),
      );
    } catch (error) {
      throw new GenericException(error);
    }
  }

  async error(
    location: string,
    message: string,
    trace?: unknown | undefined,
  ): Promise<void> {
    try {
      await firstValueFrom(
        this.loggerInsert({
          gravity: 'ERROR',
          location,
          message,
          who: this.who,
          trace: JSON.stringify(trace),
        }),
      );
    } catch (error) {
      throw new GenericException(error);
    }
  }
}
