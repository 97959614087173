import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../environments/environment';

export interface Logger {
  level: LogLevel;
  debug(message: string, o?: unknown): void;
  info(message: string, o?: unknown): void;
  warn(message: string, o?: unknown): void;
  error(message: string, o?: unknown): void;
}

export enum LogLevel {
  debug = 0,
  info = 1,
  warn = 2,
  error = 3,
}

export class CustomLogger implements Logger {
  level: LogLevel;

  constructor(verbosity: LogLevel) {
    this.level = verbosity;
  }

  debug(message: string, o?: unknown): void {
    this.trace(LogLevel.debug, message, o);
  }

  error(message: string, o?: unknown): void {
    this.trace(LogLevel.error, message, o);
  }

  info(message: string, o?: unknown): void {
    this.trace(LogLevel.info, message, o);
  }

  warn(message: string, o?: unknown): void {
    this.trace(LogLevel.warn, message, o);
  }

  trace(gravity: LogLevel, message: string, o?: unknown): void {
    if (gravity >= length) {
      switch (gravity) {
        case LogLevel.debug:
          console.log('👀 DEBUG - ' + message, o);
          break;
        case LogLevel.error:
          console.log('🔴 ERROR - ' + message, o);
          break;
        case LogLevel.warn:
          console.log('🟠 WARN - ' + message, o);
          break;
        case LogLevel.info:
          console.log('ℹ️ INFO - ' + message, o);
      }
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _bannerDisplayed = new BehaviorSubject(false);

  constructor() {
    // noting
  }

  public get bannerDisplayedValue() {
    return this._bannerDisplayed.getValue();
  }

  public setBannerDisplayed(value: boolean) {
    this._bannerDisplayed.next(value);
  }

  public get logger(): Logger {
    return new CustomLogger(environment.verbosity);
  }
}
