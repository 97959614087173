import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CompositionCommands } from './domain/composition/composition-commands';
import { CompositionEventProvider } from './domain/events/composition/composition-event-provider';
import { ToastrModule } from 'ngx-toastr';
import { CompositionRepository } from './repositories/composition-repository';

@NgModule({
  declarations: [],
  imports: [CommonModule, ToastrModule.forRoot()],
  providers: [
    CompositionRepository,
    CompositionEventProvider,
    CompositionCommands,
  ],
})
export class CompositionModule {
  static forRoot(): ModuleWithProviders<CompositionModule> {
    return {
      ngModule: CompositionModule,
      providers: [
        {
          provide: CompositionEventProvider,
        },
        {
          provide: CompositionCommands,
        },
      ],
    };
  }
}
