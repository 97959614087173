import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { LoadingStatus } from '../../../../core/domain/events/state_provider';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { UnAuthorizedException } from '../../../../core/logic/exception';
import { PatientStateProvider } from '../../../patient/domain/patient_state_provider';
import { MemoRepository } from '../../repositories/memo_repository';
import { DietitianStateProvider } from '../dietitian_state_provider';
import { MemoEventProvider } from '../events/memo/memo_event_provider';
import { MemoCreated, MemoUpdated } from '../events/memo/memo_events';
import { Memo, MemoProps } from './memo';
import { MemoState, MemoStateProvider } from './memo_state_provider';

@Injectable()
export class MemoCommands {
  constructor(
    private repository: MemoRepository,
    private stateProvider: MemoStateProvider,
    private eventProvider: MemoEventProvider,
    private dietitianStateProvider: DietitianStateProvider,
    private patientStateProvider: PatientStateProvider,
    private toastr: ToastrService,
  ) {
    // this.eventProvider.events$.subscribe((event) => {
    //   if (event instanceof MemoCreated) {
    //     this.toastr.success('Memo créé');
    //   } else if (event instanceof MemoUpdated) {
    //     this.toastr.success('Memo mis à jour');
    //   }
    // });
  }

  async load(): Promise<Memo | undefined> {
    this.stateProvider.setState(<MemoState>{
      loading: LoadingStatus.LOADING,
      data: this.stateProvider.state.data,
    });
    try {
      if (
        this.dietitianStateProvider.state.entity === undefined ||
        this.patientStateProvider.state.entity === undefined
      ) {
        throw new UnAuthorizedException();
      }
      const memo = await this.repository.load(
        this.dietitianStateProvider.state.entity.id.toString(),
        this.patientStateProvider.state.entity.id.toString(),
      );
      this.stateProvider.setState(<MemoState>{
        loading: LoadingStatus.COMPLETE,
        data: memo,
      });
      return memo;
    } catch (error) {
      this.stateProvider.setState(<MemoState>{
        loading: LoadingStatus.COMPLETE,
      });
      return undefined;
    }
  }

  async saveMemo(
    dietitianId: string,
    props: MemoProps,
    memoId?: string,
  ): Promise<Memo> {
    let memo = Memo.create(props, new UniqueEntityID(memoId));

    if (memoId !== undefined) {
      memo = await this.repository.save(dietitianId, memo);
      this.eventProvider.dispatch(new MemoUpdated(memo));
    } else {
      memo = await this.repository.create(dietitianId, memo);
      this.eventProvider.dispatch(new MemoCreated(memo));
    }
    return memo;
  }
}
