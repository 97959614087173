import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { SharedDocumentId } from '../../../document/domain/shared_document/shared_document';
import { Template } from '../templates/template';
import { TemplateField } from '../templates/template_field';
import { SurveyField } from './survey-field';

export interface SurveyProps {
  dietitianId: string;
  patientId: string;
  patientUserId?: string;
  patientUserWebId?: string;
  templateId: string;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  fields: SurveyField[];
  token?: string;
  sharedDocumentId?: SharedDocumentId | undefined;
  sharedAt?: Date | undefined;
}

export class SurveyId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): SurveyId {
    return new SurveyId(id);
  }
}

export class Survey extends Entity<SurveyProps> {
  get surveyId(): SurveyId {
    return SurveyId.create(this.id);
  }

  get dietitianId(): string {
    return this.props.dietitianId;
  }

  get patientId(): string {
    return this.props.patientId;
  }

  get templateId(): string {
    return this.props.templateId;
  }

  get patientUserId(): string | undefined {
    return this.props.patientUserId;
  }

  get patientUserWebId(): string | undefined {
    return this.props.patientUserWebId;
  }

  get name(): string {
    return this.props.name;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get fields(): SurveyField[] {
    return this.props.fields;
  }

  get sharedDocumentId(): SharedDocumentId | undefined {
    return this.props.sharedDocumentId;
  }

  set sharedDocumentId(value: SharedDocumentId | undefined) {
    this.props.sharedDocumentId = value;
  }

  get sharedAt(): Date | undefined {
    return this.props.sharedAt;
  }

  set sharedAt(value: Date | undefined) {
    this.props.sharedAt = value;
  }

  private constructor(props: SurveyProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: SurveyProps): Survey {
    return Survey.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  patch(template: Template): Survey {
    const templateFields: { [key: string]: TemplateField } = {};
    const reversedTemplateFields: { [key: string]: SurveyField } = {};
    for (const field of template.fields) {
      templateFields[field.id.toString()] = field;
    }
    for (const field of this.fields) {
      reversedTemplateFields[field.templateFieldId.id.toString()] = field;
    }

    // add missing fields
    let patchedFields = this.fields ?? [];
    for (const field of template.fields) {
      if (reversedTemplateFields[field.id.toString()]) continue;

      patchedFields.push(
        SurveyField.create({
          surveyId: this.surveyId,
          templateFieldId: field.templateFieldId,
          label: field.label,
          type: field.type,
          params: field.params,
          value: null,
          order: field.order,
        }),
      );
    }

    patchedFields = this.fields
      .map((field) => {
        const tf = templateFields[field.templateFieldId.id.toString()];
        return tf ? field.patch(tf) : field;
      })
      .sort((a, b) => a.order - b.order);

    return this.copyWith({
      templateId: template.id.toString(),
      name: template.name,
      fields: patchedFields,
    } as SurveyProps);
  }

  updateFields(fields: SurveyField[]): Survey {
    return this.copyWith({
      fields,
    } as SurveyProps);
  }

  updateFieldValues(values: { [key: string]: unknown }): Survey {
    return this.copyWith({
      fields: this.fields.map((f) => {
        return f.updateValue(values[f.id.toString()] ?? f.value);
      }),
    } as SurveyProps);
  }

  public static create(props: SurveyProps, id?: UniqueEntityID): Survey {
    return new Survey(props, id);
  }
}
