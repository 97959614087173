import { Injectable } from '@angular/core';
import EntityStateProvider, {
  EntityState,
} from 'src/app/core/domain/events/state_provider';

import { Dietitian } from './dietitian';

export type DietitianState = EntityState<Dietitian>;

@Injectable({ providedIn: 'root' })
export class DietitianStateProvider extends EntityStateProvider<DietitianState> {}
