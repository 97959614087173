import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from './ui/authentication/authentication.guard';
import { DashboardGuard } from './ui/dashboard/dashboard.guard';
import { DocumentGuard } from './ui/document/document.guard';
import { DocumentIdGuard } from './ui/document/document-id.guard';
import { NutritionGuard } from './ui/nutrition/nutrition.guard';
import { NutritionIdGuard } from './ui/nutrition/nutrition-id.guard';
import { PatientGuard } from './ui/patient/patient.guard';
import { SurveyGuard } from './ui/survey/survey.guard';
import { TemplateGuard } from './ui/template/template.guard';
import { TemplateIdGuard } from './ui/template/template-id.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./ui/authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('./ui/unsubscribe/unsubscribe.module').then(
        (m) => m.UnsubscribeModule,
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./ui/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [DashboardGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'patient/:patientId',
    loadChildren: () =>
      import('./ui/patient/patient.module').then((m) => m.PatientModule),
    canActivate: [PatientGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'template',
    loadChildren: () =>
      import('./ui/template/template.module').then((m) => m.TemplateModule),
    canActivate: [TemplateGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'template/:templateId',
    loadChildren: () =>
      import('./ui/template/template.module').then((m) => m.TemplateModule),
    canActivate: [TemplateIdGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'document',
    loadChildren: () =>
      import('./ui/document/document.module').then((m) => m.DocumentModule),
    canActivate: [DocumentGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'document/:patientId/:documentId',
    loadChildren: () =>
      import('./ui/document/document.module').then((m) => m.DocumentModule),
    canActivate: [DocumentIdGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'survey',
    loadChildren: () =>
      import('./ui/survey/survey.module').then((m) => m.SurveyModule),
    canActivate: [SurveyGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'nutrition',
    loadChildren: () =>
      import('./ui/nutrition/nutrition.module').then((m) => m.NutritionModule),
    canActivate: [NutritionGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'nutrition/:nutritionId',
    loadChildren: () =>
      import('./ui/nutrition/nutrition.module').then((m) => m.NutritionModule),
    canActivate: [NutritionIdGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
