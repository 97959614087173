import { BehaviorSubject, Observable } from 'rxjs';

import Entity from '../entity';

export enum LoadingStatus {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  COMPLETE = 'COMPLETE',
}

export interface EntityState<T extends Entity<unknown>> extends State<T> {
  readonly entity?: T;
}

export interface State<T> {
  readonly data?: T;
  readonly loading: LoadingStatus;
  readonly message?: string;
  readonly origin?: string;
}

export default abstract class StateProvider<T extends State<unknown>> {
  private $state: T = {
    loading: LoadingStatus.LOADING,
  } as T;
  private stateSubject = new BehaviorSubject<T>(this.$state);

  get state(): T {
    return this.$state;
  }

  get state$(): Observable<T> {
    return this.stateSubject.asObservable();
  }

  setState(state: T) {
    this.$state = state;
    this.stateSubject.next(state);
  }
}

export abstract class EntityStateProvider<
  T extends EntityState<Entity<unknown>>,
> extends StateProvider<T> {}
