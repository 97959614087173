import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../../environments/environment';
import UniqueEntityID from '../../../core/domain/unique_entity_id';
import {
  GenericException,
  UnAuthorizedException,
} from '../../../core/logic/exception';
import { Dietitian, DietitianId } from '../../dietitian/domain/dietitian';
import {
  Nutrition,
  NutritionProps,
} from '../../dietitian/domain/nutritions/nutrition';
import {
  NutritionGoalRepository,
  NutritionGoalSchema,
} from '../../dietitian/repositories/nutrition-goal-repository';
import {
  NutritionMealRepository,
  NutritionMealSchema,
} from '../../dietitian/repositories/nutrition-meal-repository';
import { NutritionRepository } from '../../dietitian/repositories/nutrition-repository';
import { SharedDocumentId } from '../../document/domain/shared_document/shared_document';
import { CustomNutrition } from '../domain/custom-nutrition/custom-nutrition';
import { CustomNutritionNotFoundException } from '../domain/custom-nutrition/custom-nutrition-exceptions';
import { Gender, Patient } from '../domain/patient';

export interface CustomNutritionSchema {
  dietitianId: string;
  nutritionId: string;

  name: string;

  customGender: Gender | null;
  customAge: number | null;
  customSize: number | null;
  customWeight: number | null;
  customNAP: number | null;
  customBMR: number | null;

  customTargetedEnergy: number;
  customProteins: number;
  customLipids: number;
  customGlucids: number;
  customFibers: number | null;

  customGoals: NutritionGoalSchema[];
  customMeals: NutritionMealSchema[];

  customComment: string | null;

  withQuantity: boolean | null;

  createdAt: Timestamp;
  updatedAt: Timestamp;

  sharedDocumentId: string | undefined;
  sharedAt: Timestamp | undefined;
}

@Injectable()
export class CustomNutritionRepository {
  constructor(
    private firestore: AngularFirestore,
    private fireAuth: AngularFireAuth,
    private http: HttpClient,
    private nutritionRepository: NutritionRepository,
    private nutritionGoalRepository: NutritionGoalRepository,
    private nutritionMealRepository: NutritionMealRepository,
  ) {
    // NaDa
  }

  private collection(patientId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('patients')
      .doc(patientId)
      .collection<CustomNutritionSchema>('custom_nutritions', queryFn);
  }

  toSchema(customNutrition: CustomNutrition): CustomNutritionSchema {
    return <CustomNutritionSchema>{
      dietitianId: customNutrition.nutrition.dietitianId?.id.toString() ?? null,
      nutritionId: customNutrition.nutrition.id.toString(),
      name: customNutrition.name,
      customGender: customNutrition.customGender ?? null,
      customAge: customNutrition.customAge ?? null,
      customSize: customNutrition.customSize ?? null,
      customWeight: customNutrition.customWeight ?? null,
      customNAP: customNutrition.customNAP ?? null,
      customBMR: customNutrition.customBMR ?? null,
      customTargetedEnergy: customNutrition.customTargetedEnergy,
      customProteins: customNutrition.customProteins,
      customLipids: customNutrition.customLipids,
      customGlucids: customNutrition.customGlucids,
      customFibers: customNutrition.customFibers ?? null,
      customComment: customNutrition.customComment ?? null,
      withQuantity: customNutrition.withQuantity ?? null,
      createdAt:
        customNutrition.createdAt !== undefined
          ? Timestamp.fromDate(customNutrition.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      customGoals: customNutrition.customGoals
        ? customNutrition.customGoals.map((g) =>
            this.nutritionGoalRepository.toSchema(g),
          )
        : null,
      customMeals: customNutrition.customMeals
        ? customNutrition.customMeals.map((g) =>
            this.nutritionMealRepository.toSchema(g),
          )
        : null,
      sharedDocumentId: customNutrition.sharedDocumentId?.id.toString() ?? null,
      sharedAt:
        customNutrition.sharedAt !== undefined
          ? Timestamp.fromDate(customNutrition.sharedAt)
          : null,
    };
  }

  fromSchema(
    schema: CustomNutritionSchema,
    patient: Patient,
    nutrition: Nutrition,
    id?: string | undefined,
  ): CustomNutrition {
    return CustomNutrition.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(schema.dietitianId)),
        nutrition,
        patient,
        name: schema.name,
        customGender: schema.customGender ?? undefined,
        customAge: schema.customAge ?? undefined,
        customSize: schema.customSize ?? undefined,
        customWeight: schema.customWeight ?? undefined,
        customNAP: schema.customNAP ?? undefined,
        customBMR: schema.customBMR ?? undefined,
        customTargetedEnergy: schema.customTargetedEnergy,
        customProteins: schema.customProteins,
        customLipids: schema.customLipids,
        customGlucids: schema.customGlucids,
        customFibers: schema.customFibers ?? undefined,
        customGoals: schema.customGoals
          ? schema.customGoals.map((g) =>
              this.nutritionGoalRepository.fromSchema(g),
            )
          : [],
        customMeals: schema.customMeals
          ? schema.customMeals.map((m) =>
              this.nutritionMealRepository.fromSchema(m),
            )
          : [],
        customComment: schema.customComment ?? undefined,
        withQuantity: schema.withQuantity ?? undefined,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        sharedDocumentId: schema.sharedDocumentId
          ? SharedDocumentId.create(new UniqueEntityID(schema.sharedDocumentId))
          : undefined,
        sharedAt: schema.sharedAt?.toDate() ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async load(
    patient: Patient,
    customNutritionId: string,
    dietitianId: string,
  ): Promise<CustomNutrition> {
    const snap = await firstValueFrom(
      this.collection(patient.id.toString()).doc(customNutritionId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new CustomNutritionNotFoundException();
    }
    const schema = snap.data() as CustomNutritionSchema;
    const nutrition = await this.nutritionRepository.load(
      dietitianId,
      schema.nutritionId,
    );
    return this.fromSchema(schema, patient, nutrition, snap.id);
  }

  async findAll(patient: Patient): Promise<CustomNutrition[]> {
    const snap = await firstValueFrom(
      this.collection(patient.id.toString()).get(),
    );

    const result: CustomNutrition[] = [];
    for (const doc of snap.docs) {
      const schema = doc.data() as CustomNutritionSchema;
      result.push(
        this.fromSchema(
          schema,
          patient,
          Nutrition.create(
            {} as NutritionProps,
            new UniqueEntityID(schema.nutritionId),
          ),
          doc.id,
        ),
      );
    }
    return Promise.resolve(result);
  }

  async create(customNutrition: CustomNutrition): Promise<CustomNutrition> {
    const schema = this.toSchema(customNutrition);
    if (customNutrition.patient.id) {
      const ref = await this.collection(
        customNutrition.patient.id.toString(),
      ).add(schema);
      return this.fromSchema(
        schema,
        customNutrition.patient,
        customNutrition.nutrition,
        ref.id,
      );
    } else {
      return Promise.reject('Patient non identifié');
    }
  }

  async save(customNutrition: CustomNutrition): Promise<CustomNutrition> {
    const schema = this.toSchema(customNutrition);
    if (customNutrition.patient.id) {
      const patientId = customNutrition.patient.id.toString();
      await this.collection(patientId)
        .doc(customNutrition.id.toString())
        .set(schema);
      return this.fromSchema(
        schema,
        customNutrition.patient,
        customNutrition.nutrition,
        customNutrition.id.toString(),
      );
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  delete(patientId: string, customNutritionId: string): Promise<void> {
    return this.collection(patientId).doc(customNutritionId).delete();
  }

  async generatePdf(
    patientId: string,
    customNutritionId: string,
  ): Promise<Blob> {
    const currentUser = await this.fireAuth.currentUser;
    const token = currentUser ? await currentUser.getIdToken() : undefined;
    if (!token) {
      throw new UnAuthorizedException();
    }

    let result: HttpResponse<Blob>;
    try {
      result = await firstValueFrom(
        this.http.post(
          environment.firebaseFunctionsUrl +
            'nutrition-generateCustomNutritionPdf',
          {
            patientId,
            customNutritionId,
          },
          {
            headers: new HttpHeaders({
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: 'application/pdf, application/json',
            }),
            observe: 'response',
            responseType: 'blob',
          },
        ),
      );
    } catch (error: unknown) {
      if (
        error instanceof HttpErrorResponse &&
        error.error instanceof Blob &&
        error.error.type == 'application/json'
      ) {
        const json = JSON.parse(await error.error.text());
        throw new GenericException(json.message);
      }
      throw new GenericException(error);
    }

    if (!result.ok) {
      throw new GenericException(result.statusText);
    }

    if (!result.body) {
      throw new GenericException();
    }

    if (result.body.type == 'application/pdf') {
      return result.body;
    }

    if (result.body.type == 'application/json') {
      const json = JSON.parse(await result.body.text());
      throw new GenericException(json.message);
    }

    throw new GenericException();
  }

  async duplicate(
    customNutrition: CustomNutrition,
    dietitian: Dietitian,
  ): Promise<CustomNutrition> {
    const schema = this.toSchema(customNutrition);
    const duplicatedCustomNutrition = this.fromSchema(
      schema,
      customNutrition.patient,
      customNutrition.nutrition,
    );
    duplicatedCustomNutrition.nutrition.dietitianId = dietitian.dietitianId;
    duplicatedCustomNutrition.name =
      'Copie de ' + duplicatedCustomNutrition.name;
    return this.create(duplicatedCustomNutrition);
  }
}
