import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { UserId } from 'src/app/data/auth/domain/user';

import { PatientId } from '../patient';

export interface EncouragementProps {
  patientId: PatientId;
  patientUserId?: UserId;
  createdAt?: Date;
  updatedAt?: Date;
  message: string;
  reaction?: string;
}

export class EncouragementId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): EncouragementId {
    return new EncouragementId(id);
  }
}

export class Encouragement extends Entity<EncouragementProps> {
  get encouragementId(): EncouragementId {
    return EncouragementId.create(this.id);
  }

  get patientId(): PatientId {
    return this.props.patientId;
  }

  get patientUserId(): UserId | undefined {
    return this.props.patientUserId;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get message(): string {
    return this.props.message;
  }

  get reaction(): string | undefined {
    return this.props.reaction;
  }

  private constructor(props: EncouragementProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: EncouragementProps): Encouragement {
    return Encouragement.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: EncouragementProps,
    id?: UniqueEntityID,
  ): Encouragement {
    return new Encouragement(props, id);
  }
}
