import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { DietitianId } from '../dietitian';
import { NutritionGoal } from './nutrition-goal';
import { NutritionMeal } from './nutrition-meal';

export interface NutritionProps {
  dietitianId?: DietitianId;
  name: string;
  description?: string;
  targetedEnergyIntake: number;
  proteins: number;
  lipids: number;
  glucids: number;
  fibers: number | undefined;
  goals: NutritionGoal[];
  meals: NutritionMeal[];
  header: string;
  sign: string;
  createdAt?: Date;
  updatedAt?: Date;
  archivedAt?: Date;
}

export class NutritionId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): NutritionId {
    return new NutritionId(id);
  }
}

export class Nutrition extends Entity<NutritionProps> {
  get nutritionId(): NutritionId {
    return NutritionId.create(this.id);
  }

  get dietitianId(): DietitianId | undefined {
    return this.props.dietitianId ?? undefined;
  }

  set dietitianId(v: DietitianId | undefined) {
    this.props.dietitianId = v;
  }

  get name(): string {
    return this.props.name;
  }

  set name(v: string) {
    this.props.name = v;
  }

  get description(): string | undefined {
    return this.props.description;
  }

  set description(v: string | undefined) {
    this.props.description = v;
  }

  get targetedEnergyIntake(): number {
    return this.props.targetedEnergyIntake;
  }

  set targetedEnergyIntake(v: number) {
    this.props.targetedEnergyIntake = v;
  }

  get proteins(): number {
    return this.props.proteins;
  }

  set proteins(v: number) {
    this.props.proteins = v;
  }

  get lipids(): number {
    return this.props.lipids;
  }

  set lipids(v: number) {
    this.props.lipids = v;
  }

  get glucids(): number {
    return this.props.glucids;
  }

  set glucids(v: number) {
    this.props.glucids = v;
  }

  get fibers(): number | undefined {
    return this.props.fibers;
  }

  set fibers(v: number | undefined) {
    this.props.fibers = v;
  }

  get goals(): NutritionGoal[] {
    return this.props.goals;
  }

  set goals(v: NutritionGoal[]) {
    this.props.goals = v;
  }

  get meals(): NutritionMeal[] {
    return this.props.meals;
  }

  set meals(v: NutritionMeal[]) {
    this.props.meals = v;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get archivedAt(): Date | undefined {
    return this.props.archivedAt;
  }

  get archived(): boolean {
    return this.props.archivedAt != null;
  }

  get header(): string {
    return this.props.header;
  }

  set header(v: string) {
    this.props.header = v;
  }

  get sign(): string {
    return this.props.sign;
  }

  set sign(v: string) {
    this.props.sign = v;
  }

  private constructor(props: NutritionProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: NutritionProps): Nutrition {
    return Nutrition.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  archive(): Nutrition {
    return this.copyWith({ archivedAt: new Date() } as NutritionProps);
  }

  restore(): Nutrition {
    return this.copyWith({ archivedAt: undefined } as NutritionProps);
  }

  public static create(props: NutritionProps, id?: UniqueEntityID): Nutrition {
    return new Nutrition(props, id);
  }
}
