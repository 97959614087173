import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { NutritionComponentId } from './nutrition-component';
import { NutritionUnit } from './nutrition-quantity-unit';

export interface NutritionGoalProps {
  componentId: NutritionComponentId;
  value: number | undefined;
  unit: NutritionUnit | undefined;
  createdAt?: Date;
  updatedAt?: Date;
}

export class NutritionGoal extends Entity<NutritionGoalProps> {
  private constructor(props: NutritionGoalProps, id?: UniqueEntityID) {
    super(props, id);
  }

  get componentId(): NutritionComponentId {
    return this.props.componentId;
  }

  get value(): number | undefined {
    return this.props.value;
  }

  set value(v: number | undefined) {
    this.props.value = v;
  }

  get unit(): NutritionUnit | undefined {
    return this.props.unit;
  }

  set unit(v: NutritionUnit | undefined) {
    this.props.unit = v;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  public static create(
    props: NutritionGoalProps,
    id?: UniqueEntityID,
  ): NutritionGoal {
    return new NutritionGoal(props, id);
  }

  copyWith(props: NutritionGoalProps): NutritionGoal {
    return NutritionGoal.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }
}
