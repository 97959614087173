import { DomainEvent } from '../../../../../core/domain/events/domain_event';
import { Nutrition } from '../../nutritions/nutrition';
import { NutritionIngredient } from '../../nutritions/nutrition-ingredient';

export class NutritionEvent extends DomainEvent<Nutrition> {}

export class NutritionCreated extends NutritionEvent {}

export class NutritionSaved extends NutritionEvent {}

export class NutritionArchived extends NutritionEvent {}

export class NutritionRestored extends NutritionEvent {}

export class NutritionIngredientEvent extends DomainEvent<NutritionIngredient> {}

export class NutritionIngredientCreated extends NutritionIngredientEvent {}

export class NutritionIngredientUpdated extends NutritionIngredientEvent {}

export class NutritionIngredientRenamed extends NutritionIngredientEvent {}
