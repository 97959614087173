import { Injectable } from '@angular/core';

import {
  EntityState,
  EntityStateProvider,
} from '../../../../core/domain/events/state_provider';
import { Note } from './note';

export type NoteState = EntityState<Note>;

@Injectable({ providedIn: 'root' })
export class NoteStateProvider extends EntityStateProvider<NoteState> {}
