import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { LoadingStatus } from '../../../../core/domain/events/state_provider';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { AuthenticationCommands } from '../../../auth/domain/authentication_commands';
import {
  PatientSignedIn,
  PatientSignedOut,
} from '../../../patient/domain/events/patient.events';
import { PatientEventProvider } from '../../../patient/domain/events/patient_event_provider';
import { Patient, PatientId } from '../../../patient/domain/patient';
import { PatientCommands } from '../../../patient/domain/patient_commands';
import { PatientSerieCommands } from '../../../patient/domain/patient_serie/patient_serie_commands';
import {
  PatientState,
  PatientStateProvider,
} from '../../../patient/domain/patient_state_provider';
import { SurveyRepository } from '../../repositories/survey-repository';
import { SurveyEventProvider } from '../events/survey/survey_event_provider';
import {
  SurveyCreated,
  SurveyDeleted,
  SurveyUpdated,
} from '../events/survey/survey_events';
import { PatientGroup } from '../patient_group/patient_group';
import { Template } from '../templates/template';
import { TemplateCommands } from '../templates/template_commands';
import { Survey, SurveyProps } from './survey';
import { SurveyField } from './survey-field';
import { SurveyStateProvider } from './survey-state-provider';

@Injectable()
export class SurveyCommands {
  constructor(
    private repository: SurveyRepository,
    private stateProvider: SurveyStateProvider,
    private eventProvider: SurveyEventProvider,
    private templateCommands: TemplateCommands,
    private patientCommands: PatientCommands,
    private patientSerieCommands: PatientSerieCommands,
    private authCommands: AuthenticationCommands,
    private patientStateProvider: PatientStateProvider,
    private patientEventProvider: PatientEventProvider,
  ) {
    // Nada
  }

  async getSurvey(dietitianId: string, surveyId: string): Promise<Survey> {
    let survey = await this.repository.load(dietitianId, surveyId);

    // patch with template
    let template: Template | undefined;
    try {
      template = await this.templateCommands.getTemplate(
        dietitianId,
        survey.templateId,
      );
    } catch (error) {
      // do nothing
    }
    if (template) {
      survey = survey.patch(template);
    }
    return survey;
  }

  async loadSurvey(dietitianId: string, surveyId: string): Promise<Survey> {
    this.stateProvider.setState({
      loading: LoadingStatus.LOADING,
    });
    try {
      const survey = await this.getSurvey(dietitianId, surveyId);

      this.stateProvider.setState({
        entity: survey,
        loading: LoadingStatus.COMPLETE,
      });
      return survey;
    } catch (error) {
      console.error('loadSurvey', error);
      if (error instanceof Error) {
        this.stateProvider.setState({
          loading: LoadingStatus.ERROR,
          message: error.message,
        });
      }
      throw error;
    }
  }

  async getDietitianSurveysForPatient(
    dietitianId: string,
    patientId: string,
    asc = false,
  ): Promise<Survey[]> {
    return this.repository.findByDietitianAndPatientId(
      dietitianId,
      patientId,
      asc,
    );
  }

  async surveyExistsForDietitianIdTemplateId(
    dietitianId: string,
    templateId: string,
  ): Promise<boolean> {
    return this.repository.existsForDietitianIdTemplateId(
      dietitianId,
      templateId,
    );
  }

  async saveSurvey(
    surveyProps: SurveyProps,
    surveyId?: string,
  ): Promise<Survey> {
    let survey = Survey.create(surveyProps, new UniqueEntityID(surveyId));
    if (surveyId == this.stateProvider.state.entity?.id.toString()) {
      this.stateProvider.setState({
        entity: survey,
        loading: LoadingStatus.LOADING,
      });
    }

    // patch with template
    let template: Template | undefined;
    try {
      template = await this.templateCommands.getTemplate(
        surveyProps.dietitianId,
        survey.templateId,
      );
    } catch (error) {
      // do nothing
    }

    if (template) {
      survey = survey.patch(template);
    }

    let patient: Patient | undefined;
    try {
      patient = await this.patientCommands.getPatient(survey.patientId);
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState({
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: survey,
        });
      }
      throw error;
    }

    // get existing fields
    const existingFields: { [key: string]: SurveyField } = {};
    if (surveyId !== undefined) {
      try {
        const existingNote = await this.repository.load(
          surveyProps.dietitianId,
          surveyId,
        );
        for (const field of existingNote.fields) {
          existingFields[field.id.toString()] = field;
        }
      } catch (error) {
        // do nothing
      }
    }

    const fields = [...survey.fields];

    // sync patient series
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      const serie = field.matchingPatientSerie;
      // no matching serie, ignore
      if (!serie) continue;

      const existingField = existingFields[field.id.toString()];
      // no value change, ignore
      if (existingField?.value == field.value) continue;

      // invalid or default value, delete serie item if exists and ignore
      if (typeof field.value !== 'number' || field.value === 0) {
        // delete existing item
        if (field.serieItemId) {
          try {
            await this.patientSerieCommands.deleteSerieItem(
              survey.patientId,
              field.serieItemId,
            );
          } catch (error) {
            // do nothing
          }
        }
        continue;
      }

      try {
        const serieItem = await this.patientSerieCommands.saveSerieItem(
          {
            patientId: PatientId.create(new UniqueEntityID(survey.patientId)),
            patientUserId: patient.userId,
            serie,
            value: field.value,
            timestamp: survey.createdAt ?? new Date(),
          },
          field.serieItemId,
        );
        field = field.updateSerieItemId(serieItem.id.toString());
      } catch (error) {
        // do nothing
      }

      fields[i] = field;
    }

    survey = survey.updateFields(fields);

    // save survey
    try {
      if (surveyId !== undefined) {
        survey = await this.repository.save(survey);
        this.eventProvider.dispatch(new SurveyUpdated(survey));
      } else {
        survey = await this.repository.create(survey);
        this.eventProvider.dispatch(new SurveyCreated(survey));
      }
      if (surveyId == this.stateProvider.state.entity?.id.toString()) {
        this.stateProvider.setState({
          entity: survey,
          loading: LoadingStatus.COMPLETE,
        });
      }

      return survey;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState({
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: survey,
        });
      }
      throw error;
    }
  }

  async deleteSurvey(dietitianId: string, surveyId: string): Promise<Survey> {
    if (surveyId == this.stateProvider.state.entity?.id.toString()) {
      this.stateProvider.setState({
        entity: undefined,
        loading: LoadingStatus.LOADING,
      });
    }
    const survey = await this.repository.load(dietitianId, surveyId);

    // delete synchronized fields
    for (const field of survey.fields) {
      if (field.serieItemId) {
        try {
          await this.patientSerieCommands.deleteSerieItem(
            survey.patientId,
            field.serieItemId,
          );
        } catch (error) {
          // do nothing
        }
      }
    }

    await this.repository.delete(dietitianId, surveyId);
    this.eventProvider.dispatch(new SurveyDeleted(survey));
    if (surveyId == this.stateProvider.state.entity?.id.toString()) {
      this.stateProvider.setState({
        entity: undefined,
        loading: LoadingStatus.COMPLETE,
      });
    }
    return survey;
  }

  generateSurveyPdf(surveyId: string): Promise<Blob> {
    return this.repository.generatePdf(surveyId);
  }

  async sendSurveyToSpecificPatient(
    template: Template,
    message: string | undefined,
    patientId: PatientId,
  ): Promise<Survey> {
    try {
      if (template.dietitianId) {
        const surveyId = await this.callSendSurveyToSpecificPatient({
          dietitianId: template.dietitianId.id.toString(),
          templateId: template.templateId.id.toString(),
          patientId: patientId.id.toString(),
          message,
        });
        if (surveyId) {
          const survey = await this.repository.load(
            template.dietitianId.id.toString(),
            surveyId,
          );
          this.eventProvider.dispatch(new SurveyCreated(survey));
          return Promise.resolve(survey);
        } else {
          this.stateProvider.setState({
            loading: LoadingStatus.ERROR,
            message:
              "Une erreur s'est produite lors de l'envoi du questionnaire",
          });
          throw new Error(
            "Une erreur s'est produite lors de l'envoi du questionnaire",
          );
        }
      } else {
        this.stateProvider.setState({
          loading: LoadingStatus.ERROR,
          message: "Le diététicien n'est pas identifié",
        });
        throw new Error("Le diététicien n'est pas identifié");
      }
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState({
          loading: LoadingStatus.ERROR,
          message: error.message,
        });
      }
      throw error;
    }
  }

  async getDietitianActiveSurvey(dietitianId: string): Promise<Template[]> {
    const activatedTemplates =
      await this.templateCommands.getDietitianActiveTemplates(dietitianId);
    return activatedTemplates.filter(
      (activatedTemplate) => activatedTemplate.type === 'survey',
    );
  }

  async sendSurveyToGroupedPatients(
    template: Template,
    message: string | undefined,
    patientGroup: PatientGroup | undefined,
  ): Promise<void> {
    if (template.dietitianId) {
      return firstValueFrom<void>(
        this.repository.sendSurveyToGroupedPatients({
          dietitianId: template.dietitianId.id.toString(),
          templateId: template.templateId.id.toString(),
          patientGroupId: patientGroup?.patientGroupId?.id?.toString(),
          message,
        }),
      );
    } else {
      return Promise.reject("Le diététicien n'est pas identifié");
    }
  }

  async sendSurveyToPatientSelection(
    template: Template,
    message: string | undefined,
    patients: Patient[] | undefined,
  ): Promise<void> {
    if (template.dietitianId) {
      return firstValueFrom<void>(
        this.repository.sendSurveyToPatientSelection({
          dietitianId: template.dietitianId.id.toString(),
          templateId: template.templateId.id.toString(),
          patientIds: patients?.map((p) => p.id.toString()),
          message,
        }),
      );
    } else {
      return Promise.reject("Le diététicien n'est pas identifié");
    }
  }

  async registerPatientForSurvey(
    dietitianId: string,
    surveyId: string,
    conversationId: string | undefined,
  ): Promise<string | undefined> {
    return await this.patientCommands.registerPatientForSurvey({
      dietitianId,
      surveyId,
      conversationId,
    });
  }

  async logoutPatientForSurvey() {
    if (this.patientStateProvider.state.entity) {
      this.patientEventProvider.dispatch(
        new PatientSignedOut(this.patientStateProvider.state.entity),
      );
    }
    await this.authCommands.signOut();
    this.patientStateProvider.setState(<PatientState>{
      loading: LoadingStatus.COMPLETE,
    });
  }

  async loginPatientForSurvey(
    dietitianId: string,
    surveyId: string,
    code: string,
  ): Promise<Survey> {
    const token = await this.patientCommands.loginPatientForSurvey({
      dietitianId,
      surveyId,
      code,
    });

    if (token) {
      this.patientStateProvider.setState(<PatientState>{
        loading: LoadingStatus.LOADING,
      });
      try {
        const user = await this.authCommands.signInWithSurveyToken(token);
        const survey = await this.loadSurvey(dietitianId, surveyId);
        if (
          user.userId.id.toString() === survey.patientUserWebId ||
          user.userId.id.toString() === survey.patientUserId
        ) {
          const patient = await this.patientCommands.getPatient(
            survey.patientId,
          );
          this.patientStateProvider.setState(<PatientState>{
            loading: LoadingStatus.COMPLETE,
            entity: patient,
          });
          this.patientEventProvider.dispatch(new PatientSignedIn(patient));
          return survey;
        } else {
          throw new Error('Permission denied');
        }
      } catch (error) {
        if (error instanceof Error) {
          this.patientStateProvider.setState(<PatientState>{
            loading: LoadingStatus.ERROR,
            message: error.message,
          });
        }
        throw error;
      }
    } else {
      throw new Error('Token invalid');
    }
  }

  private async callSendSurveyToSpecificPatient(data: {
    dietitianId: string;
    templateId: string;
    patientId: string;
    message: string | undefined;
  }): Promise<string | undefined> {
    return firstValueFrom<string | undefined>(
      this.repository.sendSurveyToSpecificPatient(data),
    );
  }

  notifyDietitian(updatedSurvey: {
    dietitianId: string;
    patientId: string;
    surveyId: string;
  }) {
    return firstValueFrom<void>(
      this.repository.sendDietitianNotificationSurveyUpdated(updatedSurvey),
    );
  }
}
