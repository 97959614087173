import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { UserId } from 'src/app/data/auth/domain/user';

import { CustomTrackerId } from '../../../dietitian/domain/custom_tracker/custom_tracker';
import { DietitianId } from '../../../dietitian/domain/dietitian';
import { PatientGroupId } from '../../../dietitian/domain/patient_group/patient_group';
import { PatientId } from '../../../patient/domain/patient';
import { DiaryType } from '../diary/diary_type';
import { TrackerType } from './tracker_type';

export interface TrackerProps {
  dietitianId?: DietitianId;
  patientGroupId?: PatientGroupId;
  patientId?: PatientId;
  patientUserId?: UserId;
  dietitianTrackerId?: CustomTrackerId;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  diaryType: DiaryType;
  type: TrackerType;
  activatedAt?: Date;
  max: number;
  protected: boolean;
}

export class TrackerId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): TrackerId {
    return new TrackerId(id);
  }
}

export class Tracker extends Entity<TrackerProps> {
  get trackerId(): TrackerId {
    return TrackerId.create(this.id);
  }

  get dietitianId(): DietitianId | undefined {
    return this.props.dietitianId;
  }

  set dietitianId(value: DietitianId | undefined) {
    this.props.dietitianId = value;
  }

  get patientGroupId(): PatientGroupId | undefined {
    return this.props.patientGroupId;
  }

  set patientGroupId(value: PatientGroupId | undefined) {
    this.props.patientGroupId = value;
  }

  get patientId(): PatientId | undefined {
    return this.props.patientId;
  }

  set patientId(value: PatientId | undefined) {
    this.props.patientId = value;
  }

  get dietitianTrackerId(): CustomTrackerId | undefined {
    return this.props.dietitianTrackerId;
  }

  get patientUserId(): UserId | undefined {
    return this.props.patientUserId;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get name(): string {
    return this.props.name;
  }

  get diaryType(): DiaryType {
    return this.props.diaryType;
  }

  get type(): TrackerType {
    return this.props.type;
  }

  get active(): boolean {
    return this.props.activatedAt != undefined;
  }

  get activatedAt(): Date | undefined {
    return this.props.activatedAt;
  }

  set activatedAt(value: Date | undefined) {
    this.props.activatedAt = value;
  }

  get max(): number {
    return this.props.max;
  }

  get protected(): boolean {
    return this.props.protected;
  }

  private constructor(props: TrackerProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: TrackerProps): Tracker {
    return Tracker.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: TrackerProps, id?: UniqueEntityID): Tracker {
    return new Tracker(props, id);
  }
}
