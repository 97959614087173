import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { EmailCommands } from './domain/email/email_commands';
import { EmailEventProvider } from './domain/events/email/email_event_provider';
import { EmailRepository } from './repositories/email_repository';

@NgModule({
  declarations: [],
  imports: [CommonModule, ToastrModule.forRoot()],
  providers: [EmailRepository, EmailEventProvider, EmailCommands],
})
export class EmailModule {
  static forRoot(): ModuleWithProviders<EmailModule> {
    return {
      ngModule: EmailModule,
      providers: [
        {
          provide: EmailEventProvider,
        },
        {
          provide: EmailCommands,
        },
      ],
    };
  }
}
