import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { Email } from '../domain/email/email';
import { Template } from '../domain/email/template';

interface EmailSchema {
  dietitianId: string | null;
  patientId: string | null;
  from: string | null;
  replyTo: string | null;
  to: string[];
  cc: string[] | null;
  bcc: string[] | null;
  message: MessageSchema;
  template: TemplateSchema | null;
}

interface MessageSchema {
  subject: string | null;
  text: string | null;
  html: string | null;
  attachments: string[] | null;
}

interface TemplateSchema {
  name: string;
  data: { [key: string]: unknown };
}

@Injectable()
export class EmailRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<EmailSchema>('emails', queryFn);
  }

  private toSchema(email: Email): EmailSchema {
    return <EmailSchema>{
      dietitianId: email.dietitianId ?? null,
      patientId: email.patientId ?? null,
      from: email.from ?? null,
      replyTo: email.replyTo ?? null,
      to: email.to,
      cc: email.cc ?? null,
      bcc: email.bcc ?? null,
      message: <MessageSchema>{
        subject: email.subject ?? null,
        text: email.textMessage ?? null,
        html: email.htmlMessage ?? null,
        attachments: email.attachments ?? null,
      },
      template:
        email.template != undefined
          ? <TemplateSchema>{
              name: email.template.name,
              data: email.template.data,
            }
          : null,
    };
  }

  private fromSchema(schema: EmailSchema, id: string): Email {
    return Email.create(
      {
        dietitianId: schema.dietitianId ?? undefined,
        patientId: schema.patientId ?? undefined,
        from: schema.from ?? undefined,
        replyTo: schema.replyTo ?? undefined,
        to: schema.to ?? [],
        cc: schema.cc ?? undefined,
        bcc: schema.bcc ?? undefined,
        subject: schema.message?.subject ?? undefined,
        textMessage: schema.message?.text ?? undefined,
        htmlMessage: schema.message?.html ?? undefined,
        attachments: schema.message?.attachments ?? undefined,
        template:
          schema.template != null
            ? Template.create({
                name: schema.template.name,
                data: schema.template.data,
              })
            : undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async create(email: Email): Promise<Email> {
    const schema = this.toSchema(email);
    const ref = await this.collection().add(schema);

    return this.fromSchema(schema, ref.id);
  }
}
