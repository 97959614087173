import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export interface NutritionComponentProps {
  label: string;
}

export class NutritionComponentId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): NutritionComponentId {
    return new NutritionComponentId(id);
  }
}

export class NutritionComponent extends Entity<NutritionComponentProps> {
  get nutritionComponentId(): NutritionComponentId {
    return NutritionComponentId.create(this.id);
  }

  get label(): string {
    return this.props.label;
  }

  private constructor(props: NutritionComponentProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: NutritionComponentProps): NutritionComponent {
    return NutritionComponent.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: NutritionComponentProps,
    id?: UniqueEntityID,
  ): NutritionComponent {
    return new NutritionComponent(props, id);
  }
}
