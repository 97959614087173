import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export interface InvitationTemplateProps {
  content: string;
}

export class InvitationTemplateId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): InvitationTemplateId {
    return new InvitationTemplateId(id);
  }
}

export class InvitationTemplate extends Entity<InvitationTemplateProps> {
  get invitationTemplateId(): InvitationTemplateId {
    return InvitationTemplateId.create(this.id);
  }

  get content(): string {
    return this.props.content;
  }

  set content(value: string) {
    this.props.content = value;
  }

  private constructor(props: InvitationTemplateProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: InvitationTemplateProps): InvitationTemplate {
    return InvitationTemplate.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: InvitationTemplateProps,
    id?: UniqueEntityID,
  ): InvitationTemplate {
    return new InvitationTemplate(props, id);
  }
}
