import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { UserId } from 'src/app/data/auth/domain/user';

import { PatientId } from '../patient';
import { PatientSerie } from './patient_serie';

export interface PatientSerieItemProps {
  patientId: PatientId;
  patientUserId?: UserId;
  serie: PatientSerie;
  value: number;
  timestamp: Date;
}

export class PatientSerieItemId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): PatientSerieItemId {
    return new PatientSerieItemId(id);
  }
}

export class PatientSerieItem extends Entity<PatientSerieItemProps> {
  get patientSerieItemId(): PatientSerieItemId {
    return PatientSerieItemId.create(this.id);
  }

  get patientId(): PatientId {
    return this.props.patientId;
  }

  get patientUserId(): UserId | undefined {
    return this.props.patientUserId;
  }

  get serie(): PatientSerie {
    return this.props.serie;
  }

  get value(): number {
    return this.props.value;
  }

  set value(value: number) {
    this.props.value = value;
  }

  get timestamp(): Date | undefined {
    return this.props.timestamp;
  }

  private constructor(props: PatientSerieItemProps, id?: UniqueEntityID) {
    super(props, id);
  }

  public static create(
    props: PatientSerieItemProps,
    id?: UniqueEntityID,
  ): PatientSerieItem {
    return new PatientSerieItem(props, id);
  }
}
