import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { PatientId } from '../../patient/domain/patient';
import { Memo } from '../domain/memo/memo';
import { MemoNotFoundException } from '../domain/memo/memo_exceptions';

export interface MemoSchema {
  patientId: string;
  value: string;
  updatedAt: Timestamp;
}

@Injectable()
export class MemoRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection<MemoSchema>('dietitians')
      .doc(dietitianId)
      .collection<MemoSchema>('memos', queryFn);
  }

  toSchema(memo: Memo): MemoSchema {
    return <MemoSchema>{
      patientId: memo.patientId.id.toString(),
      value: memo.value,
      updatedAt: Timestamp.now(),
    };
  }

  fromSchema(schema: MemoSchema, id: string): Memo {
    return Memo.create(
      {
        patientId: PatientId.create(new UniqueEntityID(schema.patientId)),
        value: schema.value,
        updatedAt: schema.updatedAt.toDate(),
      },
      new UniqueEntityID(id),
    );
  }

  async load(dietitianId: string, patientId: string): Promise<Memo> {
    const data = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref
          .where('patientId', '==', patientId)
          .orderBy('updatedAt', 'asc')
          .limit(1),
      ).get(),
    ).then((snap) => snap.docs);
    if (!data || data.length <= 0) throw new MemoNotFoundException();

    const firstMemo = data[0];
    return this.fromSchema(firstMemo.data(), firstMemo.id);
  }

  async create(dietitianId: string, memo: Memo): Promise<Memo> {
    const schema = this.toSchema(memo);
    const ref = await this.collection(dietitianId).add(schema);
    return this.fromSchema(schema, ref.id);
  }

  save(dietitianId: string, memo: Memo): Promise<Memo> {
    const schema = this.toSchema(memo);
    return this.collection(dietitianId)
      .doc(memo.id.toString())
      .set(schema)
      .then(() => this.fromSchema(schema, memo.memoId.id.toString()));
  }
}
