export abstract class SelectionEvent<T> {
  date: Date;
  notify: boolean;
  items: T[];

  constructor(items: T[], notify = true) {
    this.date = new Date();
    this.items = items;
    this.notify = notify;
  }
}
