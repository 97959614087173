import { Injectable } from '@angular/core';
import EntityStateProvider, {
  EntityState,
} from 'src/app/core/domain/events/state_provider';

import { Patient } from './patient';

export type PatientState = EntityState<Patient>;

@Injectable({ providedIn: 'root' })
export class PatientStateProvider extends EntityStateProvider<PatientState> {}
