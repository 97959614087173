import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { CompositionRepository } from '../../repositories/composition-repository';
import { CompositionEventProvider } from '../events/composition/composition-event-provider';
import {
  CompositionCreated,
  CompositionDeleted,
  CompositionUpdated,
} from '../events/composition/composition-events';
import { Composition, CompositionProps } from './composition';

@Injectable()
export class CompositionCommands {
  constructor(
    private repository: CompositionRepository,
    private eventProvider: CompositionEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof CompositionCreated) {
        this.toastr.success('Document libre créé');
      } else if (event instanceof CompositionUpdated) {
        this.toastr.success('Document libre mis à jour');
      } else if (event instanceof CompositionDeleted) {
        this.toastr.success('Document libre supprimé');
      }
    });
  }

  async getComposition(compositionId: string): Promise<Composition> {
    return this.repository.load(compositionId);
  }

  async getDietitianCompositions(
    dietitianId: string,
    asc = false,
  ): Promise<Composition[]> {
    return this.repository.findByDietitianId(dietitianId, asc);
  }

  async getPatientCompositions(
    dietitianId: string,
    patientId: string,
    asc = false,
  ): Promise<Composition[]> {
    return this.repository.findByPatientId(dietitianId, patientId, asc);
  }

  async saveComposition(
    compositionProps: CompositionProps,
    compositionId: string | undefined,
  ): Promise<Composition> {
    let composition = Composition.create(
      compositionProps,
      new UniqueEntityID(compositionId),
    );
    if (compositionId !== undefined) {
      composition = await this.repository.save(composition);
      this.eventProvider.dispatch(new CompositionUpdated(composition));
    } else {
      composition = await this.repository.create(composition);
      this.eventProvider.dispatch(new CompositionCreated(composition));
    }
    return composition;
  }

  async deleteComposition(compositionId: string): Promise<Composition> {
    const composition = await this.getComposition(compositionId);
    await this.repository.delete(compositionId);
    this.eventProvider.dispatch(new CompositionDeleted(composition));
    return composition;
  }

  async generateCompositionPdf(compositionId: string): Promise<Blob> {
    return await this.repository.generatePdf(compositionId);
  }
}
