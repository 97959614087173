import { Exception } from 'src/app/core/logic/exception';

export class TrackerNotFoundException extends Exception<never> {
  constructor() {
    super('Tracker non trouvé');
  }
}

export class ProtectedTrackerUnchangeableException extends Exception<never> {
  constructor() {
    super('Tracker non modifiable');
  }
}

export class TrackerTargetException extends Exception<never> {
  constructor() {
    super('Tracker non associé');
  }
}
