import { DomainEvent } from '../../../../../core/domain/events/domain_event';
import { CustomNutrition } from '../../custom-nutrition/custom-nutrition';
import { Patient } from '../../patient';

export class CustomNutritionEvent extends DomainEvent<CustomNutrition> {}

export class CustomNutritionCreated extends CustomNutritionEvent {}

export class CustomNutritionDuplicated extends CustomNutritionEvent {
  origin: CustomNutrition;
  constructor(customNutrition: CustomNutrition, origin: CustomNutrition) {
    super(customNutrition);
    this.origin = origin;
  }
}

export class CustomNutritionSaved extends CustomNutritionEvent {
  patient: Patient;
  constructor(customNutrition: CustomNutrition, patient: Patient) {
    super(customNutrition);
    this.patient = patient;
  }
}

export class CustomNutritionDeleted extends CustomNutritionEvent {}
