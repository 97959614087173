import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { Encouragement } from '../../encouragement/encouragement';

export class EncouragementEvent extends DomainEvent<Encouragement> {}

export class EncouragementCreated extends EncouragementEvent {}

export class EncouragementUpdated extends EncouragementEvent {}

export class EncouragementDeleted extends EncouragementEvent {}

export class EncouragementBatchDeleted extends EncouragementEvent {
  encouragements: Encouragement[];

  constructor(encouragements: Encouragement[], notify?: boolean) {
    if (encouragements.length == 0) throw new Error('Empty encouragement list');

    super(encouragements[0], notify);

    this.encouragements = encouragements;
  }
}
