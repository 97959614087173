import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { DocumentRepository } from '../../repositories/document_repository';
import { DocumentEventProvider } from '../events/document/document_event_provider';
import {
  DocumentCreated,
  DocumentDeleted,
  DocumentUpdated,
} from '../events/document/document_events';
import { Document, DocumentProps } from './document';
import { DocumentUpload } from './document_upload';

@Injectable()
export class DocumentCommands {
  constructor(
    private repository: DocumentRepository,
    private eventProvider: DocumentEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof DocumentCreated) {
        this.toastr.success('Document créé');
      } else if (event instanceof DocumentUpdated) {
        this.toastr.success('Document mis à jour');
      } else if (event instanceof DocumentDeleted) {
        this.toastr.success('Document supprimé');
      }
    });
  }

  async getDocument(documentId: string): Promise<Document> {
    return this.repository.load(documentId);
  }

  async getDietitianDocuments(
    dietitianId: string,
    asc = false,
  ): Promise<Document[]> {
    return this.repository.findByDietitianId(dietitianId, asc);
  }

  async getPatientDocuments(
    dietitianId: string,
    patientId: string,
    asc = false,
  ): Promise<Document[]> {
    return this.repository.findByPatientId(dietitianId, patientId, asc);
  }

  uploadDocument(
    documentProps: DocumentProps,
    documentId?: string,
  ): DocumentUpload {
    const document = Document.create(
      documentProps,
      new UniqueEntityID(documentId),
    );
    return this.repository.upload(document);
  }

  async saveDocument(
    documentProps: DocumentProps,
    documentId?: string,
  ): Promise<Document> {
    let document = Document.create(
      documentProps,
      new UniqueEntityID(documentId),
    );
    if (documentId !== undefined) {
      document = await this.repository.save(document);
      this.eventProvider.dispatch(new DocumentUpdated(document));
    } else {
      document = await this.repository.create(document);
      this.eventProvider.dispatch(new DocumentCreated(document));
    }

    return document;
  }

  async deleteDocument(documentId: string): Promise<Document> {
    const document = await this.getDocument(documentId);
    await this.repository.delete(documentId);
    this.eventProvider.dispatch(new DocumentDeleted(document));

    return document;
  }
}
