import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { Profile } from '../profile/profile';

export interface ConversationProps {
  subject: string;
  createdAt: Date;
  updatedAt: Date;
  dietitian: Profile;
  patient?: Profile;
  closed?: boolean;
}

export class ConversationId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): ConversationId {
    return new ConversationId(id);
  }
}

export class Conversation extends Entity<ConversationProps> {
  get conversationId(): ConversationId {
    return ConversationId.create(this.id);
  }

  get subject(): string {
    return this.props.subject;
  }

  get closed(): boolean {
    return this.props.closed ?? false;
  }

  get dietitian(): Profile {
    return this.props.dietitian;
  }

  get patient(): Profile | undefined {
    return this.props.patient;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date {
    return this.props.updatedAt;
  }

  private constructor(props: ConversationProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: ConversationProps): Conversation {
    return Conversation.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: ConversationProps,
    id?: UniqueEntityID,
  ): Conversation {
    return new Conversation(props, id);
  }
}
