import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { UserId } from '../../auth/domain/user';
import { PatientId } from '../../patient/domain/patient';
import { Diary } from '../domain/diary/diary';
import { DiaryNotFoundException } from '../domain/diary/diary_exceptions';
import { DiaryTracker } from '../domain/diary/diary_tracker';
import { DiaryType } from '../domain/diary/diary_type';
import { Picture } from '../domain/diary/picture';
import { TrackerType } from '../domain/tracker/tracker_type';

interface DiarySchema {
  name: string | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  archivedAt: Timestamp | null;
  type: DiaryType;
  pictures: PictureSchema[];
  trackers: TrackerSchema[];
  comment: string | null;
  patientUserId: string | null;
}

interface PictureSchema {
  url: string;
  path: string;
}

interface TrackerSchema {
  name: string;
  max: number;
  value: number;
  type: TrackerType;
}

@Injectable()
export class DiaryRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collection(patientId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('patients')
      .doc(patientId)
      .collection<DiarySchema>('diaries', queryFn);
  }

  private toSchema(diary: Diary): DiarySchema {
    return <DiarySchema>{
      name: diary.name ?? null,
      createdAt:
        diary.createdAt !== undefined
          ? Timestamp.fromDate(diary.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      patientUserId: diary.patientUserId
        ? diary.patientUserId.id.toString()
        : null,
      type: diary.type,
      pictures: diary.pictures.map(
        (p) =>
          <PictureSchema>{
            url: p.url,
            path: p.path,
          },
      ),
      archivedAt:
        diary.archivedAt !== undefined
          ? Timestamp.fromDate(diary.archivedAt)
          : null,
      trackers: diary.trackers.map(
        (t) =>
          <TrackerSchema>{
            name: t.name,
            max: t.max,
            value: t.value,
            type: t.type,
          },
      ),
      comment: diary.comment ?? null,
    };
  }

  private fromSchema(
    schema: DiarySchema,
    patientId: string,
    id: string,
  ): Diary {
    return Diary.create(
      {
        patientId: PatientId.create(new UniqueEntityID(patientId)),
        patientUserId: schema.patientUserId
          ? UserId.create(new UniqueEntityID(schema.patientUserId))
          : undefined,
        name: schema.name ?? undefined,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        type: schema.type,
        pictures:
          schema.pictures?.map((p) =>
            Picture.create({
              url: p.url,
              path: p.path,
            }),
          ) ?? [],
        archivedAt: schema.archivedAt?.toDate() ?? undefined,
        trackers:
          schema.trackers?.map((t) =>
            DiaryTracker.create({
              name: t.name,
              max: t.max ?? 0,
              value: t.value ?? 0,
              type: t.type,
            }),
          ) ?? [],
        comment: schema.comment ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async load(patientId: string, diaryId: string): Promise<Diary> {
    const snap = await firstValueFrom(
      this.collection(patientId).doc(diaryId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new DiaryNotFoundException();
    }

    return this.fromSchema(snap.data() as DiarySchema, patientId, snap.id);
  }

  async findDiaries(
    patientId: string,
    start: Date,
    end: Date,
  ): Promise<Diary[]> {
    const snap = await firstValueFrom(
      this.collection(patientId, (ref) =>
        ref
          .where('createdAt', '>=', start)
          .where('createdAt', '<=', end)
          .where('archivedAt', '==', null)
          .orderBy('createdAt'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), patientId, doc.id),
    );
  }

  async create(diary: Diary): Promise<Diary> {
    const schema = this.toSchema(diary);
    const ref = await this.collection(diary.patientId.id.toString()).add(
      schema,
    );

    return this.fromSchema(schema, diary.patientId.id.toString(), ref.id);
  }

  save(diary: Diary): Promise<Diary> {
    const schema = this.toSchema(diary);

    return this.collection(diary.patientId.id.toString())
      .doc(diary.diaryId.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(
          schema,
          diary.patientId.id.toString(),
          diary.diaryId.id.toString(),
        ),
      );
  }
}
