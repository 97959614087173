import { DomainEvent } from 'src/app/core/domain/events/domain_event';
import { CustomTracker } from 'src/app/data/dietitian/domain/custom_tracker/custom_tracker';

export class CustomTrackerEvent extends DomainEvent<CustomTracker> {}

export class CustomTrackerCreated extends CustomTrackerEvent {}

export class CustomTrackerUpdated extends CustomTrackerEvent {}

export class CustomTrackerDeleted extends CustomTrackerEvent {}
