import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { PatientGroup } from '../../../../data/dietitian/domain/patient_group/patient_group';

export enum SortListType {
  All = 'all',
  Archived = 'archived',
  Group = 'group',
}

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  private _selectedSortListTypeEmitter = new EventEmitter<SortListType>();
  private _selectedGroupEmitter = new EventEmitter<PatientGroup>();
  private _selectedGroup?: PatientGroup | undefined;
  private _selectedSortListType?: SortListType | undefined;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    // nothing
  }

  public get selectedSortListTypeEmitter() {
    return this._selectedSortListTypeEmitter;
  }

  public get selectedGroupEmitter() {
    return this._selectedGroupEmitter;
  }

  public get selectedGroup(): PatientGroup | undefined {
    return this._selectedGroup;
  }

  public get selectedSortListType(): SortListType | undefined {
    return this._selectedSortListType;
  }

  async loadParams(patientGroups: PatientGroup[]) {
    if (patientGroups) {
      const params = await firstValueFrom(this.activatedRoute.queryParams);
      const filterType = params['filter'] ?? SortListType.All;
      if (filterType == SortListType.Group) {
        const groupId = params['groupId'];
        const group = patientGroups.find((g) => g.id.toString() == groupId);
        if (group) {
          this.updateSelectedGroup(group);
        }
      }
      this.updateSortListType(filterType);
    }
  }

  public updateSortListType(value: SortListType) {
    this._selectedSortListType = value;
    this._selectedSortListTypeEmitter.next(value);

    if (value != SortListType.Group) {
      this._selectedGroup = undefined;
    }

    this.router
      .navigate([], {
        queryParams: {
          filter: value,
          groupId: this._selectedGroup?.id?.toString() ?? null,
        },
        queryParamsHandling: 'merge',
      })
      .then();
  }

  public updateSelectedGroup(group: PatientGroup) {
    this._selectedGroup = group;
    this._selectedGroupEmitter.next(group);

    this.router
      .navigate([], {
        queryParams: {
          groupId: group.id.toString(),
        },
        queryParamsHandling: 'merge',
      })
      .then();
  }
}
