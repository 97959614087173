import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}
export interface InvoiceProps {
  createdAt: Date;
  status?: InvoiceStatus;
  amount: number;
  pdfUrl?: string;
  payUrl: string | undefined;
  number: string | undefined;
  receiptNumber: string | undefined;
}

export class InvoiceId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): InvoiceId {
    return new InvoiceId(id);
  }
}

export class Invoice extends Entity<InvoiceProps> {
  get invoiceId(): InvoiceId {
    return InvoiceId.create(this.id);
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get status(): InvoiceStatus | undefined {
    return this.props.status;
  }

  get amount(): number {
    return this.props.amount / 100;
  }

  get pdfUrl(): string | undefined {
    return this.props.pdfUrl;
  }

  get payUrl(): string | undefined {
    return this.props.payUrl;
  }

  get number(): string | undefined {
    return this.props.number;
  }

  get receiptNumber(): string | undefined {
    return this.props.receiptNumber;
  }

  private constructor(props: InvoiceProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: InvoiceProps): Invoice {
    return Invoice.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: InvoiceProps, id?: UniqueEntityID): Invoice {
    return new Invoice(props, id);
  }
}
