import { Injectable } from '@angular/core';

import StateProvider, {
  State,
} from '../../../core/domain/events/state_provider';
import { MsdpToken } from './msdp-token';

export type MsdpTokenState = State<MsdpToken>;

@Injectable({ providedIn: 'root' })
export class MsdpTokenStateProvider extends StateProvider<MsdpTokenState> {}
