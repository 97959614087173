import { NutritionTreeViewNode } from 'src/app/ui/nutrition/routes/editor/components/nutrition-food-library/components/nutrition-tree-view-node/nutrition-tree-view-node';

import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export interface NutritionGroupIngredientProps {
  label: string;
  level: number;
  parent: string | undefined;
}

export class NutritionGroupIngredient
  extends Entity<NutritionGroupIngredientProps>
  implements NutritionTreeViewNode
{
  get code(): string {
    return this.id.toString();
  }

  get label(): string {
    return this.props.label;
  }

  get level(): number {
    return this.props.level;
  }

  get parent(): string | undefined {
    return this.props.parent;
  }

  private constructor(
    props: NutritionGroupIngredientProps,
    id?: UniqueEntityID,
  ) {
    super(props, id);
  }

  copyWith(props: NutritionGroupIngredientProps): NutritionGroupIngredient {
    return NutritionGroupIngredient.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: NutritionGroupIngredientProps,
    id?: UniqueEntityID,
  ): NutritionGroupIngredient {
    return new NutritionGroupIngredient(props, id);
  }
}
