import { AngularFireUploadTask } from '@angular/fire/compat/storage';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { catchError, EMPTY, lastValueFrom, Observable } from 'rxjs';
import ValueObject from 'src/app/core/domain/value_object';

import { Document } from './document';

interface DocumentUploadProps {
  document: Document;
  task: AngularFireUploadTask;
}

export class DocumentUpload extends ValueObject<DocumentUploadProps> {
  get document(): Document {
    return this.props.document;
  }

  get task(): AngularFireUploadTask {
    return this.props.task;
  }

  get progress(): Observable<number | undefined> {
    return this.task.percentageChanges().pipe(
      catchError(() => {
        return EMPTY;
      }),
    );
  }

  get changes(): Observable<UploadTaskSnapshot | undefined> {
    return this.task.snapshotChanges();
  }

  cancel(): boolean {
    if (this.task.task.snapshot.state == 'canceled') return true;
    return this.task.cancel();
  }

  async getUrl(): Promise<string> {
    await lastValueFrom(this.changes);
    return this.task.task.snapshot.ref.getDownloadURL();
  }

  private constructor(props: DocumentUploadProps) {
    super(props);
  }

  public static create(props: DocumentUploadProps): DocumentUpload {
    return new DocumentUpload(props);
  }
}
