import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { UserId } from '../../auth/domain/user';
import { CustomTrackerId } from '../../dietitian/domain/custom_tracker/custom_tracker';
import { DietitianId } from '../../dietitian/domain/dietitian';
import { PatientGroupId } from '../../dietitian/domain/patient_group/patient_group';
import { PatientId } from '../../patient/domain/patient';
import { PatientNotFoundException } from '../../patient/domain/patient_exceptions';
import { DiaryType } from '../domain/diary/diary_type';
import { Tracker } from '../domain/tracker/tracker';
import { TrackerTargetException } from '../domain/tracker/tracker_exceptions';
import { TrackerType } from '../domain/tracker/tracker_type';

export interface TrackerSchema {
  patientUserId: string | null;
  dietitianTrackerId: string | null;
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  diaryType: DiaryType;
  type: TrackerType;
  max: number;
  activatedAt: Timestamp | null;
  protected: boolean;
}

@Injectable()
export class TrackerRepository {
  constructor(private firestore: AngularFirestore) {
    // do nothing
  }

  private collectionDietitian(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<TrackerSchema>('trackers', queryFn);
  }

  private collectionPatientGroup(patientGroupId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('patient_groups')
      .doc(patientGroupId)
      .collection<TrackerSchema>('trackers', queryFn);
  }

  private collectionPatient(patientId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('patients')
      .doc(patientId)
      .collection<TrackerSchema>('trackers', queryFn);
  }

  toSchema(tracker: Tracker): TrackerSchema {
    return {
      patientUserId: tracker.patientUserId?.id.toString() ?? null,
      name: tracker.name,
      dietitianTrackerId: tracker.dietitianTrackerId?.id.toString() ?? null,
      createdAt:
        tracker.createdAt !== undefined
          ? Timestamp.fromDate(tracker.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      diaryType: tracker.diaryType,
      type: tracker.type,
      activatedAt:
        tracker.activatedAt !== undefined
          ? Timestamp.fromDate(tracker.activatedAt)
          : null,
      max: tracker.max,
      protected: tracker.protected,
    };
  }

  fromSchema(
    schema: TrackerSchema,
    dietitianId: string | undefined,
    patientGroupId: string | undefined,
    patientId: string | undefined,
    id: string,
  ): Tracker {
    return Tracker.create(
      {
        dietitianTrackerId: schema.dietitianTrackerId
          ? CustomTrackerId.create(
              new UniqueEntityID(schema.dietitianTrackerId),
            )
          : undefined,
        dietitianId: dietitianId
          ? DietitianId.create(new UniqueEntityID(dietitianId))
          : undefined,
        patientGroupId: patientGroupId
          ? PatientGroupId.create(new UniqueEntityID(patientGroupId))
          : undefined,
        patientId: patientId
          ? PatientId.create(new UniqueEntityID(patientId))
          : undefined,
        patientUserId: schema.patientUserId
          ? UserId.create(new UniqueEntityID(schema.patientUserId))
          : undefined,
        name: schema.name,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt
          ? schema.updatedAt.toDate()
          : schema.createdAt.toDate(),
        diaryType: schema.diaryType,
        type: schema.type,
        activatedAt: schema.activatedAt?.toDate(),
        max: schema.max ?? 0,
        protected: schema.protected,
      },
      new UniqueEntityID(id),
    );
  }

  async loadPatient(
    patientId: string,
    trackerId: string,
  ): Promise<Tracker | undefined> {
    const snap = await firstValueFrom(
      this.collectionPatient(patientId).doc(trackerId).get(),
    );
    const schema = snap.data() as TrackerSchema;
    if (schema) {
      return this.fromSchema(schema, undefined, undefined, patientId, snap.id);
    }
    return undefined;
  }

  async loadPatientGroup(
    patientGroupId: string,
    trackerId: string,
  ): Promise<Tracker | undefined> {
    const snap = await firstValueFrom(
      this.collectionPatientGroup(patientGroupId).doc(trackerId).get(),
    );
    const schema = snap.data() as TrackerSchema;
    if (schema) {
      return this.fromSchema(
        schema,
        undefined,
        patientGroupId,
        undefined,
        snap.id,
      );
    }
    return undefined;
  }

  async loadDietitian(
    dietitianId: string,
    trackerId: string,
  ): Promise<Tracker | undefined> {
    const snap = await firstValueFrom(
      this.collectionDietitian(dietitianId).doc(trackerId).get(),
    );
    const schema = snap.data() as TrackerSchema;
    if (schema) {
      return this.fromSchema(
        schema,
        dietitianId,
        undefined,
        undefined,
        snap.id,
      );
    }
    return undefined;
  }

  async findByDietitianId(dietitianId: string, asc = true): Promise<Tracker[]> {
    const snap = await firstValueFrom(
      this.collectionDietitian(dietitianId, (ref) =>
        ref.orderBy('createdAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, undefined, undefined, doc.id),
    );
  }

  async findByPatientGroupId(
    patientGroupId: string,
    asc = true,
  ): Promise<Tracker[]> {
    const snap = await firstValueFrom(
      this.collectionPatientGroup(patientGroupId, (ref) =>
        ref.orderBy('createdAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), undefined, patientGroupId, undefined, doc.id),
    );
  }

  async findByPatientId(patientId: string, asc = true): Promise<Tracker[]> {
    const snap = await firstValueFrom(
      this.collectionPatient(patientId, (ref) =>
        ref.orderBy('createdAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), undefined, undefined, patientId, doc.id),
    );
  }

  async findTrackersByDietitianIdAndDiaryType(
    dietitianId: string,
    diaryType: DiaryType,
  ): Promise<Tracker[]> {
    const snap = await firstValueFrom(
      this.collectionDietitian(dietitianId, (ref) =>
        ref
          .where('diaryType', '==', diaryType)
          .orderBy('protected', 'desc')
          .orderBy('createdAt', 'asc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, undefined, undefined, doc.id),
    );
  }

  async findTrackersByPatientGroupIdAndDiaryType(
    patientGroupId: string,
    diaryType: DiaryType,
  ): Promise<Tracker[]> {
    try {
      const snap = await firstValueFrom(
        this.collectionPatientGroup(patientGroupId, (ref) =>
          ref
            .where('diaryType', '==', diaryType)
            .orderBy('protected', 'desc')
            .orderBy('createdAt', 'asc'),
        ).get(),
      );

      return snap.docs.map((doc) =>
        this.fromSchema(
          doc.data(),
          undefined,
          patientGroupId,
          undefined,
          doc.id,
        ),
      );
    } catch (e: any) {
      throw new Error(
        'findTrackersByPatientGroupIdAndDiaryType Error : ' + e.toString(),
      );
    }
  }

  async findTrackersByPatientIdAndDiaryType(
    patientId: string,
    diaryType: DiaryType,
  ): Promise<Tracker[]> {
    const snap = await firstValueFrom(
      this.collectionPatient(patientId, (ref) =>
        ref
          .where('diaryType', '==', diaryType)
          .orderBy('protected', 'desc')
          .orderBy('createdAt', 'asc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), undefined, undefined, patientId, doc.id),
    );
  }

  async create(tracker: Tracker): Promise<Tracker> {
    if (tracker.patientId) {
      const schema = this.toSchema(tracker);
      const ref = await this.collectionPatient(
        tracker.patientId.id.toString(),
      ).add(schema);

      return this.fromSchema(
        schema,
        undefined,
        undefined,
        tracker.patientId.id.toString(),
        ref.id,
      );
    } else if (tracker.patientGroupId) {
      const schema = this.toSchema(tracker);
      const ref = await this.collectionPatientGroup(
        tracker.patientGroupId.id.toString(),
      ).add(schema);

      return this.fromSchema(
        schema,
        undefined,
        tracker.patientGroupId.id.toString(),
        undefined,
        ref.id,
      );
    } else if (tracker.dietitianId) {
      const schema = this.toSchema(tracker);
      const ref = await this.collectionDietitian(
        tracker.dietitianId.id.toString(),
      ).add(schema);

      return this.fromSchema(
        schema,
        tracker.dietitianId.id.toString(),
        undefined,
        undefined,
        ref.id,
      );
    } else {
      throw new TrackerTargetException();
    }
  }

  async saveBatch(trackers: Tracker[]): Promise<void> {
    const batch = this.firestore.firestore.batch();
    for (const tracker of trackers) {
      const schema = this.toSchema(tracker);
      if (tracker.patientId) {
        const ref = this.collectionPatient(tracker.patientId.id.toString()).doc(
          tracker.id.toString(),
        ).ref;
        batch.set(ref, schema);
      } else if (tracker.patientGroupId) {
        const ref = this.collectionPatientGroup(
          tracker.patientGroupId.id.toString(),
        ).doc(tracker.id.toString()).ref;
        batch.set(ref, schema);
      } else if (tracker.dietitianId) {
        const ref = this.collectionDietitian(
          tracker.dietitianId.id.toString(),
        ).doc(tracker.id.toString()).ref;
        batch.set(ref, schema);
      } else {
        console.error('❌ tracker non ciblé !', tracker);
      }
    }
    await batch.commit();
  }

  async save(tracker: Tracker): Promise<Tracker> {
    const schema = this.toSchema(tracker);
    if (tracker.patientId) {
      await this.collectionPatient(tracker.patientId.id.toString())
        .doc(tracker.trackerId.id.toString())
        .set(schema);
    } else if (tracker.patientGroupId) {
      await this.collectionPatientGroup(tracker.patientGroupId.id.toString())
        .doc(tracker.trackerId.id.toString())
        .set(schema);
    } else if (tracker.dietitianId) {
      await this.collectionDietitian(tracker.dietitianId.id.toString())
        .doc(tracker.trackerId.id.toString())
        .set(schema);
    } else {
      throw new TrackerTargetException();
    }
    return this.fromSchema(
      schema,
      tracker.dietitianId?.id.toString() ?? undefined,
      tracker.patientGroupId?.id.toString() ?? undefined,
      tracker.patientId?.id.toString() ?? undefined,
      tracker.trackerId.id.toString(),
    );
  }

  async delete(
    dietitianId: string | undefined,
    patientGroupId: string | undefined,
    patientId: string | undefined,
    trackerId: string,
  ): Promise<void> {
    if (patientId) {
      return this.collectionPatient(patientId).doc(trackerId).delete();
    } else if (patientGroupId) {
      return this.collectionPatientGroup(patientGroupId)
        .doc(trackerId)
        .delete();
    } else if (dietitianId) {
      return this.collectionDietitian(dietitianId).doc(trackerId).delete();
    } else {
      throw new TrackerTargetException();
    }
  }

  async deleteAllOfPatient(patientId: string) {
    if (patientId) {
      const snap = await firstValueFrom(
        this.collectionPatient(patientId).get(),
      );
      for (const doc of snap.docs) {
        await this.collectionPatient(patientId).doc(doc.id).delete();
      }
    } else {
      throw new PatientNotFoundException();
    }
  }

  async deletePatientGroup(
    patientGroupId: string,
    trackerId: string,
  ): Promise<void> {
    return this.collectionPatientGroup(patientGroupId).doc(trackerId).delete();
  }

  async deleteDietitian(dietitianId: string, trackerId: string): Promise<void> {
    return this.collectionDietitian(dietitianId).doc(trackerId).delete();
  }
}
