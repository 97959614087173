import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { DietitianId } from '../dietitian';

export interface PatientGroupProps {
  dietitianId: DietitianId;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  patientCount: number;
  activatedPatientCount: number;
  archivedPatientCount: number;
  encouragementCount: number;
}

export class PatientGroupId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): PatientGroupId {
    return new PatientGroupId(id);
  }
}

export class PatientGroup extends Entity<PatientGroupProps> {
  get patientGroupId(): PatientGroupId {
    return PatientGroupId.create(this.id);
  }

  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get name(): string {
    return this.props.name;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get patientCount(): number {
    return this.props.patientCount;
  }

  get activatedPatientCount(): number {
    return this.props.activatedPatientCount;
  }

  get archivedPatientCount(): number {
    return this.props.archivedPatientCount;
  }

  get encouragementCount(): number {
    return this.props.encouragementCount;
  }

  private constructor(props: PatientGroupProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: PatientGroupProps): PatientGroup {
    return PatientGroup.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  updatePatientCount(
    patientCount: number,
    activatedPatientCount: number,
    archivedPatientCount: number,
  ): PatientGroup {
    return this.copyWith({
      patientCount,
      activatedPatientCount,
      archivedPatientCount,
    } as PatientGroupProps);
  }

  public static create(
    props: PatientGroupProps,
    id?: UniqueEntityID,
  ): PatientGroup {
    return new PatientGroup(props, id);
  }
}
