<div></div>

<div class="information-wrapper">
  <span class="banner-title">{{title}}</span>
  <span class="banner-subtitle" *ngIf="subtitle">{{subtitle}}</span>
</div>

<div class="close-button">
  <i i-bs name="x" class="close-button-icon" width="13" height="13" (click)="onClose()"></i>
</div>
