import { Exception } from 'src/app/core/logic/exception';

export class InvalidSharedDocumentFileTypeException extends Exception<never> {
  constructor() {
    super(
      'Type de fichier invalide, acceptés: pdf/doc/xls, jpeg/png, mp3/aac, zip/rar',
    );
  }
}

export class SharedDocumentFileTooBigException extends Exception<never> {
  constructor() {
    super('Taille de fichier trop importante, limite de 15Mo');
  }
}

export class SharedDocumentNotFoundException extends Exception<never> {
  constructor() {
    super('Document partagé non trouvé');
  }
}

export class SharedDocumentNoPatientFoundException extends Exception<never> {
  constructor() {
    super('Aucun patient actif associé au partage');
  }
}
