import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  collection as firebaseCollection,
  getCountFromServer,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { PatientId } from '../../patient/domain/patient';
import { DietitianId } from '../domain/dietitian';
import { Encouragement } from '../domain/encouragement/encouragement';
import { EncouragementStatus } from '../domain/encouragement/encouragement';
import { EncouragementNotFoundException } from '../domain/encouragement/encouragement_exceptions';
import { PatientGroupId } from '../domain/patient_group/patient_group';

interface EncouragementSchema {
  dietitianId: string;
  groupId: string | null;
  patientId: string | string[] | null;
  recipientName: string | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  scheduled: boolean;
  scheduledAt: Timestamp;
  message: string;
  status: EncouragementStatus | null;
}

@Injectable()
export class EncouragementRepository {
  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {}

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<EncouragementSchema>('encouragements', queryFn);
  }

  toSchema(encouragement: Encouragement): EncouragementSchema {
    return <EncouragementSchema>{
      dietitianId: encouragement.dietitianId.id.toString(),
      groupId: encouragement.groupId?.id.toString() ?? null,
      patientId: encouragement.patientId
        ? Array.isArray(encouragement.patientId)
          ? encouragement.patientId.map((i) => i.id.toString())
          : encouragement.patientId.id.toString()
        : null,
      recipientName: encouragement.recipientName ?? null,
      createdAt:
        encouragement.createdAt !== undefined
          ? Timestamp.fromDate(encouragement.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      scheduled: encouragement.scheduled,
      scheduledAt: Timestamp.fromDate(encouragement.scheduledAt),
      message: encouragement.message,
      status: encouragement.status ?? null,
    };
  }

  private fromSchema(schema: EncouragementSchema, id: string): Encouragement {
    return Encouragement.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(schema.dietitianId)),
        groupId: schema.groupId
          ? PatientGroupId.create(new UniqueEntityID(schema.groupId))
          : undefined,
        patientId: schema.patientId
          ? Array.isArray(schema.patientId)
            ? schema.patientId.map((i) =>
                PatientId.create(new UniqueEntityID(i)),
              )
            : PatientId.create(new UniqueEntityID(schema.patientId))
          : undefined,
        recipientName: schema.recipientName ?? undefined,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        scheduled: schema.scheduled,
        scheduledAt: schema.scheduledAt.toDate(),
        message: schema.message,
        status: schema.status ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async load(
    dietitianId: string,
    encouragementId: string,
  ): Promise<Encouragement> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(encouragementId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new EncouragementNotFoundException();
    }

    return this.fromSchema(snap.data() as EncouragementSchema, snap.id);
  }

  async findByDietitianId(
    dietitianId: string,
    groupId: string | undefined,
    scheduled = true,
    asc = true,
  ): Promise<Encouragement[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) => {
        let query = ref.orderBy('scheduledAt', asc ? 'asc' : 'desc');

        if (scheduled) {
          query = query.where('scheduled', '==', true);
          query = query.where('status', '==', EncouragementStatus.TO_PROCESS);
        }

        if (groupId != undefined) {
          query = query.where('groupId', '==', groupId);
        }

        return query;
      }).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async create(encouragement: Encouragement): Promise<Encouragement> {
    const schema = this.toSchema(encouragement);
    const ref = await this.collection(encouragement.dietitianId.id.toString())
      .add(schema)
      .then(async (schema) => {
        return schema;
      });

    return this.fromSchema(schema, ref.id);
  }

  async save(encouragement: Encouragement): Promise<Encouragement> {
    const schema = this.toSchema(encouragement);
    return this.collection(encouragement.dietitianId.id.toString())
      .doc(encouragement.encouragmentId.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(schema, encouragement.encouragmentId.id.toString()),
      );
  }

  delete(dietitianId: string, encouragementId: string): Promise<void> {
    return this.collection(dietitianId)
      .doc(encouragementId)
      .update({ status: EncouragementStatus.DELETED });
  }

  async getDietitianEncouragementCount(dietitianId: string): Promise<number> {
    const collection = firebaseCollection(
      this.firestore.firestore,
      `dietitians/${dietitianId}/encouragements`,
    );

    const q = query(
      collection,
      where('status', '==', EncouragementStatus.TO_PROCESS),
      where('scheduled', '==', true),
    );

    const countResult = await getCountFromServer(q);

    return countResult.data().count;
  }

  async getDietitianEncouragementCountByGroup(
    dietitianId: string,
    groupId: string,
  ): Promise<number> {
    const collection = firebaseCollection(
      this.firestore.firestore,
      `dietitians/${dietitianId}/encouragements`,
    );

    const q = query(
      collection,
      where('status', '==', EncouragementStatus.TO_PROCESS),
      where('scheduled', '==', true),
      where('groupId', '==', groupId),
    );

    const countResult = await getCountFromServer(q);

    return countResult.data().count;
  }
}
