import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { StripeProductId } from './subscription';

export class StripePriceId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): StripePriceId {
    return new StripePriceId(id);
  }
}

export enum PremiumStatus {
  Active = 'active',
  Disable = 'disable',
}

export interface PremiumProps {
  label: string;
  subtitle: string;
  features: string[];
  stripeProductId: StripeProductId;
  stripePriceId: StripePriceId;
  createdAt: Date;
  updatedAt: Date | undefined;
  status: PremiumStatus;
  sort: number;
  default: boolean | undefined;
}

export class Premium extends Entity<PremiumProps> {
  get label(): string {
    return this.props.label;
  }

  get subtitle(): string {
    return this.props.subtitle;
  }

  get features(): string[] {
    return this.props.features;
  }

  get stripeProductId(): StripeProductId {
    return this.props.stripeProductId;
  }

  get stripePriceId(): StripePriceId {
    return this.props.stripePriceId;
  }

  get createdAt(): Date {
    return this.props.createdAt;
  }

  get sort(): number {
    return this.props.sort;
  }

  get status(): PremiumStatus {
    return this.props.status;
  }

  get default(): boolean {
    return this.props.default ?? false;
  }

  private constructor(props: PremiumProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: PremiumProps): Premium {
    return Premium.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: PremiumProps, id?: UniqueEntityID): Premium {
    return new Premium(props, id);
  }
}
