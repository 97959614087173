import { Exception } from 'src/app/core/logic/exception';

export class PatientGroupNotFoundException extends Exception<never> {
  constructor() {
    super('Groupe non trouvé');
  }
}

export class PatientGroupExistingNameException extends Exception<string> {
  constructor(name: string) {
    super(`Un groupe avec le nom "${name}" existe déjà`, name);
  }
}
