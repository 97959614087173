import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { PatientGroup } from '../patient_group/patient_group';

export class PatientGroupEvent extends DomainEvent<PatientGroup> {}

export class PatientGroupCreated extends PatientGroupEvent {}

export class PatientGroupUpdated extends PatientGroupEvent {}

export class PatientGroupDeleted extends PatientGroupEvent {}
