import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { Template } from '../../templates/template';

export class NotesTemplateEvent extends DomainEvent<Template> {}

export class TemplateCreated extends NotesTemplateEvent {}

export class TemplateUpdated extends NotesTemplateEvent {}

export class TemplateArchived extends NotesTemplateEvent {}

export class TemplateRestored extends NotesTemplateEvent {}

export class TemplateExported extends NotesTemplateEvent {}

export class TemplateImported extends NotesTemplateEvent {}
