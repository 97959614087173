import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingStatus } from 'src/app/core/domain/events/state_provider';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { Patient } from 'src/app/data/patient/domain/patient';
import { PatientCommands } from 'src/app/data/patient/domain/patient_commands';
import { PatientSerieCommands } from 'src/app/data/patient/domain/patient_serie/patient_serie_commands';

import { NoteRepository } from '../../repositories/note_repository';
import { NoteEventProvider } from '../events/note/note_event_provider';
import {
  NoteCreated,
  NoteDeleted,
  NoteUpdated,
} from '../events/note/note_events';
import { Template } from '../templates/template';
import { TemplateCommands } from '../templates/template_commands';
import { Note, NoteProps } from './note';
import { NoteField } from './note_field';
import { NoteStateProvider } from './note_state_provider';

@Injectable()
export class NoteCommands {
  constructor(
    private repository: NoteRepository,
    private stateProvider: NoteStateProvider,
    private eventProvider: NoteEventProvider,
    private templateCommands: TemplateCommands,
    private patientCommands: PatientCommands,
    private patientSerieCommands: PatientSerieCommands,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof NoteDeleted) {
        this.toastr.success('Note supprimée');
      }
    });
  }

  async getNote(dietitianId: string, noteId: string): Promise<Note> {
    let note = await this.repository.load(dietitianId, noteId);

    // patch with template
    let template: Template | undefined;
    try {
      template = await this.templateCommands.getTemplate(
        dietitianId,
        note.templateId.id.toString(),
      );
    } catch (error) {
      // do nothing
    }
    if (template) {
      note = note.patch(template);
    }

    return note;
  }

  async loadNote(dietitianId: string, noteId: string): Promise<Note> {
    this.stateProvider.setState({
      loading: LoadingStatus.LOADING,
    });
    try {
      const note = await this.getNote(dietitianId, noteId);

      this.stateProvider.setState({
        entity: note,
        loading: LoadingStatus.COMPLETE,
      });
      return note;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState({
          loading: LoadingStatus.ERROR,
          message: error.message,
        });
      }
      throw error;
    }
  }

  async getDietitianNotes(dietitianId: string, asc = false): Promise<Note[]> {
    return this.repository.findByDietitianId(dietitianId, asc);
  }

  async getDietitianNotesForPatient(
    dietitianId: string,
    patientId: string,
    asc = false,
  ): Promise<Note[]> {
    return this.repository.findByDietitianAndPatientId(
      dietitianId,
      patientId,
      asc,
    );
  }

  async noteExistsForDietitianIdTemplateId(
    dietitianId: string,
    templateId: string,
  ): Promise<boolean> {
    return this.repository.existsForDietitianIdTemplateId(
      dietitianId,
      templateId,
    );
  }

  async createNote(note: Note): Promise<Note> {
    try {
      note = await this.repository.create(note);
      this.eventProvider.dispatch(new NoteCreated(note));
      return note;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState({
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: note,
        });
      }
      throw error;
    }
  }

  async saveNote(noteProps: NoteProps, noteId?: string): Promise<Note> {
    let note = Note.create(noteProps, new UniqueEntityID(noteId));
    if (noteId == this.stateProvider.state.entity?.id.toString()) {
      this.stateProvider.setState({
        entity: note,
        loading: LoadingStatus.LOADING,
      });
    }

    // patch with template
    let template: Template | undefined;
    try {
      template = await this.templateCommands.getTemplate(
        noteProps.dietitianId.id.toString(),
        note.templateId.id.toString(),
      );
    } catch (error) {
      // do nothing
    }

    if (template) {
      note = note.patch(template);
    }

    let patient: Patient | undefined;
    try {
      patient = await this.patientCommands.getPatient(
        note.patientId.id.toString(),
      );
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState({
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: note,
        });
      }
      throw error;
    }

    // get existing fields
    const existingFields: { [key: string]: NoteField } = {};
    if (noteId !== undefined) {
      try {
        const existingNote = await this.repository.load(
          noteProps.dietitianId.id.toString(),
          noteId,
        );
        for (const field of existingNote.fields) {
          existingFields[field.id.toString()] = field;
        }
      } catch (error) {
        if (error instanceof Error) {
          this.stateProvider.setState({
            loading: LoadingStatus.ERROR,
            message: error.message,
            entity: note,
          });
        }
        throw error;
      }
    }

    const fields = [...note.fields];

    // sync patient series
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      const serie = field.matchingPatientSerie;
      // no matching serie, ignore
      if (!serie) continue;

      const existingField = existingFields[field.id.toString()];
      // no value change, ignore
      if (existingField?.value == field.value) continue;

      // invalid or default value, delete serie item if exists and ignore
      if (typeof field.value !== 'number' || field.value === 0) {
        // delete existing item
        if (field.serieItemId) {
          try {
            await this.patientSerieCommands.deleteSerieItem(
              note.patientId.id.toString(),
              field.serieItemId,
            );
          } catch (error) {
            // do nothing
          }
        }
        continue;
      }

      try {
        const serieItem = await this.patientSerieCommands.saveSerieItem(
          {
            patientId: note.patientId,
            patientUserId: patient.userId,
            serie,
            value: field.value,
            timestamp: note.createdAt ?? new Date(),
          },
          field.serieItemId,
        );
        field = field.updateSerieItemId(serieItem.id.toString());
      } catch (error) {
        // do nothing
      }

      fields[i] = field;
    }

    note = note.updateFields(fields);

    // save note
    try {
      if (noteId !== undefined) {
        note = await this.repository.save(note);
        this.eventProvider.dispatch(new NoteUpdated(note));
      } else {
        note = await this.repository.create(note);
        this.eventProvider.dispatch(new NoteCreated(note));
      }
      if (noteId == this.stateProvider.state.entity?.id.toString()) {
        this.stateProvider.setState({
          entity: note,
          loading: LoadingStatus.COMPLETE,
        });
      }
      return note;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState({
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: note,
        });
      }
      throw error;
    }
  }

  async deleteNote(dietitianId: string, noteId: string): Promise<Note> {
    if (noteId == this.stateProvider.state.entity?.id.toString()) {
      this.stateProvider.setState({
        entity: undefined,
        loading: LoadingStatus.LOADING,
      });
    }
    const note = await this.repository.load(dietitianId, noteId);

    // delete synchronized fields
    for (const field of note.fields) {
      if (field.serieItemId) {
        try {
          await this.patientSerieCommands.deleteSerieItem(
            note.patientId.id.toString(),
            field.serieItemId,
          );
        } catch (error) {
          // do nothing
        }
      }
    }

    await this.repository.delete(dietitianId, noteId);
    this.eventProvider.dispatch(new NoteDeleted(note));
    if (noteId == this.stateProvider.state.entity?.id.toString()) {
      this.stateProvider.setState({
        entity: undefined,
        loading: LoadingStatus.COMPLETE,
      });
    }
    return note;
  }

  generateNotePdf(noteId: string): Promise<Blob> {
    return this.repository.generatePdf(noteId);
  }
}
