import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { Note } from '../../note/note';

export class NoteEvent extends DomainEvent<Note> {}

export class NoteCreated extends NoteEvent {}

export class NoteUpdated extends NoteEvent {}

export class NoteDeleted extends NoteEvent {}
