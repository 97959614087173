import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';

import { NewsPopupComponent } from './news-popup.component';

@NgModule({
  declarations: [NewsPopupComponent],
  imports: [CommonModule, NgxBootstrapIconsModule, FormsModule],
  exports: [NewsPopupComponent],
})
export class NewsPopupModule {}
