import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import {
  GenericException,
  UnAuthorizedException,
} from 'src/app/core/logic/exception';
import { environment } from 'src/environments/environment';

import {
  FieldTool,
  FieldType,
} from '../../../ui/template/routes/editor/components/fields-library/field_type';
import { SharedDocumentId } from '../../document/domain/shared_document/shared_document';
import { PatientId } from '../../patient/domain/patient';
import { DietitianId } from '../domain/dietitian';
import { Note, NoteId } from '../domain/note/note';
import { NoteNotFoundException } from '../domain/note/note_exceptions';
import { NoteField } from '../domain/note/note_field';
import { TemplateId } from '../domain/templates/template';
import { TemplateFieldId } from '../domain/templates/template_field';

export interface NoteSchema {
  patientId: string;
  notesTemplateId: string;
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  fields?: NoteFieldSchema[];
  sharedDocumentId: string | undefined;
  sharedAt: Timestamp | undefined;
}

export interface NoteFieldSchema {
  id: string;
  notesTemplateFieldId: string;
  label: string;
  updatedAt: Timestamp;
  type: FieldType;
  params: unknown | null;
  value: unknown | null;
  serieItemId: string | null;
  order: number;
}

@Injectable()
export class NoteRepository {
  constructor(
    private firestore: AngularFirestore,
    private fireAuth: AngularFireAuth,
    private http: HttpClient,
  ) {
    // do nothing
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<NoteSchema>('notes', queryFn);
  }

  toSchema(note: Note): NoteSchema {
    return <NoteSchema>{
      patientId: note.patientId.id.toString(),
      notesTemplateId: note.templateId.id.toString(),
      name: note.name,
      createdAt:
        note.createdAt !== undefined
          ? Timestamp.fromDate(note.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      fields: note.fields
        .map(
          (f) =>
            <NoteFieldSchema>{
              id: f.id.toString(),
              notesTemplateFieldId: f.notesTemplateFieldId.id.toString(),
              label: f.label,
              updatedAt: Timestamp.now(),
              type: f.type,
              params: f.params ?? null,
              value: FieldTool.valueToSchema(f.type, f.value),
              serieItemId: f.serieItemId || null,
              order: f.order,
            },
        )
        .sort((a, b) => a.order - b.order),
      sharedDocumentId: note.sharedDocumentId?.id.toString() ?? null,
      sharedAt:
        note.sharedAt !== undefined ? Timestamp.fromDate(note.sharedAt) : null,
    };
  }

  fromSchema(schema: NoteSchema, dietitianId: string, id: string): Note {
    return Note.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        patientId: PatientId.create(new UniqueEntityID(schema.patientId)),
        templateId: TemplateId.create(
          new UniqueEntityID(schema.notesTemplateId),
        ),
        name: schema.name,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        fields:
          schema.fields
            ?.map((f) =>
              NoteField.create(
                {
                  noteId: NoteId.create(new UniqueEntityID(id)),
                  notesTemplateFieldId: TemplateFieldId.create(
                    new UniqueEntityID(f.notesTemplateFieldId),
                  ),
                  label: f.label,
                  updatedAt: f.updatedAt.toDate(),
                  type: f.type,
                  params: f.params,
                  value: FieldTool.valueFromSchema(f.type, f.value),
                  serieItemId: f.serieItemId ?? undefined,
                  order: f.order,
                },
                new UniqueEntityID(f.id),
              ),
            )
            .sort((a, b) => a.order - b.order) ?? [],
        sharedDocumentId: schema.sharedDocumentId
          ? SharedDocumentId.create(new UniqueEntityID(schema.sharedDocumentId))
          : undefined,
        sharedAt: schema.sharedAt?.toDate() ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async load(dietitianId: string, noteId: string): Promise<Note> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(noteId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new NoteNotFoundException();
    }

    return this.fromSchema(snap.data() as NoteSchema, dietitianId, snap.id);
  }

  async existsForDietitianIdTemplateId(
    dietitianId: string,
    templateId: string,
  ): Promise<boolean> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('notesTemplateId', '==', templateId).limit(1),
      ).get(),
    );

    return snap.size > 0;
  }

  async findByDietitianId(dietitianId: string, asc = false): Promise<Note[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.orderBy('createdAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findByDietitianAndPatientId(
    dietitianId: string,
    patientId: string,
    asc = false,
  ): Promise<Note[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref
          .where('patientId', '==', patientId)
          .orderBy('createdAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async create(note: Note): Promise<Note> {
    const schema = this.toSchema(note);
    const ref = await this.collection(note.dietitianId.id.toString()).add(
      schema,
    );

    return this.fromSchema(schema, note.dietitianId.id.toString(), ref.id);
  }

  async save(note: Note): Promise<Note> {
    const schema = this.toSchema(note);
    return this.collection(note.dietitianId.id.toString())
      .doc(note.noteId.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(
          schema,
          note.dietitianId.id.toString(),
          note.noteId.id.toString(),
        ),
      );
  }

  async delete(dietitianId: string, noteId: string): Promise<void> {
    return this.collection(dietitianId).doc(noteId).delete();
  }

  async generatePdf(noteId: string): Promise<Blob> {
    const currentUser = await this.fireAuth.currentUser;
    const token = currentUser ? await currentUser.getIdToken() : undefined;
    if (!token) {
      throw new UnAuthorizedException();
    }

    let result: HttpResponse<Blob>;
    try {
      result = await firstValueFrom(
        this.http.post(
          environment.firebaseFunctionsUrl + 'note-generateNotePdf',
          {
            noteId,
          },
          {
            headers: new HttpHeaders({
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: 'application/pdf, application/json',
            }),
            observe: 'response',
            responseType: 'blob',
          },
        ),
      );
    } catch (error: unknown) {
      if (
        error instanceof HttpErrorResponse &&
        error.error instanceof Blob &&
        error.error.type == 'application/json'
      ) {
        const json = JSON.parse(await error.error.text());
        throw new GenericException(json.message);
      }
      throw new GenericException(error);
    }

    if (!result.ok) {
      throw new GenericException(result.statusText);
    }

    if (!result.body) {
      throw new GenericException();
    }

    if (result.body.type == 'application/pdf') {
      return result.body;
    }

    if (result.body.type == 'application/json') {
      const json = JSON.parse(await result.body.text());
      throw new GenericException(json.message);
    }

    throw new GenericException();
  }
}
