import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Dietitian } from './data/dietitian/domain/dietitian';
import { DietitianCommands } from './data/dietitian/domain/dietitian_commands';
import {
  DietitianState,
  DietitianStateProvider,
} from './data/dietitian/domain/dietitian_state_provider';
import { SubscriptionCommands } from './data/dietitian/domain/subscription/subscription_commands';
import { NewsCommands } from './data/news/domain/news-commands';

@Injectable()
export class AppFacade {
  constructor(
    private dietitianStateProvider: DietitianStateProvider,
    private dietitianCommands: DietitianCommands,
    private subscriptionCommands: SubscriptionCommands,
    private newsCommands: NewsCommands,
  ) {
    // do nothing
  }

  get dietitian$(): Observable<DietitianState> {
    return this.dietitianStateProvider.state$.pipe();
  }

  listenSubscription(subscriptionId: string) {
    return this.subscriptionCommands.listenSubscription(subscriptionId);
  }

  loadCurrentDietitian() {
    return this.dietitianCommands.loadCurrentDietitian();
  }

  getCurrentDietitian() {
    return this.dietitianCommands.getCurrentDietitian();
  }

  async findAllNewsAfterDate(after: Date) {
    return this.newsCommands.findAllAfterDate(after);
  }

  async findLastTwoNews() {
    return this.newsCommands.findLastTwoNews();
  }

  async updateDietitianLastNews(): Promise<Dietitian> {
    return this.dietitianCommands.updateCurrentDietitianLastNews();
  }
}
