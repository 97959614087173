import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { NutritionIngredient } from './nutrition-ingredient';
import { NutritionQuantityUnit } from './nutrition-quantity-unit';

export enum NV_CODE {
  ENERGY_UE_C = '328',
  ENERGY_JONES_C = '333',
  ENERGY_UE_J = '327',
  ENERGY_JONES_J = '332',
  PROTEIN_UE = '25003',
  PROTEIN_JONES = '25000',
  LIPID = '40000',
  GLUCID = '31000',
  FIBER = '34100',
  POLYOL = '34000',
  ALCOOL = '60000',
  ACID_ORGANIC = '65000',
}

export interface NutritionValue {
  code: string;
  value: number;
}

export function calculateEnergy(
  proteins: number | undefined,
  lipids: number | undefined,
  glucids: number | undefined,
  fibers: number | undefined,
  polyols: number | undefined,
  acidsOrganic: number | undefined,
  alcool: number | undefined,
): number | undefined {
  let energy = undefined;
  if (proteins) {
    if (!energy) {
      energy = 0;
    }
    energy += proteins * 4;
  }
  if (lipids) {
    if (!energy) {
      energy = 0;
    }
    energy += lipids * 9;
  }
  if (glucids) {
    if (!energy) {
      energy = 0;
    }
    energy += glucids * 4;
  }
  if (fibers) {
    if (!energy) {
      energy = 0;
    }
    energy += fibers * 2;
  }
  if (polyols) {
    if (!energy) {
      energy = 0;
    }
    energy += polyols * 2.4;
  }
  if (acidsOrganic) {
    if (!energy) {
      energy = 0;
    }
    energy += acidsOrganic * 3;
  }
  if (alcool) {
    if (!energy) {
      energy = 0;
    }
    energy += alcool * 7;
  }
  return energy;
}

export class NutritionValues {
  values: NutritionValue[] = [];

  insert(value: NutritionValue | undefined) {
    if (value) {
      this.values.push(value);
    }
  }

  find(code: string): NutritionValue {
    if (code === NV_CODE.ENERGY_UE_C) {
      let result = this.values.filter((v) => v.code === code)[0] ?? undefined;
      if (result === undefined) {
        result = { code, value: 0 };
        const proteins = this.values.filter(
          (v) => v.code === NV_CODE.PROTEIN_UE,
        );
        const proteinsValue =
          proteins.length > 0
            ? proteins[0].value && !isNaN(proteins[0].value)
              ? proteins[0].value
              : 0
            : 0;
        const lipids = this.values.filter((v) => v.code === NV_CODE.LIPID);
        const lipidsValue =
          lipids.length > 0
            ? lipids[0].value && !isNaN(lipids[0].value)
              ? lipids[0].value
              : 0
            : 0;
        const glucids = this.values.filter((v) => v.code === NV_CODE.GLUCID);
        const glucidsValue =
          glucids.length > 0
            ? glucids[0].value && !isNaN(glucids[0].value)
              ? glucids[0].value
              : 0
            : 0;
        const fibers = this.values.filter((v) => v.code === NV_CODE.FIBER);
        const fibersValue =
          fibers.length > 0
            ? fibers[0].value && !isNaN(fibers[0].value)
              ? fibers[0].value
              : 0
            : 0;
        const polyols = this.values.filter((v) => v.code === NV_CODE.POLYOL);
        const polyolsValue =
          polyols.length > 0
            ? polyols[0].value && !isNaN(polyols[0].value)
              ? polyols[0].value
              : 0
            : 0;
        const acidsOrganic = this.values.filter(
          (v) => v.code === NV_CODE.ACID_ORGANIC,
        );
        const acidsOrganicValue =
          acidsOrganic.length > 0
            ? acidsOrganic[0].value && !isNaN(acidsOrganic[0].value)
              ? acidsOrganic[0].value
              : 0
            : 0;
        const alcool = this.values.filter((v) => v.code === NV_CODE.ALCOOL);
        const alcoolValue =
          alcool.length > 0
            ? alcool[0].value && !isNaN(alcool[0].value)
              ? alcool[0].value
              : 0
            : 0;

        result.value =
          calculateEnergy(
            proteinsValue,
            lipidsValue,
            glucidsValue,
            fibersValue,
            polyolsValue,
            acidsOrganicValue,
            alcoolValue,
          ) ?? 0;
      }
      return result;
    } else {
      return this.values.filter((v) => v.code === code)[0] ?? undefined;
    }
  }
}

export interface NutritionFoodstuffProps {
  ingredient: NutritionIngredient;
  quantity: number;
  quantityUnit: NutritionQuantityUnit;
  labelWithoutQuantity?: string;
  sorter: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export class NutritionFoodstuffId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): NutritionFoodstuffId {
    return new NutritionFoodstuffId(id);
  }
}

export class NutritionFoodstuff extends Entity<NutritionFoodstuffProps> {
  private constructor(props: NutritionFoodstuffProps, id?: UniqueEntityID) {
    super(props, id);
  }

  get nutritionFoodstuffId(): NutritionFoodstuffId {
    return NutritionFoodstuffId.create(this.id);
  }

  get ingredient(): NutritionIngredient {
    return this.props.ingredient;
  }

  set ingredient(v: NutritionIngredient) {
    this.props.ingredient = v;
  }

  get quantity(): number {
    return this.props.quantity;
  }

  set quantity(value: number) {
    this.props.quantity = value;
  }

  get quantityUnit(): NutritionQuantityUnit {
    return this.props.quantityUnit;
  }

  set quantityUnit(value: NutritionQuantityUnit) {
    this.props.quantityUnit = value;
  }

  get labelWithoutQuantity(): string | undefined {
    return this.props.labelWithoutQuantity;
  }

  set labelWithoutQuantity(value: string | undefined) {
    this.props.labelWithoutQuantity = value;
  }

  get sorter(): number {
    return this.props.sorter;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get weight(): number | undefined {
    if (this.quantityUnit) {
      return this.quantity * this.quantityUnit.weight;
    } else {
      return undefined;
    }
  }

  getNutritionValues(code?: string | undefined): NutritionValues {
    const result = new NutritionValues();
    if (
      this.ingredient &&
      this.ingredient.compositions &&
      this.ingredient.compositions.length > 0
    ) {
      if (code) {
        result.insert(this.nutritionValue(code));
      } else {
        result.insert(this.nutritionValue(NV_CODE.ENERGY_UE_C));
        result.insert(this.nutritionValue(NV_CODE.PROTEIN_UE));
        result.insert(this.nutritionValue(NV_CODE.LIPID));
        result.insert(this.nutritionValue(NV_CODE.GLUCID));
        result.insert(this.nutritionValue(NV_CODE.FIBER));
        result.insert(this.nutritionValue(NV_CODE.POLYOL));
        result.insert(this.nutritionValue(NV_CODE.ACID_ORGANIC));
        result.insert(this.nutritionValue(NV_CODE.ALCOOL));
      }
    }
    return result;
  }

  private nutritionValue(code: string): NutritionValue | undefined {
    const composition = this.ingredient.compositions.filter(
      (c) => c.component?.nutritionComponentId.id.toString() === code,
    );
    if (composition && composition.length > 0) {
      if (composition[0].value && typeof composition[0].value === 'number') {
        return {
          code: code,
          value: this.weight ? (composition[0].value * this.weight) / 100 : 0,
        };
      }
    }
    return undefined;
  }

  public static create(
    props: NutritionFoodstuffProps,
    id?: UniqueEntityID,
  ): NutritionFoodstuff {
    return new NutritionFoodstuff(props, id);
  }

  copyWith(props: NutritionFoodstuffProps): NutritionFoodstuff {
    return NutritionFoodstuff.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }
}
