import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { filter, map, Observable } from 'rxjs';

import { LoadingStatus } from '../../core/domain/events/state_provider';
import { DietitianStateProvider } from '../../data/dietitian/domain/dietitian_state_provider';

@Injectable()
export class DocumentIdGuard implements CanActivate {
  constructor(
    private router: Router,
    private dietitianStateProvider: DietitianStateProvider,
  ) {
    // do nothing
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const patientId = route.paramMap.get('patientId');
    const documentId = route.paramMap.get('documentId');

    return this.dietitianStateProvider.state$
      .pipe(
        filter((s) => {
          return s.loading != LoadingStatus.LOADING;
        }), // ignore loading
      )
      .pipe(
        map((e) => {
          // signed in as dietitian, continue
          if (e.entity != null) {
            if (patientId != null) {
              if (documentId != null) {
                return true;
              } else {
                return this.router.createUrlTree(['/patient', patientId]);
              }
            } else {
              return this.router.createUrlTree(['/dashboard']);
            }
          }

          // redirect
          return this.router.createUrlTree(['/auth']);
        }),
      );
  }
}
