import { Exception } from 'src/app/core/logic/exception';

export class EncouragementNotFoundException extends Exception<never> {
  constructor() {
    super('Encouragement non trouvé');
  }
}

export class EncouragmentAlreadyProcessingException extends Exception<never> {
  constructor() {
    super('Encouragement déjà traité, impossible de le supprimer');
  }
}

export class InvalidEncouragementDateException extends Exception<never> {
  constructor() {
    super('Date de programmation invalide');
  }
}

export class BatchSizeException extends Exception<never> {
  constructor() {
    super("Un envoi d'encouragement ne peut pas dépasser 500 destinataires");
  }
}
