import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { PeriodBarResult } from '../../../ui/components/period-bar/period-bar.component';
import { SharedDocumentId } from '../../document/domain/shared_document/shared_document';
import { PatientId } from '../../patient/domain/patient';
import { Bill, PaymentMethod } from '../domain/bill/bill';
import { BillNotFoundException } from '../domain/bill/bill-exceptions';
import { BillLine } from '../domain/bill/bill-line';
import {
  BillLineDescription,
  BillLineDescriptionProps,
} from '../domain/bill/bill-line-description';
import { BillStatus } from '../domain/bill/bill-status';
import { Dietitian, DietitianId } from '../domain/dietitian';
import { EstimateId } from '../domain/estimate/estimate';

export interface BillLineSchema {
  billId: string;
  descriptionId: string;
  comment: string | null;
  amount: number;
  date: string | null;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
}

export interface BillSchema {
  patientId: string;
  patientFullname: string;
  referencePrefix: string | null;
  reference: number | null;
  billedAt: Timestamp | null;
  lines: BillLineSchema[];
  amountExclTax: number;
  amountInclTax: number;
  amountTax: number;
  rateTax: number | null;
  withTax: boolean;
  draftedAt: Timestamp | null;
  emittedAt: Timestamp | null;
  paidAt: Timestamp | null;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
  archived: boolean;
  archivedAt: Timestamp | null;
  status: BillStatus;
  url: string | null;
  path: string | null;
  documentHasReady: boolean;
  paymentMethod: PaymentMethod | null;
  estimateId: string | null;
  sharedDocumentId: string | undefined;
  sharedAt: Timestamp | undefined;
}

export interface ResultCalcTot {
  count: number;
  amountIncludingTax: number;
  amountExcludingTax: number;
}

@Injectable()
export class BillRepository {
  private readonly calcTotalBill: (data: unknown) => Observable<ResultCalcTot>;
  private readonly previewPdf: (data: unknown) => Observable<string>;

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    this.calcTotalBill = this.functions.httpsCallable<unknown, ResultCalcTot>(
      'bill-calcTotalBill',
    );
    this.previewPdf = this.functions.httpsCallable<unknown, string>(
      'bill-previewPdf',
    );
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<BillSchema>('bills', queryFn);
  }

  public toSchema(bill: Bill): BillSchema {
    return {
      patientId: bill.patientId?.id.toString(),
      patientFullname: bill.patientFullname,
      referencePrefix: bill.referencePrefix ?? null,
      reference: bill.reference ?? null,
      billedAt: bill.billedAt ? Timestamp.fromDate(bill.billedAt) : null,
      lines: this.toLineSchema(bill),
      amountExclTax: bill.amountExclTax,
      amountInclTax: bill.amountInclTax,
      amountTax: bill.amountTax,
      rateTax: bill.rateTax ?? null,
      withTax: bill.withTax,
      draftedAt:
        bill.draftedAt !== undefined
          ? Timestamp.fromDate(bill.draftedAt)
          : Timestamp.now(),
      emittedAt:
        bill.emittedAt !== undefined
          ? Timestamp.fromDate(bill.emittedAt)
          : null,
      paidAt:
        bill.paidAt !== undefined ? Timestamp.fromDate(bill.paidAt) : null,
      createdAt:
        bill.createdAt !== undefined
          ? Timestamp.fromDate(bill.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      archived: bill.archived,
      archivedAt:
        bill.archivedAt !== undefined
          ? Timestamp.fromDate(bill.archivedAt)
          : null,
      status: bill.status,
      url: bill.url ?? null,
      path: bill.path ?? null,
      documentHasReady: bill.documentHasReady,
      paymentMethod: bill.paymentMethod ?? null,
      estimateId: bill.estimateId?.id.toString() ?? null,
      sharedDocumentId: bill.sharedDocumentId?.id.toString() ?? null,
      sharedAt:
        bill.sharedAt !== undefined ? Timestamp.fromDate(bill.sharedAt) : null,
    } as BillSchema;
  }

  private toLineSchema(bill: Bill): BillLineSchema[] {
    return bill.lines.map((line) => {
      return {
        billId: bill.id.toString(),
        descriptionId: line.description?.id.toString(),
        comment: line.comment ?? null,
        amount: line.amount,
        date: line.date ?? null,
        createdAt:
          line.createdAt !== undefined
            ? Timestamp.fromDate(line.createdAt)
            : Timestamp.now(),
        updatedAt: Timestamp.now(),
      } as BillLineSchema;
    });
  }

  fromSchema(schema: BillSchema, dietitianId: string, id?: string): Bill {
    return Bill.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(dietitianId)),
        patientId: PatientId.create(new UniqueEntityID(schema.patientId)),
        patientFullname: schema.patientFullname,
        referencePrefix: schema.referencePrefix ?? undefined,
        reference: schema.reference ?? undefined,
        billedAt: schema.billedAt?.toDate() ?? undefined,
        lines: this.fromLineSchema(schema.lines),
        amountExclTax: schema.amountExclTax,
        amountInclTax: schema.amountInclTax,
        amountTax: schema.amountTax,
        rateTax: schema.rateTax ?? undefined,
        withTax: schema.withTax,
        draftedAt: schema.draftedAt?.toDate() ?? undefined,
        emittedAt: schema.emittedAt?.toDate() ?? undefined,
        paidAt: schema.emittedAt?.toDate() ?? undefined,
        archived: schema.archived,
        archivedAt: schema.emittedAt ? schema.emittedAt?.toDate() : undefined,
        createdAt: schema.createdAt?.toDate() ?? undefined,
        updatedAt: schema.updatedAt?.toDate() ?? undefined,
        status: schema.status,
        url: schema.url ?? undefined,
        path: schema.path ?? undefined,
        documentHasReady: schema.documentHasReady,
        paymentMethod: schema.paymentMethod ?? undefined,
        estimateId: schema.estimateId
          ? EstimateId.create(new UniqueEntityID(schema.estimateId))
          : undefined,
        sharedDocumentId: schema.sharedDocumentId
          ? SharedDocumentId.create(new UniqueEntityID(schema.sharedDocumentId))
          : undefined,
        sharedAt: schema.sharedAt?.toDate() ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  private fromLineSchema(lines: BillLineSchema[]): BillLine[] {
    return lines.map((line) => {
      return BillLine.create({
        description: BillLineDescription.create(
          {} as BillLineDescriptionProps,
          new UniqueEntityID(line.descriptionId),
        ),
        comment: line.comment ?? undefined,
        amount: line.amount,
        date: line.date ?? undefined,
        createdAt: line.createdAt?.toDate() ?? undefined,
        updatedAt: line.updatedAt?.toDate() ?? undefined,
      });
    });
  }

  async create(bill: Bill): Promise<Bill> {
    const schema = this.toSchema(bill);
    if (bill.dietitianId) {
      if (schema.status !== 'DRAFT') {
        // Reference
        const now = new Date();
        schema.referencePrefix =
          now.getFullYear().toString() +
          (now.getMonth() + 1 < 10
            ? '0' + (now.getMonth() + 1)
            : now.getMonth() + 1);
        const lastReference = await this.findLastReferenceByReferencePrefix(
          bill.dietitianId.id.toString(),
          schema.referencePrefix,
        );
        if (lastReference && lastReference.length === 1) {
          schema.reference = lastReference[0].reference
            ? lastReference[0].reference + 1
            : null;
        } else {
          schema.reference = 1;
        }
      } else {
        schema.referencePrefix = null;
        schema.reference = null;
      }

      // Calcul des totaux
      const taxFactor = schema.withTax ? Number(schema.rateTax) / 100 : 0;
      for (const line of schema.lines) {
        schema.amountExclTax += Number(line.amount);
        schema.amountTax += Number(line.amount) * taxFactor;
        schema.amountInclTax +=
          Number(line.amount) + Number(line.amount) * taxFactor;
      }

      const ref = await this.collection(bill.dietitianId.id.toString()).add(
        schema,
      );
      return this.fromSchema(schema, bill.dietitianId.id.toString(), ref.id);
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async save(bill: Bill): Promise<Bill> {
    const schema = this.toSchema(bill);
    if (bill.dietitianId) {
      if (schema.status === 'EMITTED' || schema.status === 'PAID') {
        // Reference
        if (!schema.referencePrefix && !schema.reference) {
          const now = new Date();
          schema.referencePrefix =
            now.getFullYear().toString() +
            (now.getMonth() + 1 < 10
              ? '0' + (now.getMonth() + 1)
              : now.getMonth() + 1);
          const lastReference = await this.findLastReferenceByReferencePrefix(
            bill.dietitianId.id.toString(),
            schema.referencePrefix,
          );
          if (lastReference && lastReference.length === 1) {
            schema.reference = lastReference[0].reference
              ? lastReference[0].reference + 1
              : null;
          } else {
            schema.reference = 1;
          }
        }
        // Calcul des totaux
        schema.amountExclTax = 0;
        schema.amountTax = 0;
        schema.amountInclTax = 0;
        const taxFactor = schema.withTax ? Number(schema.rateTax) / 100 : 0;
        for (const line of schema.lines) {
          schema.amountExclTax += Number(line.amount);
          schema.amountTax += Number(line.amount) * taxFactor;
          schema.amountInclTax +=
            Number(line.amount) + Number(line.amount) * taxFactor;
        }
      }

      const dietitianId = bill.dietitianId.id.toString();
      await this.collection(dietitianId)
        .doc(bill.billId.id.toString())
        .set(schema);
      return this.fromSchema(schema, dietitianId, bill.billId.id.toString());
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async load(dietitianId: string, billId: string): Promise<Bill> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(billId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new BillNotFoundException();
    }
    const schema = snap.data() as BillSchema;
    return this.fromSchema(schema, dietitianId, snap.id);
  }

  async findLastReferenceByReferencePrefix(
    dietitianId: string,
    referencePrefix: string | undefined,
  ) {
    let snap;
    if (referencePrefix) {
      snap = await firstValueFrom(
        this.collection(dietitianId, (ref) =>
          ref
            .where('referencePrefix', '==', referencePrefix)
            .orderBy('reference', 'desc')
            .limit(1),
        ).get(),
      );
    } else {
      snap = await firstValueFrom(
        this.collection(dietitianId, (ref) =>
          ref.orderBy('reference', 'desc').limit(1),
        ).get(),
      );
    }
    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findAllForPatient(
    dietitianId: string,
    patientId: string,
  ): Promise<Bill[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('patientId', '==', patientId),
      ).get(),
    );
    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findAllExported(
    dietitianId: string,
    period: PeriodBarResult | undefined,
    status: BillStatus | undefined,
    isArchived: boolean,
  ): Promise<string> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) => {
        let qry = ref.where('archived', '==', isArchived);
        if (status) {
          qry = qry.where('status', '==', status);
        }
        if (period) {
          if (period.start) {
            qry = qry.where('billedAt', '>=', period.start.toDate());
          }
          if (period.end) {
            qry = qry.where('billedAt', '<=', period.end.toDate());
          }
          if (period.start || period.end) {
            qry = qry.orderBy('billedAt', 'desc');
          }
        }
        qry = qry.orderBy('updatedAt', 'desc');
        return qry;
      }).get(),
    );
    let result =
      'REFERENCE;PATIENT;DATE_FACTURE;MODE_PAIEMENT;MONTANT_HT;TVA;MONTANT_TTC;%TVA;STATUT;DATE_ARCHIVE;DATE_BROUILLON;DATE_EMISSION;DATE_PAIEMENT;DATE_CREATION;DATE_MISE_A_JOUR\r\n';
    for (const doc of snap.docs) {
      result +=
        (doc.data().referencePrefix ?? '') +
        '-' +
        (doc.data().reference?.toFixed().padStart(4, '0') ?? '') +
        ';' +
        doc.data().patientFullname +
        ';' +
        doc.data().billedAt?.toDate().toISOString() +
        ';' +
        (doc.data().paymentMethod ?? '-') +
        ';' +
        doc.data().amountExclTax +
        ';' +
        doc.data().amountTax +
        ';' +
        doc.data().amountInclTax +
        ';' +
        doc.data().rateTax +
        ';' +
        doc.data().status +
        ';' +
        (doc.data().archivedAt?.toDate().toISOString() ?? '-') +
        ';' +
        (doc.data().draftedAt?.toDate().toISOString() ?? '-') +
        ';' +
        (doc.data().emittedAt?.toDate().toISOString() ?? '-') +
        ';' +
        (doc.data().paidAt?.toDate().toISOString() ?? '-') +
        ';' +
        (doc.data().createdAt?.toDate().toISOString() ?? '-') +
        ';' +
        (doc.data().updatedAt?.toDate().toISOString() ?? '-') +
        '\r\n';
    }
    return result;
  }

  async findAllActivated(
    dietitianId: string,
    period: PeriodBarResult | undefined,
    status: BillStatus | undefined,
    lastId?: string | undefined,
  ) {
    if (lastId) {
      const last = await firstValueFrom(
        this.collection(dietitianId).doc(lastId).get(),
      );

      const snap = await firstValueFrom(
        this.collection(dietitianId, (ref) => {
          let qry = ref.where('archived', '==', false);
          if (status) {
            qry = qry.where('status', '==', status);
          }
          if (period) {
            if (period.start) {
              qry = qry.where('billedAt', '>=', period.start.toDate());
            }
            if (period.end) {
              qry = qry.where('billedAt', '<=', period.end.toDate());
            }
            if (period.start || period.end) {
              qry = qry.orderBy('billedAt', 'desc');
            }
          }
          qry = qry.orderBy('updatedAt', 'desc').startAfter(last).limit(30);
          return qry;
        }).get(),
      );
      return snap.docs.map((doc) =>
        this.fromSchema(doc.data(), dietitianId, doc.id),
      );
    } else {
      try {
        const snap = await firstValueFrom(
          this.collection(dietitianId, (ref) => {
            let qry = ref.where('archived', '==', false);
            if (status) {
              qry = qry.where('status', '==', status);
            }
            if (period) {
              if (period.start) {
                qry = qry.where('billedAt', '>=', period.start.toDate());
              }
              if (period.end) {
                qry = qry.where('billedAt', '<=', period.end.toDate());
              }
              if (period.start || period.end) {
                qry = qry.orderBy('billedAt', 'desc');
              }
            }
            qry = qry.orderBy('updatedAt', 'desc').limit(30);
            return qry;
          }).get(),
        );
        return snap.docs.map((doc) =>
          this.fromSchema(doc.data(), dietitianId, doc.id),
        );
      } catch (e) {
        console.log('❌', e);
        return [];
      }
    }
  }

  async findAllArchived(
    dietitianId: string,
    period: PeriodBarResult | undefined,
    lastId: string | undefined,
  ) {
    if (lastId) {
      const last = await firstValueFrom(
        this.collection(dietitianId).doc(lastId).get(),
      );
      const snap = await firstValueFrom(
        this.collection(dietitianId, (ref) => {
          let qry = ref.where('archived', '==', true);
          if (period) {
            if (period.start) {
              qry = qry.where('billedAt', '>=', period.start.toDate());
            }
            if (period.end) {
              qry = qry.where('billedAt', '<=', period.end.toDate());
            }
          }
          qry = qry.orderBy('updatedAt', 'desc').startAfter(last).limit(30);
          return qry;
        }).get(),
      );
      return snap.docs.map((doc) =>
        this.fromSchema(doc.data(), dietitianId, doc.id),
      );
    } else {
      try {
        const snap = await firstValueFrom(
          this.collection(dietitianId, (ref) => {
            let qry = ref.where('archived', '==', true);
            if (period) {
              if (period.start) {
                qry = qry.where('billedAt', '>=', period.start.toDate());
              }
              if (period.end) {
                qry = qry.where('billedAt', '<=', period.end.toDate());
              }
              if (period.start || period.end) {
                qry = qry.orderBy('billedAt', 'desc');
              }
            }
            qry = qry.orderBy('updatedAt', 'desc').limit(30);
            return qry;
          }).get(),
        );
        return snap.docs.map((doc) =>
          this.fromSchema(doc.data(), dietitianId, doc.id),
        );
      } catch (e) {
        console.log('❌', e);
        return [];
      }
    }
  }

  async calcTotalBillFromCloud(
    archived: boolean,
    start: Date | undefined,
    end: Date | undefined,
    status: BillStatus | undefined,
  ): Promise<ResultCalcTot> {
    const data = {
      archived,
      start,
      end,
      status,
    };
    return firstValueFrom(this.calcTotalBill(data));
  }

  async previewPdfFromCloud(): Promise<string> {
    return firstValueFrom(this.previewPdf({}));
  }

  async delete(dietitian: Dietitian, bill: Bill): Promise<void> {
    return this.collection(dietitian.id.toString())
      .doc(bill.id.toString())
      .delete();
  }

  billValueChanges(
    dietitianId: string,
    billId: string,
  ): Observable<BillSchema | undefined> {
    return this.collection(dietitianId).doc(billId).valueChanges();
  }
}
