import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { CustomTrackerEventProvider } from 'src/app/data/patient/domain/events/custom_tracker/custom_tracker_event_provider';
import {
  CustomTrackerCreated,
  CustomTrackerDeleted,
  CustomTrackerUpdated,
} from 'src/app/data/patient/domain/events/custom_tracker/custom_tracker_events';

import { TrackerRepository } from '../../../tracker/repositories/tracker_repository';
import { CustomTrackerRepository } from '../../repositories/custom_tracker_repository';
import { CustomTracker, CustomTrackerProps } from './custom_tracker';

@Injectable()
export class CustomTrackerCommands {
  constructor(
    private repository: CustomTrackerRepository,
    private trackerRepository: TrackerRepository,
    private eventProvider: CustomTrackerEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (!event.notify) return;

      if (event instanceof CustomTrackerCreated) {
        this.toastr.success('Tracker créé');
      } else if (event instanceof CustomTrackerUpdated) {
        this.toastr.success('Tracker mis à jour');
      } else if (event instanceof CustomTrackerDeleted) {
        this.toastr.success('Tracker supprimé');
      }
    });
  }

  get events() {
    return this.eventProvider.events$;
  }

  async getDietitianCustomTracker(
    dietitianId: string,
    customTrackerId: string,
  ): Promise<CustomTracker> {
    return this.repository.load(dietitianId, customTrackerId);
  }

  async getDietitianCustomTrackers(
    dietitianId: string,
  ): Promise<CustomTracker[]> {
    return this.repository.findByDietId(dietitianId);
  }

  async saveCustomTracker(
    customTrackerProps: CustomTrackerProps,
    customTrackerId?: string,
    notify = true,
  ): Promise<CustomTracker> {
    let tracker = CustomTracker.create(
      customTrackerProps,
      new UniqueEntityID(customTrackerId),
    );
    if (customTrackerId !== undefined) {
      tracker = await this.repository.save(tracker);
      this.eventProvider.dispatch(new CustomTrackerUpdated(tracker, notify));
    } else {
      tracker = await this.repository.create(tracker);
      this.eventProvider.dispatch(new CustomTrackerUpdated(tracker, notify));
    }

    return tracker;
  }

  async deleteTracker(
    dietitianId: string,
    customTrackerId: string,
  ): Promise<CustomTracker> {
    const tracker = await this.getDietitianCustomTracker(
      dietitianId,
      customTrackerId,
    );
    await this.repository.delete(dietitianId, customTrackerId);
    this.eventProvider.dispatch(new CustomTrackerDeleted(tracker));

    return tracker;
  }
}
