import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import {
  Premium,
  PremiumStatus,
  StripePriceId,
} from '../domain/subscription/premium';
import { StripeProductId } from '../domain/subscription/subscription';

export interface PremiumSchema {
  label: string;
  subtitle: string;
  features: string[];
  stripeProductId: string;
  stripePriceId: string;
  createdAt: Timestamp;
  updatedAt: Timestamp | undefined;
  status: PremiumStatus;
  sort: number;
  default: boolean | null;
}

@Injectable()
export class PremiumRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<PremiumSchema>('premiums', queryFn);
  }

  toSchema(premium: Premium): PremiumSchema {
    return {
      label: premium.label,
      subtitle: premium.subtitle,
      sort: premium.sort,
      stripeProductId: premium.stripeProductId.id.toString(),
      stripePriceId: premium.stripePriceId.id.toString(),
      createdAt:
        premium.createdAt !== undefined
          ? Timestamp.fromDate(premium.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      status: premium.status,
      default: premium.default,
      features: premium.features,
    };
  }

  fromSchema(schema: PremiumSchema, id: string): Premium {
    return Premium.create(
      {
        label: schema.label,
        subtitle: schema.subtitle,
        sort: schema.sort,
        stripeProductId: StripeProductId.create(
          new UniqueEntityID(schema.stripeProductId),
        ),
        stripePriceId: StripePriceId.create(
          new UniqueEntityID(schema.stripePriceId),
        ),
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt?.toDate() ?? undefined,
        status: schema.status,
        default: schema.default ?? false,
        features: schema.features,
      },
      new UniqueEntityID(id),
    );
  }

  async findAllActive(): Promise<Premium[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref.where('status', '==', PremiumStatus.Active).orderBy('sort', 'asc'),
      ).get(),
    );
    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }
}
