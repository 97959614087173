import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';

import { Dietitian } from '../domain/dietitian';

export interface DietitianProfileSchema {
  firstName: string;
  lastName: string;
  pictureUrl: string | null;
  picturePath: string | null;
  phoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
}

@Injectable()
export class DietitianProfileRepository {
  constructor(private firestore: AngularFirestore) {
    // nothing to do here
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<DietitianProfileSchema>(
      'dietitians_profiles',
      queryFn,
    );
  }

  toSchema(dietitian: Dietitian): DietitianProfileSchema {
    return <DietitianProfileSchema>{
      firstName: dietitian.firstName,
      lastName: dietitian.lastName,
      pictureUrl: dietitian.picture?.url ?? null,
      picturePath: dietitian.picture?.path ?? null,
      phoneNumber: dietitian.phoneNumber,
      address: dietitian.address,
      postalCode: dietitian.postalCode,
      city: dietitian.city,
    };
  }

  async save(dietitian: Dietitian): Promise<void> {
    const schema = this.toSchema(dietitian);
    return this.collection().doc(dietitian.userId.id.toString()).set(schema);
  }
}
