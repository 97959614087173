import { DomainEvent } from 'src/app/core/domain/events/domain_event';

import { User } from '../user';

export class AuthenticationEvent extends DomainEvent<User> {}

export class UserCreated extends AuthenticationEvent {}

export class UserUpdated extends AuthenticationEvent {}

export class UserSignedIn extends AuthenticationEvent {}

export class UserSignedOut extends AuthenticationEvent {}
