import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { LoadingStatus } from 'src/app/core/domain/events/state_provider';
import { DietitianStateProvider } from 'src/app/data/dietitian/domain/dietitian_state_provider';

@Injectable()
export class DashboardGuard implements CanActivate {
  constructor(
    private router: Router,
    private dietitianStateProvider: DietitianStateProvider,
  ) {
    // do nothing
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.dietitianStateProvider.state$
      .pipe(
        filter((s) => s.loading != LoadingStatus.LOADING), // ignore loading
      )
      .pipe(
        map((e) => {
          // if not signed as dietitian, redirect to auth
          if (!e.entity) {
            return this.router.createUrlTree(['/auth']);
          }

          // check if dietitian has a valid subscription, if not redirect to billing page
          if (!e.entity.subscription || !e.entity.subscription?.isActive) {
            if (!state.url.includes('/dashboard/account/subscription')) {
              return this.router.createUrlTree([
                '/dashboard/account/subscription',
              ]);
            }
          }

          return true;
        }),
      );
  }
}
