import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { EmailRepository } from '../../repositories/email_repository';
import { EmailEventProvider } from '../events/email/email_event_provider';
import { EmailSent } from '../events/email/email_events';
import { Email, EmailProps } from './email';

@Injectable()
export class EmailCommands {
  constructor(
    private repository: EmailRepository,
    private eventProvider: EmailEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof EmailSent) {
        this.toastr.success('Email envoyé');
      }
    });
  }

  async sendEmail(emailProps: EmailProps): Promise<Email> {
    const email = await this.repository.create(Email.create(emailProps));
    this.eventProvider.dispatch(new EmailSent(email));
    return email;
  }
}
