import { DomainEvent } from '../../../../../core/domain/events/domain_event';
import { Survey } from '../../survey/survey';

export class SurveyEvent extends DomainEvent<Survey> {}

export class SurveyCreated extends SurveyEvent {}

export class SurveyUpdated extends SurveyEvent {}

export class SurveyDeleted extends SurveyEvent {}
