import { Injectable } from '@angular/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { UnAuthorizedException } from 'src/app/core/logic/exception';

import { EncouragementRepository } from '../../repositories/encouragement_repository';
import { EncouragementTemplateRepository } from '../../repositories/encouragement-template-repository';
import { DietitianStateProvider } from '../dietitian_state_provider';
import { EncouragementTemplate } from '../encouragement_template/encouragement-template';
import { EncouragementEventProvider } from '../events/encouragement/encouragement_event_provider';
import {
  EncouragementBatchDeleted,
  EncouragementCreated,
  EncouragementDeleted,
  EncouragementUpdated,
} from '../events/encouragement/encouragement_events';
import { PatientGroupCommands } from '../patient_group/patient_group_commands';
import {
  Encouragement,
  EncouragementProps,
  EncouragementStatus,
} from './encouragement';
import {
  EncouragmentAlreadyProcessingException,
  InvalidEncouragementDateException,
} from './encouragement_exceptions';

@Injectable()
export class EncouragementCommands {
  constructor(
    private repository: EncouragementRepository,
    private templateRepository: EncouragementTemplateRepository,
    private eventProvider: EncouragementEventProvider,
    private dietitianStateProvider: DietitianStateProvider,
    private patientGroupCommands: PatientGroupCommands,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (!event.notify) return;

      if (event instanceof EncouragementCreated) {
        this.toastr.success('Encouragement créé');
      } else if (event instanceof EncouragementUpdated) {
        this.toastr.success('Encouragement mis à jour');
      } else if (event instanceof EncouragementDeleted) {
        this.toastr.success('Encouragement supprimé');
      } else if (event instanceof EncouragementBatchDeleted) {
        this.toastr.success('Encouragements supprimés');
      }
    });
  }

  getEncouragement(
    dietitianId: string,
    encouragementId: string,
  ): Promise<Encouragement> {
    return this.repository.load(dietitianId, encouragementId);
  }

  getEncouragements(
    groupId: string | undefined,
    scheduled = true,
  ): Promise<Encouragement[]> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }
    return this.repository.findByDietitianId(
      this.dietitianStateProvider.state.entity.id.toString(),
      groupId,
      scheduled,
    );
  }

  async saveEncouragement(
    encouragementProps: EncouragementProps,
    encouragementId?: string,
  ): Promise<Encouragement> {
    let encouragement = Encouragement.create(
      { ...encouragementProps, status: EncouragementStatus.TO_PROCESS },
      new UniqueEntityID(encouragementId),
    );
    // check dietitian
    if (
      this.dietitianStateProvider.state.entity === undefined ||
      !encouragement.dietitianId.equals(
        this.dietitianStateProvider.state.entity.dietitianId,
      )
    ) {
      throw new UnAuthorizedException();
    }

    // check date
    if (encouragement.scheduledAt < moment().add(-1, 'minute').toDate()) {
      throw new InvalidEncouragementDateException();
    }

    if (encouragementId !== undefined) {
      encouragement = await this.repository.save(encouragement);
      this.eventProvider.dispatch(new EncouragementUpdated(encouragement));
    } else {
      encouragement = await this.repository.create(encouragement);

      let notify = true;

      // not scheduled, display specific toast
      if (!encouragement.scheduled) {
        notify = false;
        this.toastr.success(
          "Encouragement envoyé, il s'affichera d'ici quelques minutes.",
        );
      }

      this.eventProvider.dispatch(
        new EncouragementCreated(encouragement, notify),
      );
    }

    return encouragement;
  }

  async deleteEncouragement(encouragementId: string): Promise<Encouragement> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    const dietitianId = this.dietitianStateProvider.state.entity.id.toString();

    const encouragement = await this.getEncouragement(
      dietitianId,
      encouragementId,
    );
    if (encouragement.status != EncouragementStatus.TO_PROCESS) {
      const exception = new EncouragmentAlreadyProcessingException();
      this.toastr.error(exception.message);
      throw exception;
    }

    await this.repository.delete(dietitianId, encouragementId);
    this.eventProvider.dispatch(new EncouragementDeleted(encouragement));

    return encouragement;
  }

  async deleteEncouragements(groupId: string | undefined): Promise<void> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    // list encouragements
    const encouragements = await this.getEncouragements(groupId);

    if (encouragements.length == 0) return;

    // delete encouragements
    const operations: Promise<unknown>[] = [];

    for (const encouragement of encouragements) {
      operations.push(
        this.repository.delete(
          this.dietitianStateProvider.state.entity.id.toString(),
          encouragement.id.toString(),
        ),
      );
    }

    await Promise.all(operations);
    this.eventProvider.dispatch(new EncouragementBatchDeleted(encouragements));
  }

  getDietitianEncouragementCount(dietitianId: string): Promise<number> {
    return this.repository.getDietitianEncouragementCount(dietitianId);
  }

  async getDietitianEncouragementCountByGroup(
    dietitianId: string,
    groupId: string,
  ): Promise<number> {
    return this.repository.getDietitianEncouragementCountByGroup(
      dietitianId,
      groupId,
    );
  }

  async saveEncouragementTemplate(template: EncouragementTemplate) {
    const dietitianId =
      this.dietitianStateProvider.state.entity?.dietitianId ?? undefined;
    if (dietitianId) {
      return await this.templateRepository.save(
        dietitianId.id.toString(),
        template,
      );
    }
    return Promise.reject("Une erreur s'est produite.");
  }

  async loadAllEncouragementTemplate() {
    const dietitianId =
      this.dietitianStateProvider.state.entity?.dietitianId ?? undefined;
    if (dietitianId) {
      return await this.templateRepository.loadAll(dietitianId.id.toString());
    }
    return Promise.reject("Une erreur s'est produite.");
  }
}
