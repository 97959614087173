import { Exception } from 'src/app/core/logic/exception';

export class UserNotFoundException extends Exception<string> {
  constructor(email?: string) {
    super(
      email ? `Utilisateur ${email} non trouvé` : 'Utilisateur non trouvé',
      email,
    );
  }
}

export class WrongCredentialsException extends Exception<never> {
  constructor() {
    super('Identifiants incorrects');
  }
}

export class EmailAlreadyInUseException extends Exception<string> {
  constructor(email: string) {
    super(`Adresse email ${email} déjà utilisée`, email);
  }
}

export class InvalidEmailException extends Exception<string> {
  constructor(email: string) {
    super(`Adresse email ${email} invalide`, email);
  }
}

export class WeakPasswordException extends Exception<never> {
  constructor() {
    super('Mot de passe trop faible');
  }
}

export class UserDisabledException extends Exception<string> {
  constructor(email?: string) {
    super(
      email
        ? `Compte ${email} désactivé, veuiller contacter le support`
        : 'Compte désactivé, veuiller contacter le support',
      email,
    );
  }
}

export class RecentLoginRequiredException extends Exception<never> {
  constructor() {
    super(
      'Connexion récente requise, veuillez vous déconnecter et reconnecter',
    );
  }
}

export class InvalidPasswordResetCodeException extends Exception<never> {
  constructor() {
    super('Code de réinitialisation invalide');
  }
}

export class ExpiredPasswordResetCodeException extends Exception<never> {
  constructor() {
    super('Code de réinitialisation de mot de passe expiré');
  }
}
