import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { UnAuthorizedException } from '../../../../core/logic/exception';
import {
  SharedDocument,
  SharedDocumentProps,
} from '../../../document/domain/shared_document/shared_document';
import {
  Conversation,
  ConversationProps,
} from '../../../message/domain/conversation/conversation';
import { Message, MessageProps } from '../../../message/domain/message/message';
import { Profile } from '../../../message/domain/profile/profile';
import { Patient } from '../../../patient/domain/patient';
import { PatientRepository } from '../../../patient/repositories/patient_repository';
import { PatientGroupRepository } from '../../repositories/patient_group_repository';
import { ScheduledTaskRepository } from '../../repositories/scheduled_task.repository';
import { Dietitian } from '../dietitian';
import { DietitianStateProvider } from '../dietitian_state_provider';
import { ScheduledTaskEventProvider } from '../events/scheduled_task/Scheduled_task_event_provider';
import {
  ScheduledTaskBatchCreated,
  ScheduledTaskBatchDeleted,
  ScheduledTaskCreated,
  ScheduledTaskDeleted,
  ScheduledTaskUpdated,
} from '../events/scheduled_task/scheduled_task_events';
import { PatientGroup } from '../patient_group/patient_group';
import { Template } from '../templates/template';
import {
  ScheduledTask,
  ScheduledTaskStatus,
  ScheduledTaskTargetProps,
  ScheduledTaskTargetRecipientProps,
  ScheduledTaskTargetType,
  ScheduledTaskType,
} from './scheduled_task';

@Injectable()
export class ScheduledTaskCommands {
  constructor(
    private repository: ScheduledTaskRepository,
    private patientRepository: PatientRepository,
    private patientGroupRepository: PatientGroupRepository,
    private dietitianStateProvider: DietitianStateProvider,
    private eventProvider: ScheduledTaskEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof ScheduledTaskCreated) {
        this.toastr.success('Programmation créée');
      } else if (event instanceof ScheduledTaskUpdated) {
        this.toastr.success('Programmation mis à jour');
      } else if (event instanceof ScheduledTaskDeleted) {
        this.toastr.success('Programmation supprimée');
      } else if (event instanceof ScheduledTaskBatchCreated) {
        this.toastr.success('Programmations créées');
      }
    });
  }

  async scheduleSharedDocuments(
    scheduledAt: Date,
    sharedDocumentProps: SharedDocumentProps[],
  ) {
    if (sharedDocumentProps.length == 0) return [];

    const operations: Promise<ScheduledTask>[] = [];
    for (const props of sharedDocumentProps) {
      if (props.patientId) {
        const scheduledTaskTarget: ScheduledTaskTargetProps = {
          type: ScheduledTaskTargetType.PATIENT,
          recipients: [
            {
              patientId: props.patientId.id.toString(),
              patientUserId: props.patientUserId?.id.toString(),
            },
          ],
        };
        const recipientName = await this.getRecipientName(scheduledTaskTarget);
        const task = ScheduledTask.create({
          type: ScheduledTaskType.DOCUMENT,
          contentId: undefined,
          content: SharedDocument.create(props),
          dietitianId: props.dietitianId,
          target: scheduledTaskTarget,
          recipientName,
          scheduledAt,
          status: ScheduledTaskStatus.TO_PROCESS,
        });
        operations.push(this.repository.create(task));
      }
    }
    const tasks = await Promise.all(operations);
    this.eventProvider.dispatch(new ScheduledTaskBatchCreated(tasks));
    return tasks;
  }

  async scheduleSharedDocumentsForPatients(
    target: PatientGroup | Patient[] | undefined,
    scheduledAt: Date,
    sharedDocumentProps: SharedDocumentProps[],
  ) {
    if (sharedDocumentProps.length == 0) return [];

    let scheduledTaskTarget: ScheduledTaskTargetProps | undefined;
    if (target) {
      if (target instanceof PatientGroup) {
        scheduledTaskTarget = {
          type: ScheduledTaskTargetType.GROUP,
          groupId: target.patientGroupId.id.toString(),
          recipients: (await this.getPatientsByGroup(target)).map((p) => {
            return {
              patientId: p.patientId.id.toString(),
              patientUserId: p.userId?.id.toString(),
            } as ScheduledTaskTargetRecipientProps;
          }),
        } as ScheduledTaskTargetProps;
      } else {
        const recipients = target.map((p) => {
          return {
            patientId: p.patientId.id.toString(),
            patientUserId: p.userId?.id.toString(),
          } as ScheduledTaskTargetRecipientProps;
        });
        scheduledTaskTarget = {
          type: ScheduledTaskTargetType.PATIENT,
          recipients,
        } as ScheduledTaskTargetProps;
      }
    }

    const operations: Promise<ScheduledTask>[] = [];
    for (const props of sharedDocumentProps) {
      const recipientName = await this.getRecipientName(scheduledTaskTarget);
      const task = ScheduledTask.create({
        type: ScheduledTaskType.DOCUMENT,
        contentId: undefined,
        content: SharedDocument.create(props),
        dietitianId: props.dietitianId,
        target: scheduledTaskTarget,
        recipientName,
        scheduledAt,
        status: ScheduledTaskStatus.TO_PROCESS,
      });
      operations.push(this.repository.create(task));
    }
    const tasks = await Promise.all(operations);
    this.eventProvider.dispatch(new ScheduledTaskBatchCreated(tasks));
    return tasks;
  }

  private async getPatientsByGroup(target: PatientGroup): Promise<Patient[]> {
    return this.patientRepository.getPatientGroupPatients(
      target.dietitianId.id.toString(),
      target.patientGroupId.id.toString(),
    );
  }

  async scheduleMessage(
    dietitian: Dietitian,
    target: Profile | PatientGroup | Patient[],
    scheduledAt: Date,
    subject: string,
    message: string,
  ) {
    if (target instanceof Profile) {
      const scheduledTaskTarget: ScheduledTaskTargetProps = {
        type: ScheduledTaskTargetType.PATIENT,
        recipients: [
          {
            patientId: target.profileId.id.toString(),
            patientUserId: target.userId?.id.toString(),
          },
        ],
      };

      await this.createMessageScheduledTask(
        dietitian,
        scheduledTaskTarget,
        subject,
        message,
        scheduledAt,
      );
    } else if (target instanceof PatientGroup) {
      const recipients = (await this.getPatientsByGroup(target)).map((p) => {
        return {
          patientId: p.patientId.id.toString(),
          patientUserId: p.userId?.id.toString(),
        } as ScheduledTaskTargetRecipientProps;
      });

      const scheduledTaskTarget: ScheduledTaskTargetProps = {
        type: ScheduledTaskTargetType.GROUP,
        groupId: target.patientGroupId.id.toString(),
        recipients,
      };

      await this.createMessageScheduledTask(
        dietitian,
        scheduledTaskTarget,
        subject,
        message,
        scheduledAt,
      );
    } else {
      const recipients = target.map((p) => {
        return {
          patientId: p.patientId.id.toString(),
          patientUserId: p.userId?.id.toString(),
        };
      });
      const scheduledTaskTarget: ScheduledTaskTargetProps = {
        type: ScheduledTaskTargetType.PATIENT,
        recipients,
      };

      await this.createMessageScheduledTask(
        dietitian,
        scheduledTaskTarget,
        subject,
        message,
        scheduledAt,
      );
    }
  }

  private async createMessageScheduledTask(
    dietitian: Dietitian,
    scheduledTaskTarget: ScheduledTaskTargetProps,
    subject: string,
    message: string,
    scheduledAt: Date,
  ) {
    const content = Conversation.create({
      dietitian: Profile.create({
        firstName: dietitian.firstName,
        lastName: dietitian.lastName,
        profileId: dietitian.dietitianId,
        userId: dietitian.userId,
        profilePicture: dietitian.picture?.url,
        hasNewMessage: false,
        lastMessageReadDate: new Date(),
      }),
      subject,
    } as ConversationProps);
    const subContent = Message.create({
      senderId: dietitian.id,
      message,
    } as MessageProps);

    const recipientName = await this.getRecipientName(scheduledTaskTarget);
    const task = ScheduledTask.create({
      type: ScheduledTaskType.MESSAGE,
      contentId: undefined,
      content,
      subContent,
      dietitianId: dietitian.dietitianId,
      target: scheduledTaskTarget,
      recipientName,
      scheduledAt,
      status: ScheduledTaskStatus.TO_PROCESS,
    });
    await this.repository.create(task);
    this.eventProvider.dispatch(new ScheduledTaskCreated(task));
  }

  async scheduleSurvey(
    dietitian: Dietitian,
    target: Patient | PatientGroup | Patient[],
    currentTemplate: Template,
    messageTemplateContent: string,
    scheduledAt: Date,
  ) {
    if (target instanceof Patient) {
      const scheduledTaskTarget: ScheduledTaskTargetProps = {
        type: ScheduledTaskTargetType.PATIENT,
        recipients: [
          {
            patientId: target.patientId.id.toString(),
            patientUserId: target.userId?.id.toString(),
          },
        ],
      };
      await this.createSurveyScheduledTask(
        dietitian,
        scheduledTaskTarget,
        currentTemplate,
        messageTemplateContent,
        scheduledAt,
      );
    } else if (target instanceof PatientGroup) {
      const recipients = (await this.getPatientsByGroup(target)).map((p) => {
        return {
          patientId: p.patientId.id.toString(),
          patientUserId: p.userId?.id.toString(),
        } as ScheduledTaskTargetRecipientProps;
      });

      const scheduledTaskTarget: ScheduledTaskTargetProps = {
        type: ScheduledTaskTargetType.GROUP,
        groupId: target.patientGroupId.id.toString(),
        recipients,
      };

      await this.createSurveyScheduledTask(
        dietitian,
        scheduledTaskTarget,
        currentTemplate,
        messageTemplateContent,
        scheduledAt,
      );
    } else {
      const recipients = target.map((p) => {
        return {
          patientId: p.patientId.id.toString(),
          patientUserId: p.userId?.id.toString(),
        };
      });
      const scheduledTaskTarget: ScheduledTaskTargetProps = {
        type: ScheduledTaskTargetType.PATIENT,
        recipients,
      };

      await this.createSurveyScheduledTask(
        dietitian,
        scheduledTaskTarget,
        currentTemplate,
        messageTemplateContent,
        scheduledAt,
      );
    }
  }

  private async createSurveyScheduledTask(
    dietitian: Dietitian,
    scheduledTaskTarget: ScheduledTaskTargetProps,
    content: Template,
    subContent: string,
    scheduledAt: Date,
  ) {
    const recipientName = await this.getRecipientName(scheduledTaskTarget);
    const task = ScheduledTask.create({
      type: ScheduledTaskType.SURVEY,
      contentId: content.id,
      content,
      subContent,
      dietitianId: dietitian.dietitianId,
      target: scheduledTaskTarget,
      recipientName,
      scheduledAt,
      status: ScheduledTaskStatus.TO_PROCESS,
    });
    await this.repository.create(task);
    this.eventProvider.dispatch(new ScheduledTaskCreated(task));
  }

  async countScheduledTask(dietitianId: string) {
    return this.repository.countScheduledTaskByDietitianId(dietitianId);
  }

  async countScheduledTaskByType(dietitianId: string, type: ScheduledTaskType) {
    return this.repository.countScheduledTaskByDietitianIdAndType(
      dietitianId,
      type,
    );
  }

  async countScheduledTaskByGroup(dietitianId: string, groupId: string) {
    return this.repository.countScheduledTaskCountByDietitianIdAndGroupId(
      dietitianId,
      groupId,
    );
  }

  async countScheduledTaskByGroupAndType(
    dietitianId: string,
    groupId: string,
    type: ScheduledTaskType,
  ) {
    return this.repository.countScheduledTaskCountByDietitianIdAndGroupIdAndType(
      dietitianId,
      groupId,
      type,
    );
  }

  async getScheduledTasks(
    dietitianId: string,
    type: ScheduledTaskType,
    groupId: string | undefined,
  ) {
    return this.repository.getScheduledTasks(dietitianId, type, groupId);
  }

  async deleteScheduledTask(dietitianId: string, scheduledTaskId: string) {
    return this.repository.deleteScheduledTask(dietitianId, scheduledTaskId);
  }

  async getPatientRecipient(patientId: string): Promise<string | undefined> {
    const patient = await this.patientRepository.load(patientId);
    if (patient) {
      return patient.firstName + ' ' + patient.lastName;
    }
    return undefined;
  }

  private async getRecipientName(
    scheduledTaskTarget: ScheduledTaskTargetProps | undefined,
  ) {
    if (scheduledTaskTarget) {
      if (scheduledTaskTarget.type === ScheduledTaskTargetType.PATIENT) {
        if (scheduledTaskTarget.recipients.length === 1) {
          const patient = await this.patientRepository.load(
            scheduledTaskTarget.recipients[0].patientId,
          );
          return patient.firstName + ' ' + patient.lastName;
        } else if (scheduledTaskTarget.recipients.length > 1) {
          return (
            'Une sélection de ' +
            scheduledTaskTarget.recipients.length +
            ' patients'
          );
        }
      } else {
        if (scheduledTaskTarget.groupId) {
          const group = await this.patientGroupRepository.load(
            scheduledTaskTarget.groupId,
          );
          return group.name;
        }
      }
    }
    return undefined;
  }

  async deleteScheduledTasks(
    dietitianId: string,
    type: ScheduledTaskType,
    groupId: string | undefined,
  ) {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    const scheduledTasks = await this.getScheduledTasks(
      dietitianId,
      type,
      groupId,
    );

    if (scheduledTasks.length == 0) return;

    // delete scheduledTasks
    const operations: Promise<unknown>[] = [];

    for (const scheduledTask of scheduledTasks) {
      operations.push(
        this.repository.delete(
          this.dietitianStateProvider.state.entity.id.toString(),
          scheduledTask.id.toString(),
        ),
      );
    }

    await Promise.all(operations);
    this.eventProvider.dispatch(new ScheduledTaskBatchDeleted(scheduledTasks));
  }
}
