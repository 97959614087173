import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export type MessageTemplateType = 'survey' | 'other';

export interface MessageTemplateProps {
  content: string;
  type: MessageTemplateType;
  withSign: boolean;
}

export class MessageTemplateId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): MessageTemplateId {
    return new MessageTemplateId(id);
  }
}

export class MessageTemplate extends Entity<MessageTemplateProps> {
  get messageTemplateId(): MessageTemplateId {
    return MessageTemplateId.create(this.id);
  }

  get content(): string {
    return this.props.content;
  }

  set content(value: string) {
    this.props.content = value;
  }

  get type(): string {
    return this.props.type;
  }

  get withSign(): boolean {
    return this.props.withSign;
  }

  set withSign(value: boolean) {
    this.props.withSign = value;
  }

  private constructor(props: MessageTemplateProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: MessageTemplateProps): MessageTemplate {
    return MessageTemplate.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: MessageTemplateProps,
    id?: UniqueEntityID,
  ): MessageTemplate {
    return new MessageTemplate(props, id);
  }
}
