import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { StripePriceId } from './premium';
import { StripeProductId } from './subscription';

export enum FeatureStatus {
  Active = 'active',
  Disable = 'disable',
}

export interface FeatureProps {
  code: string;
  label: string;
  stripeProductId: StripeProductId;
  stripePriceId: StripePriceId;
  status: FeatureStatus;
  createdAt: Date;
  updatedAt?: Date | undefined;
  sort: number;
  details: string[];
}

export class FeatureId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): FeatureId {
    return new FeatureId(id);
  }
}

export class Feature extends Entity<FeatureProps> {
  get code(): string {
    return this.props.code;
  }

  get label(): string {
    return this.props.label;
  }

  get sort(): number {
    return this.props.sort;
  }

  get stripeProductId(): StripeProductId {
    return this.props.stripeProductId;
  }

  get stripePriceId(): StripePriceId {
    return this.props.stripePriceId;
  }

  get status(): FeatureStatus {
    return this.props.status;
  }

  get details(): string[] {
    return this.props.details;
  }

  get createdAt(): Date {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  private constructor(props: FeatureProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: FeatureProps): Feature {
    return Feature.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: FeatureProps, id?: UniqueEntityID): Feature {
    return new Feature(props, id);
  }
}
